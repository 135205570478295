import React from 'react'
import { FreeTextAnswers } from './FreeTextAnswers.jsx';


export const UXQuestionUI = (props) => {
    let listOfQuestions = props.state.uxquestions.map((question) => (
      <div className="accountQuestion">
        {/* <div className = 'questionText'>{question.q}</div> */}
        <FreeTextAnswers
          name={props.state.uxquestions.indexOf(question)}
          placeholder={question.placeholder}
          handleThisChange={(e) => props.handleUserFeedback(e)  }
        />
      </div>
    ));
    return <div className="listOfAccountQuestions">
        {listOfQuestions}
        <button type="submit" onClick={(e)=>props.handleSubmitUXSurvey(e)}>Submit</button>
        </div>;
  };

export default UXQuestionUI