import React from 'react'

import { Footer, TopNav } from './nnv_components.js'

import {
    map_global_stewards,
    map_justice_warriors,
    map_preservers,
    map_innovation_leaders
} from '../nnv_importImages.js'


//////////////////////////

/* const MoreOrLess = props =>  {
    const xThem = props.xThem, 
    yThem = props.yThem, 
    report = props.report

    console.log('moreOrLess',xThem, yThem, report) */

   /*  const xYou = report.xGroups[0]; // eg ["change", "preservation"]
    const yYou = report.yGroups[0]; // eg ["world", "tribe"]

    const xCompared = (xThem === xYou
        ?<div><b>Motivated by {xYou},</b> like you</div>
        :<div><b>More motivated by {xThem}</b> than you</div>)
    const yCompared = (yThem === yYou
            ?<div><b>Aiming for {yYou},</b> like you</div>
            :<div><b>Aiming more for {yThem}</b> than you</div>)  

     let words =    xCompared
     words +=     yCompared
            
    return  words */ 

 /*    return 'MoreOrLess'

                } */

////////////////////////


export const ReportArchetypesPage = (props) => {

    const archetype = props.state.report.archetype
    //const formattedArchetype = formatToSentenceCase(archetype)


    const map_graphic_archetype = {
        global_stewards: map_global_stewards,
        justice_warriors: map_justice_warriors,
        preservers: map_preservers,
        innovation_leaders: map_innovation_leaders
    }

    // make list of other archetypes, minus your archetype
    let otherArchetypes = {
        global_stewards : otherArchetypes_global_stewards,
        justice_warriors : otherArchetypes_justice_warriors,
        preservers : otherArchetypes_preservers,
        innovation_leaders : otherArchetypes_innovation_leaders
    }
    delete otherArchetypes[archetype]
    const listOfOtherArchetypes = Object.values(otherArchetypes)
    //const listOfOtherArchetypesKeys_for_test = Object.keys(otherArchetypes)

    return (
        <>

            <span className={archetype}>

                <TopNav handleBack = {()=>props.handleBack()}
                handlePrint = {()=>props.handlePrint()} 
                startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

                <section className="dark">
                    <div>
                        <h1><span>Your results</span> Archetype</h1>
                        <p><i>The Values Map below shows where you sit in relation to the 4 archetypes, and what this means in
                            terms
                            of your motivations and goals.</i></p>
                    </div>
                </section>
                <section className="tabs dark">
                    <div>
                        <div className="tab" onClick={()=>props.handlePage('ReportGoalsPage')}>Your Goals</div>
                        <div className="tab selected" onClick={()=>props.handlePage('ReportArchetypesPage')}>Archetypes</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportInDetailPage')}>In detail</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportActionPage')}>Action</div>
                    </div>
                </section>

                <section>
                    <div>
                        <p><i>Which archetype are you?</i></p>
                        <h1>The 4 Archetypes</h1>
                        <img src={map_graphic_archetype[archetype]} alt="" />
                    </div>
                </section>


                    { yourArchetype[archetype] }


                


                <section className='grey last' onClick = {console.log('()=>props.handleSetArchetype_for_testing(listOfOtherArchetypesKeys_for_test[0])')}>
                    {listOfOtherArchetypes[0]}
                </section>

                <section className="last" onClick = {console.log('()=>props.handleSetArchetype_for_testing(listOfOtherArchetypesKeys_for_test[1])')}>
                    {listOfOtherArchetypes[1]}
                </section>

                <section className='grey last' onClick = {console.log('()=>props.handleSetArchetype_for_testing(listOfOtherArchetypesKeys_for_test[2])')}>
                    {listOfOtherArchetypes[2]}
                </section>

                <section className='last'>
                    <div className='centred'>
                        <button className="big" onClick={()=>props.handlePage('ReportInDetailPage')}>Next: <span>Your Archetype in detail</span></button>
                        <div className="small centred">What your archetype typically values</div>
                    </div>
                </section>

 

                <Footer handlePage={(p)=>props.handlePage(p)}/>

            </span>



        </>
    );
};

export default ReportArchetypesPage

///////////////////////////////////////////

////////////// content for Your Archetype //////////////////

const  yourArchetype_justice_warriors  = (<>
                <section className='last yourArchetype'>
                    <div>
                        <h3 className='noPadding'>Your archetype</h3>
                        <h1 className="xxx">Justice Warriors</h1>
                        <p>Justice Warriors fight for change in the world that benefits the greater good, be it nature, humanity
                            or
                            the community.</p>
                        <p>You are focused on action and the implementation of revolutionary ideas.</p>
                        <div className="small"><b>Motivated by World</b> rather than Tribe</div>
                        <div className="small"><b>Aiming for Change</b> rather than Preservation</div>

                    </div>
                </section>

                <section className='last'>
                    <div>
                        <div><i>The other archetypes</i></div>
                    </div>
                </section>
            </>)
            
const  yourArchetype_global_stewards  = (<>
                <section className='last yourArchetype'>
                    <div>
                        <h3 className='noPadding'>Your archetype</h3>
                        <h1 className="xxx">Global Stewards</h1>
                        <p>Global Stewards preserve and protect valued resources to serve the greater good, be it nature, humanity or the community.</p>
                        <div className="small"><b>Motivated by World</b> rather than Tribe</div>
                        <div className="small"><b>Aiming for Change</b> rather than Preservation</div>

                    </div>
                </section>

                <section className='last'>
                    <div>
                        <div><i>The other archetypes</i></div>
                    </div>
                </section>
            </>)
            
const  yourArchetype_innovation_leaders  = (<>
                <section className='last yourArchetype'>
                    <div>
                        <h3 className='noPadding'>Your archetype</h3>
                        <h1 className="xxx">Innovation Leaders</h1>
                        <p>Innovation Leaders strive for outstanding success of the individual or organisation, by implementing radically new ideas that change the world.
</p>
                        <div className="small"><b>Motivated by Tribe</b> rather than World</div>
                        <div className="small"><b>Aiming for Change</b> rather than Preservation</div>

                    </div>
                </section>

                <section className='last'>
                    <div>
                        <div><i>The other archetypes</i></div>
                    </div>
                </section>
            </>)
            
const  yourArchetype_preservers  = (<>
                <section className='last yourArchetype'>
                    <div>
                        <h3 className='noPadding'>Your archetype</h3>
                        <h1 className="xxx">Preservers</h1>
                        <p>Preservers strive for outstanding success of the individual or organisation, through building and protection of security and stability.</p>
                        <div className="small"><b>Motivated by Tribe</b> rather than World</div>
                        <div className="small"><b>Aiming for Preservation</b> rather than Change</div>

                    </div>
                </section>

                <section className='last'>
                    <div>
                        <div><i>The other archetypes</i></div>
                    </div>
                </section>
            </>)

const yourArchetype = {
    justice_warriors : yourArchetype_justice_warriors,
    global_stewards : yourArchetype_global_stewards,
    innovation_leaders : yourArchetype_innovation_leaders,
    preservers : yourArchetype_preservers,
}           
            


            
////////////// content for Other Archetypes //////////////////

     const otherArchetypes_justice_warriors = (
                    <div>
                        <h3>Justice Warriors</h3>
                        <p>Justice Warriors fight for change in the world that benefits the greater good, be it nature, humanity or the community.</p>
                        <div className="small"><b>More motivated by Tribe</b> than you</div>
                        <div className="small"><b>Aiming for Change,</b> like you</div>
                    </div>)  
                     
     const otherArchetypes_innovation_leaders = (
                    <div>
                        <h3>Innovation Leaders</h3>
                        <p>Innovation Leaders strive for outstanding success of the individual or organisation, by implementing
                            radically new ideas that change the world.
                        </p>
                        <div className="small"><b>More motivated by Tribe</b> than you</div>
                        <div className="small"><b>Aiming for Change,</b> like you</div>
                    </div>)    

     const otherArchetypes_global_stewards = (
                <div>
                    <h3>Global Stewards</h3>
                    <p>Global Stewards preserve and protect valued resources to serve the greater good, be it nature,
                        humanity
                        or the community.</p>
                    <div className="small"><b>Motivated by Tribe,</b> like you</div>
                    <div className="small"><b>Aiming more for Preservation</b> than you</div>
                </div>
                )       

     const otherArchetypes_preservers = (
                    <div>
                        <h3>Preservers</h3>
                        <p>Preservers strive for outstanding success of the individual or organisation, through building and
                            protection of security and stability.</p>
                        <div className="small"><b>More motivated by Tribe</b> than you</div>
                        <div className="small"><b>Aiming more for Preservation</b> than you</div>
                        {/* <MoreOrLess 
                            xThem = 'preservation'
                            yThem = 'tribe' 
                            report = {props.report}
                        /> */}
                    </div>
                    )       



//////////////////////////////////////////////////

