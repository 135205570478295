import React from 'react'

import {
    nnv_examplearmyIMG,
    nnv_examplestanfordIMG,
    nnv_examplepatekphilippeIMG
} from '../nnv_importImages.js'


export const content = (props) => {



    /*  */
    return (
        <>

                <section>
                    <div>
                        <h3>Mission</h3>
                        <p>Your mission statement should convey stability and security, for example by referencing authority and outlining how your business fits into existing convention and institutions. Highlight a strong drive for success and focus on (past or future) achievements. Use formal language that conveys a commitment to tradition and is respectful of differing opinion as well as conveying power and exclusivity.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Culture</h3>
                        <p>The internal business culture rewards achievement above all else and expects employees to deliver. It also rewards rewards behaviour that conforms to the status quo, and maintains the current order. Status is important in your organisation, and leaders establish dominance to get things done. Employees are expected to show self-restraint, follow rules and respect an established hierarchy of authority.  </p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Brand</h3>
                        <p>Externally, your brand portrays status and excellence through hard work. You are expected to lead and uphold industry standards and stand for a deep commitment to traditional values, and for conformity with and a respect for standards and societal rules. Your brand offers security and predictability, and affords respect to institutions and individuals who have achieved status in society.</p>
                    </div>
                </section>

                <section className="last">
                    <div>
                        <h3>Communication</h3>
                        <p>Focus on the in-group/business/customers rather than a larger group in your communication. Address topics directly related to your business rather than commenting on general issues. At the same time, topics related to personal and social processes, and family and private life tend to be of elevated interest for those with conservative values. Try connecting these topics to your business,  productivity or work.</p>
                        <p>Take a positive and assertive tone that avoids anxious or uncertain language, but avoid making statements of absolute certainty. Use respectful language that portrays self restraint and avoids upsetting people or going against convention. Avoid using swear words. </p>
                        
                        <div className='footnote'><hr />Communication recommendations for your values camp are based on <a href='https://journals.sagepub.com/doi/full/10.1002/per.2254'>scientific research on
                            values
                            and
                            word use.</a></div >
                    </div>
                </section>

                <section className="grey last">
                    <div>
                        <h3>Key words</h3>
                        <p>Using key words like these will help to communicate your values to customers, and help manifest your
                            company culture internally.
                        </p>
                        <div>
                            { ['superior', 'win', 'success', 'better', 'take', 'price', 'benefit', 'work', 'school', 'job', 'team', 'danger', 'doubt', 'was', 'had (past tense)', 'parent', 'family', 'husband', 'dad', 'home', 'family'].map(word=> <span className="tag">{word}</span>)}

                        </div>
                        <div className='footnote'><hr />Key words are matched to your value camp based on <a href='https://repositories.lib.utexas.edu/handle/2152/31333'>scientific research on values
                            and
                            word use.</a></div>

                    </div>
                </section>

                <section>
                    <div>
                        <h3>Real world examples <span>Brands in this Archetype</span></h3>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>British Army</h3>
                        <div className="example">
                            <img src={nnv_examplearmyIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>The landscape invokes danger. Themes of belonging in text and image (a group facing danger together) answer to feelings of doubt and create a sense of comraderie. The motion in the image suggests forward momentum, with the call to action, "Be the best", connoting success.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Patek Philippe</h3>
                        <div className="example">
                            <img src={nnv_examplepatekphilippeIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>This brand sells watches. Yet the campaigns run by Patek Philippe serve as a masterclass in how to communicate tradition, heritage and longevity through skillfully designed adverts and messaging. With strong references to family, fatherhood and legacy, the black and white image conveys a sense of timelessness, while the brand label and styling make reference to exclusivity and luxury.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Stanford University</h3>
                        <div className="example">
                            <img src={nnv_examplestanfordIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>Here, the imagery conveys a sense of longstanding tradition, stability and wealth, and the question, "Prepared to lead with purpose?" communicates the institution's values. The invitation (or challenge) to "Develop a Stanford Mindset" connotes exclusivity and global renown, referring implicitly to the institution's reputation for excellence and global impact.</p>
                    </div>
                </section>

        </>
    );
};

export default content