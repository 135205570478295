import React from 'react'
/* import { UXQuestionUI } from '../../components/UXQuestionUI.jsx'; */
// import './css/rbe.css'

export const UserTestIntro = (props) => {
    /*  */
    return (
        <>
            <div className="metaLogo"><img src="https://www.metaprofiling.com/images/logo_meta_large.png" alt="META" /></div>

            <section className="header userTest meta last">
                <div>
                    <h1>Introduction</h1>
                    <p>Thank you for agreeing to take part in this User Testing panel. Your input will help support and guide the development of Red Bull's new Talent Management product.</p>
                    <p>The product has a working title of 'My Experience' but this is likely to change.</p>

                </div>
            </section>

            <section className="userTest last">
                <div>
                    <h4>To log in …</h4>
                    <p>Click 'Open the demo' at the bottom of the page when you're ready</p>
                    <h4>What you need to do</h4>
                    <p>We want you to provide feedback on different aspects of the user experience and at various points you will have the opportunity to answer specific questions. Most of these questions are free text. Please offer your honest opinion as this will guide the development of the product.</p>
                    <p>You can visit the UX test site any number of times and you can submit feedback at any time. Just make sure you always enter your email address when you login. The site will be open until ____   May.</p>
                    <p>If you have any questions, please contact <a href="mailto:info@metaprofiling.com">info@metaprofiling.com</a> </p>
                    <p><em>Good luck and thank you!</em></p>
                    <p><em>The META team</em></p>

                    <button className="big" onClick={() => props.handlePage('account')}>Open the demo</button>

                </div>



            </section>
            <section className="metaFooter last"><div><p>© 2021 Meta Profiling Ltd</p></div></section>


        </>
    );
};

export default UserTestIntro