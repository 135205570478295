import React from 'react'

import { Footer, TopNav } from './nnv_components.js'


export const TermsPage = (props) => {
    /*  */
    return (
        <>

            <TopNav handleBack = {()=>props.handleBack()}
            handlePrint = {()=>props.handlePrint()} 
            startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark last">
                <div>
                    <h1>Terms of Use</h1>
                    <p><i>If you’re unsure about anything, <a href='mailto:info@thevaluesmap.com'>please get in touch</a>
                    </i></p>
                </div>
            </section>

            <section>
                <div>

                    <p>1. GENERAL INFORMATION</p>


                    <p>1.1 INFORMATION ABOUT US AND THE WEBSITE</p>

                    <p>
                        This Website offers you access to its interactive online web pages, applications and services. The
                        Website
                        is operated by The Web Psychologist Limited, 201 Haverstock Hill, Second Floor FKGB, London, England,
                        NW3
                        4QG, United Kingdom (hereinafter referred to as “We”, “Us”, “Our” or “Ours” or “TWP”). These Terms and
                        Conditions set out the rules which apply to the Website and its Services and Content that We make
                        accessible
                        to or permit Users to make available through Our service (referred to herein as "You", "Your", "Yours"
                        or
                        “User”). You should read these Terms and Conditions and the Privacy Policy carefully as, amongst other
                        things; they clarify the limitations of Our liability to You and govern the agreement between You and
                        Us. If
                        You do not agree to these Terms and Conditions or the Privacy Policy You must not use the Website, the
                        Content or the Services.
                    </p>


                    <p>1.2 ACCEPTANCE OF THE TERMS</p>

                    <p>
                        By using this Website, browsing any Service or Content or registering for any Test, You agree to be
                        bound by
                        the following General Terms and Conditions. In addition, certain additional rules are specific to
                        individual
                        Services, such as the Privacy Policy and any such rules shall be published on the Website and shall be
                        deemed incorporated into the Terms and Conditions (together with any other schedules, appendices or
                        exhibits
                        incorporated by reference and links in these Terms and Conditions, collectively referred to herein as
                        "Terms") and agreed by You.
                    </p>


                    <p>1.3 CHANGES TO OUR TERMS</p>

                    <p>
                        We reserve the right to review and revise the Terms from time to time. You will be deemed to have
                        accepted
                        any such changes if You use the Website, Services or Content subsequently to the posting of Our revised
                        Terms. You should therefore check the Website from time to time to review the current Terms because they
                        are
                        binding on you.
                    </p>


                    <p>1.4 IMPROVEMENT OF THE WEBSITE</p>

                    <p>
                        We encourage You to provide feedback on the quality and preferences of the Services, the Website and
                        Content. In the event that You have a complaint, or You are aware that these Terms are being breached by
                        a
                        user or other third party, please contact Us. You can do this by contacting Us at
                        <a href='mailto:info@thevaluesmap.com'>info@thevaluesmap.com</a>
                    </p>


                    <p>1.5 DEFINED TERMS</p>

                    <p>The capitalised terms in this notice have the following meanings:</p>

                    <p>
                        "Content" means all information, material and media, in particular text, data, artwork, graphics,
                        photos,
                        print, footage, software, sounds, music, recordings, videos and other moving and non-moving images and
                        material created or contributed by Us, by You or through Your TWP account, and whether provided in
                        stream,
                        download, electronic data or any other form on or via the Website;
                    </p>

                    <p>
                        "IPR" means intellectual property rights which include, but are not limited to copyright, moral rights,
                        registered designs, patents, trademarks, service marks, brands, design rights (whether registered or
                        unregistered), semiconductor rights, database rights, trade secrets, rights of confidence and all other
                        similar rights (including applications for the registration of any of the foregoing rights) and all
                        other
                        renewals and extensions thereof in any part of the world;
                    </p>

                    <p>
                        "Services" means the services such as the access, software, tools, computer programs (widgets) and
                        Content
                        made available to You via the Website.
                    </p>

                    <p>"Website" means this website that you are currently visiting;</p>

                    <p>"Test" means any assessment developed by Us, which is available on this Website.</p>


                    <p>2. COPYRIGHT, TRADEMARKS AND OTHER INTELLECTUAL PROPERTY RIGHTS</p>

                    <p>
                        All Content and Services of the Website, including the Content and Services provided from Users such as
                        answers submitted, i.e. all text, logos, trademarks, graphics, artwork, sounds, music and software
                        (including the access software) is protected IPR. You grant to Us an irrevocable, worldwide, perpetual,
                        non-exclusive, transferable, sublicensable, free licence to exploit and otherwise use for any purpose
                        all of
                        Your IPR subsisting in or with respect to any Content and Services that is created or contributed by You
                        as
                        a result of in or connection with your use of the Website or through use of your TWP account. All IPR
                        subsisting in the Website and its Content or Services is either owned by Us or licensed to Us. All
                        rights
                        remain reserved to Us or, if provided by a third party, to such third party (and even if any such
                        Content or
                        Service is not explicitly identified to be legally protected or registered, this does not imply any
                        waiver
                        by Us of applicable intellectual property rights with respect to any such Content or Service in its
                        entirety
                        or parts thereof). Even if any such trademark, name and logo are not explicitly identified to be legally
                        protected or registered, this does not imply a waiver from Us as to applicable IPR in respect of any
                        such
                        trademark, name or logo. You may not reproduce, copy, post, republish, broadcast, record, transmit or
                        edit
                        any Content, materials or part(s) thereof without Our express prior written consent, nor do or attempt
                        to do
                        anything which infringes Our IPR or any IPR licensed to Us or owned by a third party. The same shall
                        apply
                        to ideas and concepts that the Website, any of its Content or Services is based on, even if not
                        protected by
                        IPR law.
                    </p>

                    <p>
                        Any reproduction of the Content, the Services or part thereof shall be expressly agreed to by Us and
                        clearly
                        marked by User with Our copyright sign, as follows:
                    </p>

                    <p>c The Web Psychologist Ltd.</p>


                    <p>3. USER REGISTRATION</p>

                    <p>3.1 REGISTRATION AND ACCOUNT</p>

                    <p>
                        In order to access Tests, you may have to become a registered User. In order to register You must be
                        aged 18
                        years or older. You may only register if, you have (a) accepted Our Terms and the Privacy Policy; and
                        (b)
                        provided Us with complete and accurate information for the registration.
                    </p>

                    <p>
                        Certain Content or Services may be made accessible or non-accessible to certain Users (e.g. establishing
                        different access levels differentiating between certain groups of Users) at Our sole discretion.
                    </p>


                    <p>3.2 USERNAME AND PASSWORD</p>

                    <p>
                        By registering with Us You warrant that the information given for Your Account (together with any other
                        information which You may from time to time provide to Us, whether as part of the registration process
                        or
                        otherwise) is complete, true, accurate and not misleading in any way. You are responsible for notifying
                        Us
                        of any changes to such information in order to ensure that it remains up-to-date. You must keep Your
                        Username and Password details secure at all times. You are responsible for all activity that occurs on
                        Your
                        Account, whether impliedly or expressly authorised by You or not. You must immediately notify Us of any
                        unauthorized use on Your Account. You will be liable for losses incurred by Us or any other User of or
                        visitor to the Website due to someone else using Your Account. You must not use an Account belonging to
                        someone else. We will not be liable for any loss or damage arising from Your failure to comply with
                        these
                        obligations. We will not modify Your Username without consulting You.
                    </p>


                    <p>4. USER’S RIGHTS AND OBLIGATIONS</p>

                    <p>4.1 YOUR RIGHTS FOR "PERSONAL USE"</p>

                    <p>
                        We grant You and other Users the right to access the Website and to use the Services and Content in a
                        personal capacity as made available from time to time. Any further use of the Content or the Services,
                        in
                        particular any commercial use or use for third parties’ or Your own interests, activities, services or
                        products, is strictly prohibited. We may revoke any of the rights granted to You at any time at Our
                        discretion by notifying You.
                    </p>


                    <p>4.2 YOUR OBLIGATIONS</p>

                    <p>You agree that Your obligations in connection with the Website, the Content and Services include that:
                    </p>

                    <p>
                        I. You must not copy, reproduce, republish, publicly display, translate, offer, transmit, upload or
                        distribute in any way (including “mirroring”) any part of the Website or any Content or Service to any
                        other
                        computer, server, Website or other medium for publication or distribution, except as expressly provided
                        in
                        these Terms or otherwise agreed to by Us in writing;
                    </p>

                    <p>
                        II. You must not use the Content or Services for commercial uses, including without limitation the sale
                        of
                        access to the Website, Services or Content, except as expressly provided in these Terms or otherwise
                        agreed
                        to by Us in writing;
                    </p>

                    <p>
                        III. You must not modify, alter or adapt any part of the Content or Services (each an “Adaptation”)
                        without
                        Our prior written consent. In the event that We give Our consent to any Adaptation, You acknowledge and
                        agree by requesting such that We shall obtain the irrevocable, worldwide, perpetual, non-exclusive,
                        transferable, sublicensable, fully paid up and unlimited rights to exploit and otherwise use any
                        Adaptation
                        and all materials, whether provided by Us or not as well as all parts thereof, in particular, but not
                        limited to, any edit, cut or excerpt and all other materials created by You or Your affiliates,
                        employees,
                        sublicensees, contractors or any other person or entity, pursuant to or related to the Content, Services
                        or
                        Adaptation, free of charge and in any known or yet unknown kind of use upon its creation;
                    </p>

                    <p>
                        IV. You must not modify, decompile, reverse engineer, disassemble or create derivative works based on
                        any
                        software, Content or Service;
                    </p>

                    <p>
                        V. You must not (or attempt to) hamper, disable, interfere or attempt to interfere with the Website or
                        any
                        part of it (including its security-related features or any transaction as may be concluded on the
                        Website)
                        or in any way reproduce or circumvent the navigational structure of the Website, to obtain or attempt to
                        obtain any Content or Service through any means other than as intentionally offered under the Website;
                    </p>

                    <p>
                        VI. You must not use any Content in connection with: (a) illegal products, content, services or
                        materials;
                        (b) any products, content, services or materials concerning or relating to obscenity, pornography or
                        similarly adult-themed material; and/or (c) coverage of accidents, assaults, catastrophes or riots.
                    </p>

                    <p>
                        VII. You must not use the Website, Content or Services for any unlawful purpose or for the publication
                        of,
                        linking to, issue or display of any unlawful material (such terms to include, but not be limited to, any
                        pirated software or any material which is obscene, threatening, malicious, deceptive, harmful, abusive,
                        defamatory, intimidating, discriminatory on the basis of religion, gender, sexual orientation, race,
                        ethnicity, age or disability) or that breaches the rights (including, but not limited to IPR) of Us
                        and/or
                        any third party or that encourages or procures any criminal activity or contains any virus, trojan
                        horses or
                        other harmful code or script;
                    </p>

                    <p>
                        VIII. You must comply with all applicable laws and regulations in using the Website, Content and
                        Services
                        and You agree not to infringe the rights of Us, Our affiliates, any other User or other third parties.
                    </p>
                    <p>
                        IX. You must observe and comply with any data volume limit which may apply to the Website, Content or
                        Services.
                    </p>


                    <p>4.3 NO ADVERTISING</p>

                    <p>
                        You are not permitted to use the Website, Content or Services for the purpose of advertising or for the
                        purpose of displaying information in connection with any commercial activities, services or products,
                        except
                        as expressly provided in these Terms or otherwise agreed to by Us in writing.
                    </p>


                    <p>4.4 ASSIGNMENT</p>

                    <p>
                        These Terms and any rights and licenses granted hereunder may not be transferred or assigned by You
                        without
                        Our express prior written consent. In the event that You transfer or assign any right, obligation or
                        duty
                        with our prior written consent, You undertake, represent and warrant that: (i) any assignment of rights
                        or
                        obligations granted hereunder is not contrary to or incompatible with each and any provision of these
                        Terms;
                        and (ii) each obligation and duty towards Us is duly imposed upon and entirely complied with by each
                        assignee. Even after an assignment You will not be relieved from any of Your obligations and duties
                        towards
                        Us hereunder. An assignment of Our rights or obligations may be conducted by Us at Our sole discretion.
                    </p>


                    <p>4.5 MODIFICATIONS TO THE SERVICE</p>

                    <p>
                        We reserve the right to review, revise, modify, improve or discontinue the Website, Our Service or
                        Content
                        from time to time, without giving You prior notice. Such content-related and technical reviews and any
                        maintenance work in relation thereto may include without limitation, the extension or modification of
                        the
                        subject areas made available through the Website and the adaptation of the technical formats comprising
                        the
                        Services or the Content. Access to the Website and its Services or Content may therefore be suspended
                        temporarily or permanently and without notice in the case of system failure, maintenance, review or
                        repair,
                        or for reasons beyond Our reasonable control. We shall not be liable if, for any reason, the Website,
                        any
                        Content or Service is unavailable. You are aware that We may cease the provision of the Website, its
                        Content
                        or Services at any time. In any event We shall not be obliged to provide maintenance or support
                        services.
                    </p>


                    <p>5. INDEMNIFICATION</p>

                    <p>
                        You will be responsible for any liability or reasonable costs incurred by Us and Our affiliates,
                        officers,
                        directors, shareholders, employees and agents (Indemnified Parties), jointly and severally, as a result
                        of
                        any claims or actions incurred by any Indemnified Party arising from, or in connection with: (i) your
                        use of
                        our Services; (ii) User Content provided by you or through use of your Account; (iii) any actual or
                        alleged
                        violation or breach by you of these Terms of Use; (iv) any actual or alleged breach of any
                        representation,
                        warranty, or covenant that you have made to us; or (v) your acts or omissions. You agree to cooperate
                        fully
                        with us in the defense of any claim that is the subject of your obligations hereunder.
                    </p>


                    <p>6. DISCLAIMER AND LIMITATION OF LIABILITY</p>

                    <p>6.1</p>

                    <p>
                        We make no express or implied representations, warranties or guarantees of any kind (and all
                        representations, warranties, guarantees and any implied conditions are expressly disclaimed to the
                        fullest
                        extent permitted under applicable law) with respect to the accuracy, security, quality, workmanship,
                        timeliness (including in respect of delivery), operability, merchantability, availability, or
                        completeness
                        of the Website, Services, Content, results of the assessment or towards the non-infringement of
                        industrial
                        or intellectual property or any other rights of third parties or the fitness of the Content for a
                        particular
                        purpose. We do not endorse or guarantee the accuracy of any assessment results and the test output is
                        provided to You for personal development purposes only and is not intended to be relied upon by You. Any
                        decisions or action taken by You, or any third party to whom You choose to disclose Your results, on the
                        basis or in relation to Your results or any other information, Content or Services provided on or via
                        the
                        Website are at Your sole discretion and risk. You must be aware that any action, activity and/or
                        information
                        displayed or provided on or via the Website is conducted by trained and experienced professionals and it
                        is
                        Your responsibility to take precautions and use common sense when using the Website, Content or
                        Services.
                    </p>

                    <p>
                        To the fullest extent permitted by applicable law, You acknowledge and agree that You access and use the
                        Website, Content and Services at Your own risk on an "as is" and "as available" basis and that We are
                        not
                        liable for (i) any result produced by a Test or any recruitment decision based on a Test; and (ii) any
                        errors or omissions in any Content, Service, any availability or delivery (including without limitation
                        whether caused by interruption, deletion, delay in operation, transmission, communication line, errors,
                        omissions or computer virus, trojan horses, or other harmful code or script).
                    </p>


                    <p>6.3</p>

                    <p>
                        We do not warrant that the Website, the Content or the Services will be error-free or uninterrupted or
                        that
                        defects will be corrected. We reserve the right at any time and without notice to remove the Website,
                        Content or Services (or part thereof) for whatever reason, or to interrupt the operation of the Website,
                        Content or Services (or part thereof) as may be necessary to perform routine or non-routine maintenance,
                        error correction or other changes.
                    </p>


                    <p>6.4</p>

                    <p>
                        We sometimes provide hyperlinks and/or advertisements to other Websites that are not owned or controlled
                        by
                        Us. We accept no responsibility or liability for any material supplied by or contained on any third
                        party
                        Website which is linked from or to Our Website. Neither are We responsible for any third party's
                        Websites
                        and You visit such Websites entirely at Your own risk.
                    </p>


                    <p>6.5</p>

                    <p>
                        You agree that We, Our and Our affiliates’ employees, directors, distributors, licensors and agents
                        shall
                        not be liable, whether such liability arises in contract, tort (including without limitation negligence)
                        or
                        otherwise, for any (i) loss of business, opportunity, use, profit, anticipated profit, contracts,
                        revenue,
                        goodwill or anticipated savings or (ii) loss of data or use of data, whether direct, indirect,
                        consequential
                        or special and whether arising in connection with the use of or attempt to use the Website, the Content
                        or
                        the Services, the inability to use these items, interruption or termination of Our provision of the
                        Website,
                        Content or Services and even if We have been advised of the possibility of such damages occurring.
                    </p>


                    <p>6.6</p>

                    <p>
                        In any event, to the fullest extent permitted by applicable law, We shall not be liable for any damage
                        or
                        loss, whether direct or indirect, that You may suffer as a result of a failure by Us to deliver the
                        Content
                        or Services for any reason, including but not limited to access delays or interruptions, data
                        non-delivery
                        or mis-delivery, failure to deliver the Content or Services due to server failure or any event of force
                        majeure including without limitation, acts of God, war or terrorism, breaches of security or
                        unauthorised
                        use of personal data arising from hacking and/or failure or lack of reception of networks. The exclusion
                        set
                        out in this section shall apply even in the event that the loss or damage suffered by You was or should
                        have
                        been foreseen by Us and/or You told Us of the risk of You suffering the loss or damage in question.
                    </p>


                    <p>6.7</p>

                    <p>
                        Nothing in these Terms shall limit or exclude Our liability for death or personal injury caused by Our
                        negligence, for fraud or fraudulent misrepresentation, or for any other type of liability which cannot
                        be
                        limited or excluded by applicable law.
                    </p>


                    <p>6.8</p>

                    <p>
                        Nothing in these Terms shall affect any statutory rights which You may be entitled to as a consumer.
                        However
                        those rights are limited to the extent that We may lawfully limit or exclude our obligations or
                        liability
                        with respect to any such consumer right
                    </p>s.


                    <p>6.9</p>

                    <p>
                        Nothing in these Terms or Your use of the Website, Service or Content shall be construed as amounting to
                        a
                        relationship (e.g. employer/employee or principal/agent) between Us and You or an endorsement from Us to
                        You.
                    </p>


                    <p>7. TERMINATION AND SUSPENSION</p>

                    <p>7.1</p>

                    <p>
                        We may suspend, restrict or terminate Your Account and/or access to this Website at any time, without
                        notice
                        and at Our discretion, where You are or We believe that You are in breach of these Terms, or where We
                        believe Your use of the Website, Content or Services may be causing damage to systems or other technical
                        installations. In addition, a breach of the Terms may constitute a fraudulent offence and result in
                        legal
                        claims and damages as well as criminal prosecution.
                    </p>

                    <p>7.2</p>

                    <p>All disclaimers, indemnities and exclusions in these Terms shall survive the termination of these Terms.
                    </p>


                    <p>8. GOVERNING LAW AND JURISDICTION</p>

                    <p>These Terms shall be governed by and construed in accordance with English law.</p>


                    <p>9. LANGUAGE</p>

                    <p>
                        The original version of the Terms is in English language. Any translated version is for Your convenience
                        and
                        information only. In case of disputes the English text shall prevail.
                    </p>




                </div>
            </section>
            <section className="last">
                <div><button className="big" onClick={()=>props.handleBack()}>Back</button></div>
            </section>




            <Footer handlePage={(p)=>props.handlePage(p)}/>


        </>
    );
};

export default TermsPage