export const NNV_FREETEXT_QUESTIONS = {
    adjective_1 : {
        ui: "freeText",
        placeholder: "e.g. 'driven'"
      },
    adjective_2 : {
        ui: "freeText",
        placeholder: "e.g. 'creative'"
      },
    adjective_3 : {
        ui: "freeText",
        placeholder: "e.g. 'quirky'"
      },
    number_one_goal : {
        ui: "freeText",
        placeholder: "Write a sentence or two"
      },
    biggest_challenge : {
        ui: "freeText",
        placeholder: "Write a sentence or two"
      },
    how_you_give_back : {
        ui: "freeText",
        placeholder: "Write a sentence or two"
      },
    contact_name : {
        ui: "freeText",
        placeholder: "Name"
      },
    contact_email : {
        ui: "freeText",
        placeholder: "Email"
      },
    contact_query : {
        ui: "freeText",
        placeholder: "Query"
      },
    contact_submit_button : {
        ui: "button",
        placeholder: "Query"
      },
}
  

export default NNV_FREETEXT_QUESTIONS
