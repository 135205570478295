import React from 'react'

export const FooterFakes = (props) => {
  return (
    <section className="footerFakes">
      <div>

        <div className="smallLink" onClick={() => props.handleMakeFakeLikertAnswers()}>Add random dummy answers</div>

        {/*         <div className="smallLink" onClick={() => props.handleFakeAvgScores()}>Then click: Make fake avg scores</div> */}

      </div>
    </section>
  );
};

export default FooterFakes