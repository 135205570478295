import React from 'react'
import { Footer, TopNav } from './nnv_components.js' // <Footer handlePage={(p, ??keep??)=>props.handlePage(p, keep)}/>
//import './css/nnv.css'



export const StartPage_nnv = (props) => {

    const whichStartButtons = (<>
    

                {(props.state.url_id && (props.state.url_id !== props.state.local_id))
                ? <>
                    <div className="small centred">You're opening a link shared from another report. </div>
                    <p className='centred'><span onClick={()=>props.handleOpenReport(props.state.url_id)} className="fakeBtnBig">View Shared Report</span></p>
                    </>
            : null}
                {props.state.local_id
                ? <p className='centred'><span onClick={()=>props.handleOpenReport(props.state.local_id)} className="fakeBtnBig">Open My Report</span></p>
            : null}
                <p className='centred'><a href="#anchor" className="fakeBtnBig">Start New</a></p>
                        
                    <div className="small centred">Takes around 8 mins</div>


    </>)




    /*  */
    return (
        <span className='home'>

            <TopNav 
            handleBack = {()=>props.handleBack()} 
            hideBack={true} 
            light_bg = {true}
            handlePrint = {()=>props.handlePrint()} 
tempTest = {()=>props.tempTest()}
            
            />

            <section className="illusHeader ">
                <div>
                    <h1><span>The Values Map</span> Hello and a very warm welcome!</h1>
                    <p><i>The Values Map is designed to help you identify, develop and communicate the psychological values your
                        business represents, and what it strives to achieve for its employees, customers and the wider
                        world. </i></p>
                        

                </div>
            </section>
            <section className="dark last">
                <div>
                {/* <p className='centred'><a href="#anchor" className="fakeBtnBig">Start</a></p> */}
                    {/* <div className="small centred">Takes around 8 mins</div> */}
                {whichStartButtons}
                        

                </div>
            </section>

            <section>
                <div>
                    <h3>What is it?</h3>
                    <p><b>The Values Map</b> is as a companion tool that complements <span className='link'> <a href="https://www.businessunusualthebook.com/"> Business Unusual: Values, Uncertainty and the
                        Psychology of Brand Resilience</a></span>, the new book by best-selling author Nathalie Nahai. Developed
                        in
                        collaboration with Dr. Kiki Leutner at Goldsmiths University, this tool is founded upon robust
                        psychological research and is designed to help you understand and apply cutting edge insights to your
                        business and branding.</p>
                </div>
            </section>
            <section>
                <div>
                    <h3>Who is this for?</h3>
                    <p>This questionnaire is primarily for business leaders wishing to better identify, develop and communicate
                        the psychological values they stand for. From feedback detailing how your values are expressed through
                        company culture, to recommendations on how to enhance your marketing and communications, the report
                        provides scientifically rigorous insights that will help inform and develop your branding strategy
                        moving forward.</p>
                </div>
            </section>
            <section>
                <div>
                    <h3>How does it work?</h3>
                    <p>We will ask you to answer a series of questions designed to explore the purpose and goals of your
                        business. We invite you to fill in the questionnaire as intuitively as possible, and remind you that
                        since each business is unique, there are no “right” or “wrong” answers.</p>
                </div>
            </section>
            <section>
                <div>
                    <h3>What will I get?</h3>
                    <p>Having completed the survey, you will receive a customised report outlining your values archetype, and
                        how your values apply to your mission, culture, brand and communication. You’ll see examples of how
                        these values are expressed through ad campaigns and brand communication, and receive feedback on how to
                        apply these insights to drive more meaningful, resonant engagement with your customers, employees and
                        partners.</p>
                </div>
            </section>
            <section className='last'>
                <div>
                    <h3>Can I use this for my brand, my team, or myself?</h3>
                    <p>You can use <b>The Values Map</b> not only to explore the values of your organisation, but also those of your
                        brand (the public facing side of your business), your team, and the partners you work with. It can also
                        be applied to specific parts of your organisation, as well as to any startup idea or business you are
                        setting up. You can even use this process to learn more about yourself as an individual. In any of these
                        cases, when responding to the survey questions, simply replace 'your business' with 'myself / my team
                        /...' as you answer each item, and read the insights and recommendations in the context of your specific
                        situation.</p>
                </div>
            </section>

            <section className="grey last" id="anchor">
                <div>
                    <h3>Notice of intended use</h3>
                    <p>Your privacy matters to us, your data won't be passed on to third parties or used for advertising
                        purposes without your consent. <b>The Values Map</b> is committed to the security, confidentiality and processing
                        integrity of all information collected.
                    </p>
                    <p>I have read and agree to <b>The Values Map</b>'s <span className='link' onClick={()=>props.handlePage('TermsPage')}>Terms & Conditions</span> and <span className='link' onClick={()=>props.handlePage('PrivacyPage')}>Privacy Policy</span> and confirm that I am 18
                        years or older.</p>
                    <div className='centred'>
                        <button className="big"  onClick={()=>props.handlePage('QuestionsPage_nnv_part1')}>Agree and Start</button>
                        <div className="small">Takes around 8 mins</div>
                    </div>
                </div>
            </section>


            <Footer 
            handlePage={(p)=>props.handlePage(p)}
            handleMakeFakeLikertAnswers={()=>props.handleMakeFakeLikertAnswers()}
            handleClearLocalId={()=>props.handleClearLocalId()}
            state={props.state}
            />



        </span>
    );
};

export default StartPage_nnv

