
import React from 'react'


export const QuickPageMenu = (props) => {

    return (
        <>
            <div className="QuickPageMenu">
                {/* <span className="smallLink" onClick={() => props.handleMakeFakeLikertAnswers()}><span className='underlined'>fill</span>, then: </span> */}

                <select id="title" name="title" onChange={(e) => props.handlePage(e.target.value)}>
                    <option value="" defaultValue>Demo: quick links</option>

                    <option value="account">Sign in (start)</option>
                    <option value="intro">Intro</option>
                    <option value="questions">Questions</option>
                    {/* <option value="ReportIntroPage">ReportIntroPage</option> */}
                    <option value="ReportFacetsPage">Report: All</option>
                    <option value="ReportMasteryPage">Report: Mastery</option>
                    <option value="ReportSubFacetPageStrengths">Mastery-Strengths</option>
                    <option value="ReportSubFacetPageGrowthMindset">Mastery-Growth Mindset</option>
                    <option value="ReportWorkoutPage">Mastery-Workouts</option>
                    {/* <option value="JournalTEMPPage">OLD Journal Page</option> */}
                    <option value="JournalPage">Journal Page</option>
                    <option value="Bot">Goal setting</option>
                    {/* <option value="UserTestIntro">ignore</option> */}

                </select>
            </div>
        </>
    );
};

export default QuickPageMenu


/*
<label for="title">Title</label>
<select id="title" name="title">
 <option value="" selected>Please choose</option>

 <option value="Mr">Mr</option>
 <option value="Miss">Miss</option>
 <option value="Mrs">Mrs</option>
 <option value="Ms">Ms</option>

 <option value="Dr">Dr</option>
 <option value="Other">Other</option>
</select>

*/