import React from 'react'


export const Navtabs = (props) => {
  /* e.g. <Navtabs t={0}> */
  /* note the param for keepScrollPosition is true */
  return (
    <div className="navtabs">
      <div
        className={props.t === 0 ? "tab selected" : "tab"}
        onClick={() => props.handlePage("ReportMasteryPage", 0)}
      >
        How to Improve
      </div>
      <div
        className={props.t === 1 ? "tab selected" : "tab"}
        onClick={() => props.handlePage("JournalPage", 0)}
      >
        My Journal
      </div>
      <div
        className={props.t === 2 ? "tab selected" : "tab"}
      >
        My Inspirations
      </div>
      <div
        className={props.t === 3 ? "tab selected" : "tab"}
        onClick={() => props.handlePage("Bot", 0)}
      >
        My Goals
      </div>
    </div>
  );
};


export default Navtabs

/*
<div className="navtabs">
      <div
        className={props.t === 0 ? "tab selected" : "tab"}
        onClick={() => props.handlePage("ReportMasteryPage", 1)}
      >
        How to Improve
        </div>
      <div
        className={props.t === 1 ? "tab selected" : "tab"}
        onClick={() => props.handlePage("JournalPage", 1)}
      >
        My Journal
        </div>
      <div
        className={props.t === 2 ? "tab selected" : "tab"}
      >
        My Inspirations
        </div>
      <div
        className={props.t === 3 ? "tab selected" : "tab"}
        onClick={() => props.handlePage("Bot", 1)}
      >
        My Goals
        </div>
    </div>

*/