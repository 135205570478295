import React from 'react'

import {
    nnv_exampleunicefIMG,
    nnv_examplejohnlewisIMG,
    nnv_examplebupaIMG
} from '../nnv_importImages.js'


export const content = (props) => {


    /*  */
    return (
        <>

                <section>
                    <div>
                        <h3>Mission</h3>
                        <p>Your mission statement should connect everyone to your vision for a healthy society and natural world. It should convey convey stability and security, for example by referencing authority and outlining how your business fits into existing convention and institutions. Use language that conveys togetherness and a deep common commitment for the welfare of all. Use formal language to emphasize a commitment to tradition and respect of differing opinion.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Culture</h3>
                        <p>The internal business culture rewards collaboration, listening, and support of others in and outside the company. It also rewards behaviour that conforms to the status quo, and maintains the current order. Employees are expected to show self-restraint, follow rules and respect an established hierarchy of authority. You expect employees to be loyal and supportive, to promote sustainability, and to practice justice.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Brand</h3>
                        <p>Externally, your brand portrays a sense of care and commitment to the greater good, as well as a deep commitment to traditional values, and conformity with and a respect for standards and societal rules. You stand for justice and welfare for all, bringing kindness and acceptance to every area you touch. Your brand offers security and predictability, and affords respect to institutions and individuals who have achieved status in society.</p>
                    </div>
                </section>

                <section className="last">
                    <div>
                        <h3>Communication</h3>
                        <p>Focus on others and the concerns of the larger group in your communications. Address topics related to personal and social processes, and family and private life if appropriate, as they tend to be of elevated interest for those with conservative values. Show that you address complex topics, and volunteer to help. </p>
<p>Do provide advice to others, and feel free to express worry or concerns about important topics. However, make sure you use respectful language that portrays self restraint and avoids upsetting people or going against convention. For example, avoid making statements of absolute certainty, or using swear words. Use language that signifies deep thinking and analysis of topics. </p>

                        <div className='footnote'><hr />Communication recommendations for your values camp are based on <a href='https://journals.sagepub.com/doi/full/10.1002/per.2254'>scientific research on
                            values
                            and
                            word use.</a></div >
                    </div>
                </section>

                <section className="grey last">
                    <div>
                        <h3>Key words</h3>
                        <p>Using key words like these will help to communicate your values to customers, and help manifest your
                            company culture internally.
                        </p>
                        <div>
                            { ['Humans', 'inclusive', 'ally', 'friend', 'social', 'we', 'us', 'think', 'can', 'know', 'but', 'parent', 'friend', 'people', 'neighbour', 'afraid', 'include', 'health', 'danger', 'doubt', 'was', 'had (past tense)', 'parent', 'family', 'husband', 'dad', 'home', 'family'].map(word=> <span className="tag">{word}</span>)}

                        </div>
                        <div className='footnote'><hr />Key words are matched to your value camp based on <a href='https://repositories.lib.utexas.edu/handle/2152/31333'>scientific research on values
                            and
                            word use.</a></div>

                    </div>
                </section>

                <section>
                    <div>
                        <h3>Real world examples <span>Brands in this Archetype</span></h3>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Unicef</h3>
                        <div className="example">
                            <img src={    nnv_exampleunicefIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>The portrayed child introduces themes of family. Inclusive wording creates a sense of togetherness and connection. The call to action invites viewers to enter into a relationship with the child in the image by offering care. The image evokes feelings of worry for the lonely child facing an uncertain future, connecting to traditional themes of saviours.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>John Lewis & Partners</h3>
                        <div className="example">
                            <img src={nnv_examplejohnlewisIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>The heavily branded name change to include '& partners' puts relationship and connection at the heart of the brand. The company is exhibiting care for its employees, signalling its intended status as a role model for sharing and inclusivity. At the same time, a traditional quality is maintained through clean and classic logo design.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Bupa</h3>
                        <div className="example">
                            <img src={nnv_examplebupaIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>This still, taken from a video Bupa campaign, highlights the friendly face of a nurse who is supporting a patient named Alan. The close-up crop and warm expression conveys a welcoming and inclusive tone, creating a sense of social conversation, community and comfort. The text makes references to personal support, framing the insurance provider as an ally.</p>
                    </div>
                </section>

        </>
    );
};

export default content