import React from 'react'
import { UXQuestionUI } from '../../components/UXQuestionUI.jsx';
// import './css/rbe.css'

export const UserFeedbackA = (props) => {
  /*  */
  return (
    <>


      <section className="header userTest meta">
        <div>
          <h1>UserFeedbackA</h1>
        </div>
      </section>

      <section className="userTest">
        <div>
          <UXQuestionUI
            state={props.state}
            handleUserFeedback={(e) => props.handleUserFeedback(e)}
            handleSubmitUXSurvey={(e) => props.handleSubmitUXSurvey(e)}
          />
        </div>



      </section>


    </>
  );
};

export default UserFeedbackA