import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
import { FacetsNav } from '../../components/FacetsNav.jsx';
import { SaveBtn } from '../../components/SaveBtn.jsx';
// import './css/rbe.css'

export const ReportSubFacetPageStrengths = props => {
    return (<>
        <NavLogoHeader
            handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
            handlePage={(p) => props.handlePage(p, 0)}
            handleBack={(e) => props.handleBack(e)}
            showJumpMenu={props.state.showJumpMenu} />
        <section className="FacetsNavHeader bgImgMedicineBall">
            <div>
                <FacetsNav
                    handlePage={(p) => props.handlePage(p)}
                />
            </div>
        </section>

        <section className="navheader bgImgMedicineBall">
            <div>
                <h1>Strengths</h1>
                <blockquote>Fixing weaknesses prevents failure, but building strengths leads to success”</blockquote>
                <SaveBtn />

                <Navtabs
                    t={0}
                    handlePage={
                        (p, k, s, c) => props.handlePage(p, 1, s, c) /* tabs keep scroll postion */
                    }
                />

            </div>
        </section>
        <section className="">
            <div>
                <div className="standfirst">
                    Mastery starts with individuals discovering their unique strengths. People who know and are able to use their strengths every day:
                </div>
                <p><i className="fa fa-cog fa-spin"></i>have higher levels of energy and vitality</p>
                <p><i className="fa fa-cog fa-spin"></i>are more likely to achieve their goals</p>
                <p><i className="fa fa-cog fa-spin"></i>are more confident</p>
                <p><i className="fa fa-cog fa-spin"></i>experience less stress</p>
                <p><i className="fa fa-cog fa-spin"></i>are more effective at developing themselves and growing as individuals</p>
            </div>
        </section>
        <section className="vid">
            <div>
                <h4>So, how can you identify and leverage your strengths?</h4>
                <div className="video">
                    <iframe height="100%" width="100%" src="https://www.youtube.com/embed/tlFPVhfPzNA" title="YouTube video player" frameborder="0" rel="0" autoplay="1" modestbranding="1" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen  ></iframe>

                </div>

            </div>
        </section>
        <section><div><div>In order to recognize your strengths and understand how they influence your work, you need to think hard about when and how you succeed. There are lots of different ways to learn about your strengths, and it is important to think about them from a variety of perspectives so that you can apply those strengths in different workplace scenarios. </div>
        </div></section>
        <section>
            <div>
                <h4><em>Use these steps</em> as a guide for identifying your strengths at work:</h4>
                <div className='workouts'>
                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'strengths', 1)}
                    ><i className="fa fa-paper-plane" aria-hidden="true"></i>Listen to others<button >5min exercise</button>

                    </div>
                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'strengths', 2)}
                    ><i className="fa fa-paper-plane" aria-hidden="true"></i>Think about the things you really love<button >15min exercise</button></div>
                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'strengths', 3)}
                    ><i className="fa fa-paper-plane" aria-hidden="true"></i>Take a personality test<button >30min exercise</button></div>
                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'strengths', 4)}
                    ><i className="fa fa-paper-plane" aria-hidden="true"></i>Pay attention to when you are most productive<button >5min exercise</button></div>
                    <div className='card' onClick={() => props.handlePage('ReportWorkoutPage', 0, 'strengths', 5)}
                    ><i className="fa fa-paper-plane" aria-hidden="true"></i>Seek out new experiences<button > weekly exercise</button></div>
                    <div className='card' onClick={() => props.handlePage('ReportWorkoutPage', 0, 'strengths', 6)}
                    ><i className="fa fa-paper-plane" aria-hidden="true"></i>Build strong habits<button >5mins daily</button></div>
                    <div className='card goals' onClick={() => props.handlePage('Bot', 0, 'strengths')}>Your Development Goals<button >Lorem ipsum</button></div>
                </div>
            </div>
        </section>
        <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>)
}

export default ReportSubFacetPageStrengths