import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
// import './css/rbe.css'

export const FeedbackPageB = (props) => {
  /* let listOfFeedback = 'listOfFeedback' */
  return (
    <>
      <NavLogoHeader />

      <section className="navheader">
        <div>
          <h1>Report page 2</h1>
          <div className="standfirst">
            Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam
          </div>

          <Navtabs
            t={1}
            handlePage={
              (p) => props.handlePage(p, 1) /* tabs keep scroll postion */
            }
          />
        </div>
      </section>

      <section className="keywords">
        <div>
          <h4>Lorem ipsum dolor sit amet</h4>
          <div className="tags">
            <h4>keyword</h4>
            <h4>keyword</h4>
            <h4>keyword</h4>
          </div>
        </div>
      </section>

      <section className="catposter">
        <div>
          <h4>Lorem ipsum dolor sit amet</h4>
          <blockquote>
            Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet
          </blockquote>
        </div>
      </section>

      <section className="stats">
        <div>
          <div className="factoid">
            <div className="comment">Factoid title</div>
            <h4 className="tag">Very high</h4>
          </div>
          <div className="factoid">
            <div className="comment">Factoid title</div>
            <h4 className="tag">Lorem</h4>
          </div>
        </div>
      </section>

      <section className="cta">
        <div>
          <h2>Next...</h2>
          <div className="standfirst">dosdfkjhsdf</div>
          <div>
            <button
              className="big strong "
              onClick={() => props.handlePage("feedbackC", 0)}
            >
              Next page
            </button>
          </div>
        </div>
      </section>

      <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>
  );
};

export default FeedbackPageB