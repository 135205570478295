import React from 'react'


export const LikertDivsReverseAnswers = (props) => {
    const ids = [
        "Strongly disagree",
        "Disagree",
        "Neutral",
        "Agree",
        "Strongly agree"
    ];
    /* //////////////////////////////// */

    const aReverse = 6 - props.a

    // for each text label above...
    const likert = ids.map((id) => (
        /* show a button in a div... */
        < div className="likertAnswerButton" key={props.name + id}>
            <button
                id={props.name + id}/* ??? maybe confusing here?*/

                name={props.name}/* ties this button into radio group */

                value={ids.indexOf(id) + 1} /* e.target.name will be used by handler to set question.a */
                onClick={(e) => props.handleChangeLikert(e)}

                className={/* if answered, find out if this option is the one selected */
                    aReverse && aReverse === ids.indexOf(id) + 1
                        ? "clicked"
                        : "not"
                }
            >
                {<span className="checkmark"></span>}
                {id}
            </button>
        </div >
    ));



    return (
        <>
            <div className="answer">
                {props.a
                    ? ids[aReverse - 1]
                    : <span className='prompt'>How true is this for you?</span>}
            </div>
            <div className="likert"> {likert} </div>
        </>
    );


};

export default LikertDivsReverseAnswers