import React from 'react'


export const ProgressBar = (props) => {

  return (
    <>
      <div className="ProgressBar">
        <div
          className="progressLine"
          style={{ width: props.progressPercent + '%' }}
        ></div>
      </div>
    </>
  );
};

export default ProgressBar