import React from 'react'
import { phraseHowStrong  } from '../helpers.jsx';
import { ScaleDiagram  } from './ScaleDiagram.jsx';


export const ScaleFeedback = (props) => {
    /* props wanted: 
    report = props.report
    scale = scale
    */

    let thisScale = props.report.scales[props.scale];

    return (
      <section>
        <div>
          <div className="scaleFeedback">
            <h4>
              {props.scale}{" "}
              <span className="weak">{phraseHowStrong(thisScale.avgScore)}</span>
            </h4>
            <p className="small">{thisScale.whatThisMeasures}</p>
            {<ScaleDiagram score={thisScale.avgScore} />}
            {<div className="strong">{thisScale.feedback[0]}</div>}
            {<div className="">{thisScale.feedback[1]}</div>}
          </div>
        </div>
      </section>
    );
  };

export default ScaleFeedback