import React from 'react'

import {
    nnv_examplemonzoIMG,
    nnv_exampleimpossibleburgerIMG,
    nnv_exampleteslaIMG
} from '../nnv_importImages.js'


export const content = (props) => {


    /*  */
    return (
        <>

                <section>
                    <div>
                        <h3>Mission</h3>
                        <p>Your mission statement should sparks excitement and curiosity, and portray a strong drive for success and focus on (past or future) achievements. You lead the way and explore new paths! Use language that conveys power and exclusivity, as well as a sense of action and novelty to reflect your business values.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Culture</h3>
                        <p>The internal business culture rewards achievement above all else and expects employees to deliver. The culture encourages independent, creative behaviour. Your team asks questions, is curious, and is constantly on the lookout for the new. Enjoyment and excitement are a part of everyday work. Employees act independently to get things done, but status is also important in your organisation. Leaders establish dominance to get things done. </p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Brand</h3>
                        <p>Externally, your brand portrays status and excellence through hard work. You are expected to lead and push industry standards. You are all about change, and offering something new and unique. You break with conventions and pursue excitement.</p>
                    </div>
                </section>

                <section className="last">
                    <div>
                        <h3>Communication</h3>
                        <p>Focus on the in-group/business/customers rather than a larger group in your communication.  Address future looking topics and avoid writing about the past or family. Address topics directly related to your business rather than commenting on general issues. Retain a strict focus on commercially relevant topics, productivity and work.</p>
                        <p>At the same time, connecting those topics to leisure, music, sex and art will help communicate a hedonistic brand image. Take a positive and assertive tone that avoids anxious or uncertain language. Feel free to use unconventional and colloquial language, even swear words if appropriate, and to make convicted statements of certainty. Do no be restrained by rules around language and writing. </p>
                        <div className='footnote'><hr />Communication recommendations for your values camp are based on <a href='https://journals.sagepub.com/doi/full/10.1002/per.2254'>scientific research on
                            values
                            and
                            word use.</a></div >
                    </div>
                </section>

                <section className="grey last">
                    <div>
                        <h3>Key words</h3>
                        <p>Using key words like these will help to communicate your values to customers, and help manifest your
                            company culture internally.
                        </p>
                        <div>
                            { ['superior', 'win', 'success', 'better', 'take', 'price', 'benefit', 'work', 'school', 'job', 'team', 'always', 'everything', 'never', 'swear words', 'see', 'watch', 'hear', 'music', 'love', 'sex', 'game', 'movie', 'beer'].map(word=> <span className="tag">{word}</span>)}

                        </div>
                        <div className='footnote'><hr />Key words are matched to your value camp based on <a href='https://repositories.lib.utexas.edu/handle/2152/31333'>scientific research on values
                            and
                            word use.</a></div>

                    </div>
                </section>

                <section>
                    <div>
                        <h3>Real world examples <span>Brands in this Archetype</span></h3>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Monzo</h3>
                        <div className="example">
                            <img src={nnv_examplemonzoIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>This ad highlights the exciting, innovative way in which Monzo is disrupting traditional banking. With messaging around themes of inclusivity and a customer-centric approach, the bold colour and simple design of this ad communicates a fresh and radical approach to banking that is forging a bright new future.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Impossible Burgers</h3>
                        <div className="example">
                            <img src={nnv_exampleimpossibleburgerIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>Here, the clue is in the name - the branding for Impossible Burgers plays on the audacity of innovating plant-based, meat-free burgers that taste pretty indistinguishable from real meat. With sustainability and biodiversity loss pressing issues of our time, this messaging conveys both a concern for the future and the power of innovation to forge a better one.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Tesla</h3>
                        <div className="example">
                            <img src={nnv_exampleteslaIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>Known for their track record in technology and innovation, Tesla is one of those rare brands whose actions speak for themselves. Alleged to despise advertising, Elon Musk's approach demonstrates how a brilliant product that captures both the values and imagination of its consumers can be a powerful means through which to build success.</p>
                    </div>
                </section>

        </>
    );
};

export default content