import React from 'react'

import { Footer, TopNav } from './nnv_components.js'


export const PrivacyPage = (props) => {
    /*  */
    return (
        <>

            <TopNav handleBack = {()=>props.handleBack()}
            handlePrint = {()=>props.handlePrint()} 
            startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark last">
                <div>
                    <h1>We’re strong on privacy</h1>
                    <p><i>If you’re unsure about anything, <a href='mailto:info@thevaluesmap.com'>please get in touch</a>
                    </i></p>
                </div>
            </section>

            <section>
                <div>
                    <p>PRIVACY</p>

                    <p>This policy explains how The Web Psychologist Limited (TWP or We or Us) will collect, store, use,
                        disclose, transfer or otherwise process your personal data in connection with any Test accessed through
                        this website. Please read this policy with our website Terms and Conditions, which provide more
                        information on TWP’s use of your information. This policy incorporates the Terms and Conditions. To the
                        extent there are any inconsistencies between this policy and our Terms and Conditions, the terms of this
                        policy take precedence.</p>


                    <p>INFORMED CONSENT</p>

                    <p>With your consent, TWP will use your Test results and data primarily: (1) for research and development
                        purposes (e.g. to validate, maintain and analyse the efficacy of the Tests); and (2) to assist with
                        candidate selection (e.g. for current and future roles) by selected third parties. Individuals may also
                        use the Tests for self-development purposes.</p>

                    <p>By agreeing to take a Test, you consent to TWP using your information in the way described in this
                        policy. You may withdraw your consent at any time by contacting <a href='mailto:info@thevaluesmap.com'>info@thevaluesmap.com</a></p>


                    <p>TEST DATA</p>

                    <p>The personal data relating to you that will be processed in connection with the Tests includes the
                        personal information that you provide to us and we collect about you when you visit this website, take a
                        Test, or communicate with us by any means (e.g. by email correspondence). This includes the information:
                        (a) you provide to TWP (or that we receive from third parties) as part of the registration process; (b)
                        the information you provide when submitting your responses (e.g., name, email address, status
                        (professional or student) and gender) as well as test results data. This is to personalise the results
                        and email you the Test report.</p>

                    <p>Test results data will also be generated and passed to TWP to assist with, where relevant, candidate
                        selection. Your personal details and test results data may also be provided to selected third parties
                        including recruiters and other parties who we engage to manage or assess applications and who may have
                        requested that you complete a Test. More generally, de-identified Test data may be used for research and
                        development purposes, as stated above.</p>

                    <p>TWP will retain Test data in accordance with its data retention procedures. Please let us know If you
                        would like us to delete Test data about you, or if you would like to access or correct any of your
                        personal or Test data that we hold, by contacting us at <a href='mailto:info@thevaluesmap.com'>info@thevaluesmap.com</a></p>


                    <p>DATA SHARING</p>

                    <p>TWP may share information amongst selected third parties for the purposes described in this policy,
                        particularly for the purpose of assisting with candidate selection. TWP may work with partners and
                        instruct third party service providers to assist (e.g. to host data or conduct analysis), in which case
                        these partners and third party service providers will have access to your information to help facilitate
                        a Test, its on-going validation and the recruitment process, as well as for general research and
                        development purposes. TWP may also disclose your information in order to comply with any legal
                        obligation; in connection with the sale, transfer or disposal of our business; to enforce our rights
                        under our terms and conditions and other related agreements, or as otherwise permitted by applicable
                        law. You acknowledge and agree that your data may be shared as described.</p>


                    <p>LOCATION OF DATA</p>

                    <p>Your data will be collected and processed in the UK. You acknowledge and consent that your data may be
                        transferred to, and stored and disclosed in the UK and other countries outside the UK and the
                        jurisdiction where you are based.</p>


                    <p>DATA SECURITY</p>

                    <p>We have put in place technical and organisational security measures to prevent the loss or unauthorised
                        access of your personal information. However, whilst we have used our best efforts to ensure the
                        security of your data, please be aware that we cannot guarantee the security of information transmitted
                        over the Internet.</p>


                    <p>CHILDREN</p>

                    <p>Anyone under the age of 18 is not permitted to take Tests assessment and TWP does not knowingly collect
                        or process data relating to anyone under the age of 18.</p>


                    <p>EQUALITY</p>

                    <p>TWP has clear procedures in place to ensure that any information you provide in a Test relating to any
                        characteristic you may have which is protected by law will be treated fairly in the assessment process.
                    </p>


                    <p>CONTACT US</p>

                    <p>You may contact us in order to correct, delete, or access any information that we hold about you. Also,
                        please contact us if you have any questions about this policy, how we use your information or if you
                        have privacy related complaints. You can reach us at <a href='mailto:info@thevaluesmap.com'>info@thevaluesmap.com</a>. We will respond to
                        any complaints within 7 business days.</p>
                </div>
            </section>
            <section className="last">
                <div><button className="big" onClick={()=>props.handleBack()}>Back</button></div>
            </section>





            <Footer handlePage={(p)=>props.handlePage(p)}/>


        </>
    );
};

export default PrivacyPage