import React from 'react'

import { Footer, TopNav, QuestionUI } from './nnv_components.js'


export const QuestionsPage_nnv_part1 = (props) => {
    /*  */
    return (
        <>
        <TopNav handleBack = {()=>props.handleBack()}
        handlePrint = {()=>props.handlePrint()}
        startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark last ">
                <div>
                    <h1>Let’s get started</h1>
                    <p><i>Please answer each question as honestly as possible (there is no ‘right’ or ‘wrong’ answer)</i></p>
                    <p   ><i><span onClick={()=>props.handleMakeFakeLikertAnswers()}>Each</span> part takes only a few minutes.</i></p>

                </div>
            </section>


            <section className="questions">
        <div>
        <h3>Start here</h3>
                    <div className="small highlight">Please select the answer that best represents your business or team</div>
          <QuestionUI
            state={props.state}
            handleChangeLikert={(e, name, value, thing) => props.handleChangeLikert(e, name, value, thing)}
            handleChangeSlider={(e) => props.handleChangeSlider(e)}
            handleChangeFreeText={(e) => props.handleChangeFreeText(e)}
            questionGroup={'part1'}
          />


        </div>
      </section>

            <section className="grey last">
                <div className='centred'>
                    
                    <button className="big"  onClick={()=>props.handlePage('QuestionsPage_nnv_part2')}>Continue to Part 2</button>
                </div>
            </section>




            <Footer 
            handlePage={(p)=>props.handlePage(p)}/>
            <div></div>
            <div onClick={e=>props.handleSubmitUserAnswersAndStoreInAirtable_nnv(e)}>handleSubmitUserAnswersAndStoreInAirtable_nnv</div>


        </>
    );
};

export default QuestionsPage_nnv_part1