import React from 'react'

import { Footer, TopNav } from './nnv_components.js'


export const QuestionsFinalPage = (props) => {
    /*  */
    const questions = props.state.nnv_freeTextQuestionsObject
    
    return (
        <>

<TopNav handleBack = {()=>props.handleBack()}
handlePrint = {()=>props.handlePrint()} 
startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark last">
                <div>
                    <h1>The final word</h1>
                    <p><i>To finish, a short exercise to help you reflect on your values in the context of your company.
                    </i></p>

                </div>
            </section>

            <section className='grey'>
                <div>
                    <h3>What three adjectives would you use to best describe your business? </h3>
                    <div>
                    <fieldset>
                        <input placeholder="e.g. 'driven'" type="text"  
                        onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'adjective_1')}
                        value = {questions['adjective_1']
                        ? questions['adjective_1'].a
                        : null}
                        />
                        <input placeholder="e.g. 'creative'" type="text"  
                        onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'adjective_2')}
                        value = {questions['adjective_2']
                        ? questions['adjective_2'].a
                        : null}
                        />
                        <input placeholder="e.g. 'quirky’'" type="text"  
                        onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'adjective_3')}
                        value = {questions['adjective_3']
                        ? questions['adjective_3'].a
                        : null}
                        />
                        </fieldset>
                    </div>
                </div>
            </section>

            <section className='grey'>
                <div>
                    <h3>What is your number one business goal?</h3>
                    <textarea placeholder="Write a sentence or two"  cols="30" rows="10"
                    onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'number_one_goal')}
                    value = {questions['number_one_goal']
                        ? questions['number_one_goal'].a
                    : null}
                    ></textarea>
                </div>
            </section>

            <section className='grey'>
                <div>
                    <h3>What is the biggest challenge you face / are working to overcome?</h3>
                    <textarea placeholder="Write a sentence or two"  cols="30" rows="10"
                    onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'biggest_challenge')}
                    value = {questions['biggest_challenge']
                        ? questions['biggest_challenge'].a:
                    null}
                    ></textarea>
                </div>
            </section>

            <section className='grey last'>
                <div>
                    <h3>How you give back</h3>
                    <textarea placeholder="Write a sentence or two"  cols="30" rows="10"
                    onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'how_you_give_back')}
                    value = {questions['how_you_give_back']
                        ? questions['how_you_give_back'].a
                        : null}
                    ></textarea>
                </div>
            </section>

            <section className="dark last">
                <div>
                    <h1>All done!</h1>
                    <p>Where shall we send your report to?</p>
                    <p className="small">The email gives you a link so you can open your report again later. You're free to <span className = 'link' onClick={(e)=>{props.handleSubmitUserAnswersAndStoreInAirtable_nnv(e); props.handlePage('ReportGoalsPage')}}>skip this</span> if you don't want the link</p>
                    <input placeholder="Your email" type="text" className="rounded"
                        onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'user_email')}
                        value = {questions['user_email']
                        ? questions['user_email'].a
                        : null}
                        />
                    <button className="big"  onClick={(e)=>{props.handleSubmitUserAnswersAndStoreInAirtable_nnv(e); props.handlePage('ReportGoalsPage')}}>Calculate results</button>
                </div>
            </section>
            <section className="dark last"><div></div></section>




            <Footer handlePage={(p)=>props.handlePage(p)}/>


        </>
    );
};

export default QuestionsFinalPage