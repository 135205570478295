import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { FooterFakes } from '../../components/FooterFakes.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
import { ScaleFeedback } from '../../components/ScaleFeedback.jsx';
// import './css/rbe.css'

export const FeedbackPageA = (props) => {


  const listOfScaleFeedbacks = props.report.rankedScaleNames.map(scale =>
    <ScaleFeedback report={props.report} scalesOrGroups={'scales'} scale={scale}
    />
  )




  return (
    <>
      <NavLogoHeader />

      <section className="navheader">
        <div>
          <h1>Report page 1</h1>
          <div className="standfirst">
            Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam
          </div>

          <Navtabs
            t={0}
            handlePage={
              (p) => props.handlePage(p, 1) /* tabs keep scroll postion */
            }
          />
        </div>
      </section>

      <section>
        <div>
          <div className="chunk">
            <h4>
              Consectetur adipiscing elit, sed do eiusmod tempor incididunt?
            </h4>
            <p>
              Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam
            </p>
          </div>
        </div>
      </section>

      {/* listOfGroupsOfScalesFeedbacks */}

      {listOfScaleFeedbacks}

      <section className="cta">
        <div>
          <h2>Next...</h2>
          <div className="standfirst">dosdfkjhsdf</div>
          <div>
            <button
              className="big strong "
              onClick={() => props.handlePage("feedbackB", 0)}
            >
              Next page
            </button>
          </div>
        </div>
      </section>

      <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
      <FooterFakes
        ///
        handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
        handleMakeFakeFeedback={() => props.handleMakeFakeFeedback()}
        handleFakeAvgScores={() => props.handleFakeAvgScores()}
      ////       
      />
    </>
  );
};

export default FeedbackPageA