import React from 'react'


export const LikertTags = props => {
  const ids = [
    "Never!",
    "Rarely",
    "Not Often",
    /* "Sort of", */
    "Typically",
    "Always!"
  ];
  /* //////////////////////////////// */
  const likert = ids.map((id) => (
    <div className="likertTag">
      <button
        id={props.name + id}
        name={props.name}
        value={ids.indexOf(id) + 1}
        onClick={(e) => props.handleChangeLikert(e)}
        className={
          /* if answered, find out if this option is the one selected */ props.a &&
            props.a === ids.indexOf(id) + 1
            ? "clicked"
            : "not"
        }
      >
        {<span className="checkmark"></span>}
        {id}
      </button>
    </div>
  ));


  return (
    <>
      <div className="answer"><span className='prompt'>How true is this for you?</span> </div>
      <div className="likertTags"> {likert} </div>
    </>
  );
}

export default LikertTags