import React from 'react';
import ReactDOM from 'react-dom';
//require('dotenv').config();
import { FEEDBACK } from './tempData/FEEDBACK_RBE.js'; //FEEDBACK_RBE.js or FEEDBACK_NNV.js
import { DBQUESTIONSRB } from './tempData/DBQUESTIONSRB.js';
import { UXQUESTIONS } from './tempData/UXQUESTIONS.js';
import { NNV_FREETEXT_QUESTIONS } from './tempData/NNV_FREETEXT_QUESTIONS.js';
import { ACCOUNTQUESTIONS2 } from './tempData/ACCOUNTQUESTIONS.js';
import { ITEMS } from './tempData/ITEMS.js';
import { SUBFACET_CONTENT } from './tempData/SUBFACET_CONTENT.js';
//import {ADVICE} from './tempData/ADVICEv1.js';
import { App } from './App.js';
//import './css/theme-pre.css' // font, color vars
//import './css/theme-pre-NN.css' // OVERRIDES with NN font, color vars
//import './css/index.css';
//import './css/theme-post-NN.css' // OVERRIDES with other NN style
/*********** need to manually set css for client*************/





////////// GET RESULTS FROM URL STRING ////////////////
// learning from https://www.w3docs.com/snippets/javascript/how-to-get-url-parameters.html
// example url http://localhost:3000/?product=troussers&color=black&newuser&size=s&scales=a1b2c3

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString)
// .get('xxx') return first vlaue for that param
// .getAll('yyy') returns all
// .has('zzz') checks param exists
const size = urlParams.get('scales');
console.log('url things', size);
urlParams.append('color', 'pink');// mess with it (appends to color)




ReactDOM.render(
  <App

    accountQuestions={ACCOUNTQUESTIONS2}
    questionsSTATIC={DBQUESTIONSRB}
    feedback={FEEDBACK}
    uxquestions={UXQUESTIONS}
    nnv_freeTextQuestionsObject={NNV_FREETEXT_QUESTIONS}
    items={ITEMS}
    subFacetContent={SUBFACET_CONTENT}
  /* advice={ADVICE} */
  />,
  document.getElementById("root")
);
