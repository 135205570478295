import React from 'react'


export const LikertDivsAnswersNotButtons = (props) => {
let ids = [
    "Strongly disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly agree"
  ];

/* if (props.client==='nnv'){
  ids = [
    "0%",
    "20%",
    "40%",
    "60%",
    "80%",
    "100%",
  ];
} */
if (props.client==='nnv'){
  /* ids = [
    "Never",
    "Rarely",
    `Not${'\u00A0'}Often`,
    `Sort${'\u00A0'}of`,
    "Typically",
    "Always"
  ]; */
  ids = [
    `Disagree v${'\u00A0'}strongly`,
    "Disagree strongly",
    "Disagree",
    "Agree",
    "Agree strongly",
    "Agree v strongly"
  ];
}


  /* //////////////////////////////// */
  const fakeLikert = ids.map((id) => (
    <>
    <div 
    className="likertFakeRadioSingleDiv"
    id={props.name + id}
    onClick={() => props.handleChangeLikert(false, props.name, ids.indexOf(id) + 1, props.name + id)}
    >
        <div className={
                /* if answered, find out if this option is the one selected */ props.a &&
                props.a === ids.indexOf(id) + 1
                ? "clicked"
                : "not"
            }>
          <div className="fakeCheckmark"></div>
          <div className="likertOptionLabel">{id}</div>
          
        </div>
    </div>
    </>
  ));

/*   const likert = ids.map((id) => (
        <div className="likertAnswerButton">
          <button
            // id={props.name + id} 
            name={props.name}
            value={ids.indexOf(id) + 1}
            // NB App/saveAnswer handles 'reverseLikert' by assuming that there are only 5 options
            // MAYBE could pass ids.length to it if this is unreliable
            onClick={(e) => props.handleChangeLikert(e)}
            className={
                // if answered, find out if this option is the one selected 
                props.a &&
                props.a === ids.indexOf(id) + 1
                ? "clicked"
                : "not"
            }
          >
            {<span className="checkmark"></span>}
            {id}
          </button>
        </div>
  )); */



  return (
    <>
      <div className="answer">
        {props.a
          ? ids[props.a - 1]
          : <span className='prompt'>How true is this for you?</span>}
      </div>
      {/* <div className="likert"> {likert} </div> */}
      <div className="fakeLikert"> {fakeLikert} </div>
    </>
  );


};

export default LikertDivsAnswersNotButtons