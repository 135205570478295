import React from 'react'




export const Footer = (props) => {
    /*  */
    return (
        <>
        <section className="dark footer last">
            <div >
                <h3>© The Web Psychologist Ltd. 2021</h3>
                <div onClick={()=>props.handlePage('PrivacyPage')} className="small">Privacy</div>
                <div onClick={()=>props.handlePage('TermsPage')} className="small">Terms</div>
            </div>
        </section>
        <section className="dark footer">
            <div >
 
                <div onClick={()=>props.handlePage('HiddenPage')} className="small dev">Dev</div>
                <div onClick={()=>console.log('state',props.state)} className="small dev">state</div>
                

            </div>
        </section>

        </>

        
    );
};

export default Footer