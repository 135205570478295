export const UXQUESTIONS = [
    {
      q: "Do you like it?",
      ui: "freeText",
      placeholder: "Ah, go on"
    },
    { q: "Oh? why not?", 
    ui: "freeText", 
    placeholder: "make it good" },
    {
      q: "Wel you'd better say somethng smarter than that",
      ui: "freeText",
      placeholder: "try again or go away"
    }
  ];
  

export default UXQUESTIONS