export const ACCOUNTQUESTIONS2 = [
  {
    q: "Email",
    ui: "freeText",
    pid: "true",
    placeholder: "Your email, please"
  },
  {
    q: "Password",
    ui: "freeText",
    pid: "true",
    password: "true",
    placeholder: "Password"
  }
];


export default ACCOUNTQUESTIONS2