import React from 'react'
import { AccountQuestionUI } from '../../components/AccountQuestionUI.jsx';
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
// import './css/rbe.css'



export const StartPage_rbe = (props) => {
  /*  */
  return (
    <>
      <NavLogoHeader
        handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
        handlePage={(p) => props.handlePage(p, 0)}
        handleBack={(e) => props.handleBack(e)}
        showJumpMenu={props.state.showJumpMenu} />

      <section className="header bgImgSurfTube">
        <div>
          <div>
            <h1>My Experience</h1>
          </div>
          <div>Start your journey towards more <br />meaningful and impactful work</div>

          <div className="accountTabs">
            <div className="accountTab">
              <br />
              <h4>Sign in</h4>

            </div>

          </div>

          <AccountQuestionUI
            state={props.state}
            handleChangeLikert={(e) => props.handleChangeLikert(e)}
            handleChangeAccountFreeText={(e) =>
              props.handleChangeAccountFreeText(e)
            }
          />



          {
            /* allow click only if valid email was entered */
            props.emailIsOk ? (
              <button
                className="big strong grey"
                onClick={() => props.handlePage('intro', 1)}
              >
                Sign in
              </button>
            ) : null
          }
        </div>
      </section>
      <section className='header last bgImgSurfTube'><div></div></section>
      <section className='header last bgImgSurfTube'><div></div></section>



      {/* <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} /> */}
      <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>
  );
};

export default StartPage_rbe