import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { FooterFakes } from '../../components/FooterFakes.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { ProgressBar } from '../../components/ProgressBar.jsx';
import { progressPercent } from '../../helpers.jsx';
import { QuestionUI } from '../../components/QuestionUI.jsx';
// import './css/rbe.css'

export const QuestionsPage = (props) => {
  /*  */
  return (
    <>

      <NavLogoHeader
        handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
        handlePage={(p) => props.handlePage(p, 0)}
        handleBack={(e) => props.handleBack(e)}
        showJumpMenu={props.state.showJumpMenu} />

      <ProgressBar progressPercent={progressPercent(props.state.questions)} />

      <section className="header last">
        <div>
          <h1>Let’s get started</h1>
          <div className="standfirst">
            Don't spend too much time overthinking your responses, there are no right or wrong answers.
          </div>

        </div>
      </section>

      <section className="questions header bgGradFacets ">
        <div>
          <QuestionUI
            state={props.state}
            handleChangeLikert={(e) => props.handleChangeLikert(e)}
            handleChangeSlider={(e) => props.handleChangeSlider(e)}
            handleChangeFreeText={(e) => props.handleChangeFreeText(e)}
            thing={'2'}
          />

          {
            /* allow click only if valid email was entered */
            props.emailIsOk ? (
              <button
                className="big strong grey"
                onClick={() => props.handlePage('ReportFacetsPage')}
              >
                Finish
              </button>
            ) : null
          }
        </div>
      </section>
      <FooterFakes
        ///
        handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
        handleMakeFakeFeedback={() => props.handleMakeFakeFeedback()}
        handleFakeAvgScores={() => props.handleFakeAvgScores()}
      ////       
      />
      <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />

    </>
  );
};

export default QuestionsPage