import React from 'react'

import { Footer, TopNav } from './nnv_components.js'

import ScaleDiagram from '../../components/ScaleDiagram.jsx'

import {formatToSentenceCaseFrom_snake_case} from '../../helpers.jsx'


import {
    pin_global_stewards,
    pin_justice_warriors,
    pin_preservers,
    pin_innovation_leaders,
} from '../nnv_importImages.js'



export const ReportInDetailPage = (props) => {

const archetype = props.state.report.archetype

const pins = {
    global_stewards: pin_global_stewards,
    justice_warriors: pin_justice_warriors,
    preservers: pin_preservers,
    innovation_leaders: pin_innovation_leaders,
}

const stateFeedback = props.state.feedback;
const report = props.state.report;

const ScaleContent = props => (
    <section className="last">
            <div>{props.score > 50
            ? <h4>{props.feedback.titles[0]} <span>{props.valued}</span></h4>
            : <h4><span>{props.valued}</span> {props.feedback.titles[0]} </h4>
            }
                <p><i>{props.feedback.about[0]}</i></p>
                <ScaleDiagram score={props.score}/>
                <p><b>{props.feedback[props.hiOrLo][0]}</b> </p>
                <p>{props.feedback[props.hiOrLo][1]} </p>
                
            </div>
        </section>
)

// filter lists of scales for: 'highly valued', 'valued' and 'we don't value'
const highlyValued = report.rankedScaleNames.filter( which => report.scales[which].avgScore > 60)

const medValued = report.rankedScaleNames.filter( which => (report.scales[which].avgScore >= 50) && (report.scales[which].avgScore <= 60))

const notValued = report.rankedScaleNames.filter( which => report.scales[which].avgScore < 50)

// make the partial lists for each category
const list_highlyValued = highlyValued.map( scale => 
<ScaleContent 
feedback = {stateFeedback[scale]}
valued='is Highly Valued'
hiOrLo = 'hi'
score={report.scales[scale].avgScore}
/>)
const list_medValued = medValued.map( scale => 
<ScaleContent 
feedback = {stateFeedback[scale]}
valued='is Valued'
hiOrLo = 'hi'
score={report.scales[scale].avgScore}
/>)
// make css odd-even possible by combining these sublists
const list_highAndMedCombined = [...list_highlyValued, ...list_medValued]

const list_notValued = notValued.map( scale => 
<ScaleContent 
feedback = {stateFeedback[scale]}
valued="We Don't Value"
hiOrLo = 'lo'
score={report.scales[scale].avgScore}
/>)

////////////// GRAPH: VALUED LESS - VALUED MORE ////////////////


// calc lengths of bars

const scores = report.rankedScaleNames.map (which=> report.scales[which].avgScore) 

const graph_values = calc_padded_graph_values_from (scores)

function calc_padded_graph_values_from (scores) {
    let 
    posMax = Math.max(...scores),
    negMin = Math.min(...scores),
    spread = posMax - negMin,
    centrePadding = (100 - spread) / 2,
    bar_lengths_from_centre_inc_padding = add_padding_to(scores, centrePadding)

    return { scores, posMax, negMin, spread, centrePadding, bar_lengths_from_centre_inc_padding }
}
function add_padding_to (scores, centrePadding) {
    let padded_scores = scores.map(score =>
        score >= 50
        ? score  = (score - 50) + centrePadding
        : score = score - 50 - centrePadding
        )
        return padded_scores
}



// assemble array for mapping to final graph
    const graph_array = report.rankedScaleNames.map(scale=>
        ({
            label : scale, 
            number : Math.round(graph_values.scores[report.rankedScaleNames.indexOf(scale)]),
            bar_length : graph_values.bar_lengths_from_centre_inc_padding[report.rankedScaleNames.indexOf(scale)],
            
        })
        )

/// html for one row
const graph_row = (label, number, bar_length) =>
    <div className="graph_row">

        <div className="posHalf">
            {number >= 50
            ? <div className="bar" Style= {`margin-left:50%; width: ${bar_length}%` }>
                <span className="label">{label}</span>
                <span className="number">{number}</span>
            </div>
            : null }
        </div>
        <div className="negHalf">
            {number < 50
            ? <div className="bar" Style= {`margin-left:${50 + bar_length}%; width: ${1-bar_length}%` }>
                <span className="number">{number /* NB swapped order */ }</span>
                <span className="label">{label}</span>        
            </div>
            : null }
        </div>
    </div>

// final graph
    const valuedMoreOrLess_graph = <div>
        {graph_array.map(row=>
        graph_row( row.label, row.number, row.bar_length )
        )}
        </div>





/////////////////////////////////////////////////





    return (
        <>

            <span className={archetype}>

               <TopNav handleBack = {()=>props.handleBack()}
light_bg = {true}
        handlePrint = {()=>props.handlePrint()}
        startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

                <section className="illusHeader ">
                    <div>
                        <h1><span >{formatToSentenceCaseFrom_snake_case(archetype)}</span> In detail</h1>
                    </div>

                <div className=' centred'><img src={pins[archetype]} alt="" /></div>
                <div className>
                    <p><i>What matters most? This section details the 10 key values that inform your archetype, and how
                            important they are to you or your organisation.</i></p>
                    </div>
                </section>

                <section className="dark tabs">
                    <div>
                        <div className="tab" onClick={()=>props.handlePage('ReportGoalsPage')}>Your Goals</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportArchetypesPage')}>Archetypes</div>
                        <div className="tab selected" onClick={()=>props.handlePage('ReportInDetailPage')}>In detail</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportActionPage')}>Action</div>
                    </div>
                </section>

                <section className="last">
                    <div>
                        <p><i>Behind the archetype</i></p>
                        <h1>What you value</h1>
                    </div>
                </section>
                <section className="last">
                <div>
                    <div className="legend">
                        <span className='legendHalfLeft'><h3>Valued Less</h3></span>
                        <span className='legendHalfRight'><h3>Valued More</h3></span>
                    </div>
                </div>
                    <div className="stackedGraphs">
                        <div  className='valuedMoreOrLess_graph under'>{valuedMoreOrLess_graph}
                        </div>
                        <div  className='valuedMoreOrLess_graph over'>{valuedMoreOrLess_graph}
                        </div>
                    </div>


                </section>

                <section className="dark last">
                    <div>
                        <h1>Valued More</h1>
                        <h4>Here are the things that have greater influence on your shared strategy and decision making</h4>
                    </div>
                </section>

                <section className="oddEven">
                    {list_highAndMedCombined}
                </section>
                



                <section></section>

                <section className="dark last">
                    <div>
                        <h1>Valued Less</h1>
                        <h4>Here are the things that have less influence on your shared strategy and decision making</h4>
                    </div>
                </section>

                
                <section className="oddEven">
                {list_notValued}
                </section>
                




                <section className='dark last'>
                    <div className='centred'>
                        <button className="big" onClick={()=>props.handlePage('ReportActionPage')}>Next: <span>Actions</span></button>
                        <div className="small">How to express your values business-wide</div>
                    </div>
                </section>


                <Footer handlePage={(p)=>props.handlePage(p)}/>

            </span>


        </>
    );
};

export default ReportInDetailPage