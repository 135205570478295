import React from 'react'

import { Footer, TopNav } from './nnv_components.js'




export const HiddenPage = (props) => {

    
    /*  */
    return (
        <>

            <TopNav handleBack = {()=>props.handleBack()}
            handlePrint = {()=>props.handlePrint()} 
            startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>


            <section className='dark'>
                <div>
                <div onClick={()=>props.handlePage('StartPage_nnv')} className="small">intro</div>
                <div onClick={()=>props.handlePage('QuestionsPage_nnv_part1')} className="small">questions</div>
                <div onClick={()=>props.handlePage('QuestionsFinalPage')} className="small">questions-final</div>
                <div  className="small"> Before open report pages,  <span className="link" onClick={()=>props.handleMakeFakeLikertAnswers()}>Prefill fake answers</span> </div>
                <div onClick={()=>props.handlePage('ReportGoalsPage')} className="small">report-goals</div>
                <div onClick={()=>props.handlePage('ReportArchetypesPage')} className="small">report-archetypes</div>
                <div onClick={()=>props.handlePage('ReportInDetailPage')} className="small">report-in-detail</div>
                <div onClick={()=>props.handlePage('ReportActionPage')} className="small">report-action</div>
                <div onClick={()=>props.handlePage('Consultancy')} className="small">Next Steps</div>
                <div onClick={()=>props.handleClearLocalId()} className="small">handleClearLocalId</div>
                </div>
            </section>


  

            <Footer handlePage={(p)=>props.handlePage(p)}/>

        </>
    );
};

export default HiddenPage