
export const CONTENT_ARRAY = {

    /* STRENGTHS */
    strengths: [
        {
            title: "(Please don't click this line)",
            blockquote: "When people talk, listen completely. Most people never listen.",
            blockquoteAuthor: "Ernest Hemingway",
            body: "You can develop a more accurate self-perception by listening to what others have to say about you and your work. One of the most reliable sources for what you do best are the opinions of people who work with you regularly. Pay attention when co-workers mention your positive traits and give you feedback about your work performance. Think about what aspects of your work receive the most compliments and determine which personality traits or skills allow you to complete those tasks.",
            XXXXX: "What aspects of your work receive the most positive feedback from colleagues?",
            YYYYY: "Which traits or skills do you think make you good at these things?",
            journalQuestions: [
                'what_aspects_of_your_work_receive_the_most_positive_feedback_from_colleagues',
                'which_traits_or_skills_do_you_think_help_you_get_positive_feedback_from_your_colleagues'
            ]
        },
        {
            title: "Listen to Others",
            blockquote: "When people talk, listen completely. Most people never listen.",
            blockquoteAuthor: "Ernest Hemingway",
            body: "You can develop a more accurate self-perception by listening to what others have to say about you and your work. One of the most reliable sources for what you do best are the opinions of people who work with you regularly. Pay attention when co-workers mention your positive traits and give you feedback about your work performance. Think about what aspects of your work receive the most compliments and determine which personality traits or skills allow you to complete those tasks.",
            XXXXX: "What aspects of your work receive the most positive feedback from colleagues?",
            YYYYY: "Which traits or skills do you think make you good at these things?",
            journalQuestions: [
                'what_aspects_of_your_work_receive_the_most_positive_feedback_from_colleagues',
                'which_traits_or_skills_do_you_think_help_you_get_positive_feedback_from_your_colleagues'
            ]
        },

        {
            title: "Think about the things you really love",
            blockquote: "You can only become truly accomplished at something you love.",
            blockquoteAuthor: "Maya Angelou",
            body: "When you enjoy doing something, it is often easier to hone your skills in that area and develop them into some of your key strengths. Understanding your natural talents and passions can help you determine where you are most willing to put in the work to become an expert. So think about what you most enjoy doing.",
            XXXXX: "In the last month, what did you really enjoy doing at work?",
            HHHHH: "Some tips when you compile your list Do you actively look forward to the activity?When you are doing the activity, does time fly by?",
            YYYYY: "Do you find it very easy to concentrate when you are doing this activity?",
            ZZZZZ: "Afterwards, are you invigorated and authentically proud, strong, or happy?",
            AAAAA: "And what did you really not enjoy?",
            AHAHAHAHAH: "Some tips when you compile your list Do you procrastinate or feel apprehensive before starting the activity? Do you struggle to complete the activity because you are bored or distracted? Does the completion of the activity leave you drained?",
            journalQuestions: [
                'in_the_last_month_what_did_you_really_enjoy_doing_at_work',
                'do_you_find_it_very_easy_to_concentrate_when_you_are_doing_this_activity',
                'afterwards_are_you_invigorated_and_authentically_proud_strong_or_happy',
                'and_what_did_you_really_not_enjoy'
            ],
        },



        {
            title: "Take a personality test",
            blockquote: "Knowing yourself is the beginning of all wisdom.",
            blockquoteAuthor: "Aristotle",
            body: "Taking a personality test can help you not only recognise the areas where you are already successful, but they can also identify your aptitude for skills and activities that you don't have experience in. There are many personality tests based specifically on workplace strengths that can help you choose a career path that will suit your natural talents and interests and one of the very best is <a href='https://www.redbull.com/int-en/wingfinder'>Red Bull’s Wingfinder</a>. Developed by Red Bull and an expert team of psychology professors from University College London and Columbia University New York, Wingfinder is an accurate, free personality assessment that focuses on your strengths. If you haven’t taken Wingfinder yet you should do!!",
            journalActivities: [{ label: 'Try WingFinder', url: 'https://www.redbull.com/int-en/wingfinder' }
            ],
        },
        {
            title: "Pay attention to when you are most productive",
            blockquote: "My success, part of it certainly, is that I have focused in on a few things.",
            blockquoteAuthor: "Bill Gates",
            body: "Throughout your day-to-day activities, make note of how long different tasks take and how productive you are during that time. If it feels like time is passing quickly and you accomplish a lot in a short period of time, it is likely that you are using some of your biggest strengths. Make a list of when you feel the most focused, and consider what characteristics motivate you during those times. When do you feel most focused at work; and why? Conversely, if you notice that time drags on during certain tasks, think about what makes you feel less motivated so that you can either avoid those situations or actively try to grow those skills.",
            XXXXX: "What really demotivates you at work; and why?",
            journalQuestions: [
                'when_do_you_feel_most_focused_at_work_and_why',
                'what_really_demotivates_you_at_work_and_why',
            ],
        },

        {
            title: "Seek out New Experiences",
            blockquote: "I am always doing that which I cannot do, in order that I may learn how to do it.",
            blockquoteAuthor: "Pablo Picasso",
            body: "Trying new skills, hobbies and activities is essential for identifying your strengths. Regularly seek out professional development opportunities and take risks by asking to get involved in projects outside of your usual skillset. You can seek out new experiences by taking classes, asking colleagues if you can shadow them, learning new technical skills and taking on leadership roles in new areas. You may be surprised at what strengths you discover by taking the risk of trying something new – whether it’s work-related or not. ",
            journalQuestions: [
                'think_of_something_outside_your_usual_skillset',
            ],
        },

        {
            title: "Build Strong Habits",
            blockquote: "Practice isn't the thing you do once you're good. It's the thing you do that makes you good.",
            blockquoteAuthor: "Malcolm Gladwell",
            body: "Successful people aren’t successful because they just rely on their strengths but because they continually use them and build upon them. A core concept here is the idea of 'deliberate practice', which means putting regular time into activities with the aim of improvement.",
            XXXXX: "Think about a strength you have that you really want to make the most of.How could you practice that 'strength' more in your day- to - day work?",
            YYYYY: "What could you change about your routine or the way you go about your work to really play to that strength?",
            journalQuestions: [
                'think_about_a_strength_you_have_that_you_really_want_to_make_the_most_of_how_could_you_practice_that_strength_more_in_your_day_to_day_work',
                'what_could_you_change_about_your_routine_or_the_way_you_go_about_your_work_to_really_play_to_that_strength',
            ]
        },
    ],











    /* GROWTH MINDSET */

    growth_mindset: [

        {
            title: "(Please don't click this line)",
            blockquote: "Even the greatest was once a beginner. Don’t be afraid to take that first step.",
            blockquoteAuthor: "Muhammad Ali",
            body: "When you have a fixed mindset, you probably won’t even start trying to learn something new. Why try if you weren’t born with the ability? And if you do start, you likely won’t stick with it if it seems challenging because the task at hand is simply not a skill that you have. The first growth mindset activity you need to do is to take that critical first step. And, this isn’t just making moves toward things that you are passionate about or really want to know how to do. Part of this is getting out there and trying new things so you can uncover your passions and truly find out what’s right for you. You have to locate your untapped potential. You may already know what fascinates you, but if you don’t know everything that is out there, you’re strongly limiting yourself.",
            XXXXX: "What do you want to do that you are too scared to do or you just think you can’t do?",
            journalQuestions: ["what_do_you_want_to_do_that_you_are_too_scared_to_do_or_you_just_think_you_cant_do"]
        },
        {
            title: "Take the first step",
            blockquote: "Even the greatest was once a beginner. Don’t be afraid to take that first step.",
            blockquoteAuthor: "Muhammad Ali",
            body: "When you have a fixed mindset, you probably won’t even start trying to learn something new. Why try if you weren’t born with the ability? And if you do start, you likely won’t stick with it if it seems challenging because the task at hand is simply not a skill that you have. The first growth mindset activity you need to do is to take that critical first step. And, this isn’t just making moves toward things that you are passionate about or really want to know how to do. Part of this is getting out there and trying new things so you can uncover your passions and truly find out what’s right for you. You have to locate your untapped potential. You may already know what fascinates you, but if you don’t know everything that is out there, you’re strongly limiting yourself.",
            XXXXX: "What do you want to do that you are too scared to do or you just think you can’t do?",
            journalQuestions: ["what_do_you_want_to_do_that_you_are_too_scared_to_do_or_you_just_think_you_cant_do"]
        },
        {
            title: "Reflect",
            blockquote: "The greatest of faults, I should say, is to be conscious of none.",
            blockquoteAuthor: "Thomas Carlyle",
            body: "No matter what you’re current mindset is, you can probably think of something that you were not good at doing when you started it, but you eventually got the hang of it.  Think about your first day at your current job.Did you show up knowing what to do? Or did you have to go through some trial and error in order to learn how to be good at what you do? You probably had to 'practice' your job by showing up each day and facing various problems that you had to work to solve. Think about what this process was like – whether it is your current job or something more basic such as driving a car or learning how to cook.Reflecting and recognizing the skills that you have improved throughout your life will help you be more open to the idea that you can learn and improve.",
            XXXXX: "What skills have you improved over time and how have managed to improve them?",
            journalQuestions: ["what_skills_have_you_improved_over_time_and_how_have_managed_to_improve_them"]
        },




        {
            title: "Seek out Learning Opportunities",
            blockquote: "I am still learning.",
            blockquoteAuthor: "Michelangelo",
            body: "Seek out learning opportunities. Specifically, seek out a learning opportunity that you think you’re not cut out for – something that really stretches you. It can be a work- related skill but it needn’t be.Just the process of learning something new is valuable practice for a growth mindset. Whatever learning opportunity you seek out, stick with it.",
            journalQuestions: [
                "think_of_something_difficult_you_want_to_learn_and_create_some_smart_goals_that_give_you_a_specific_target_to_work_toward_and_let_you_measure_your_progress_as_you_go"
            ]
        },


        {
            title: "Don’t be afraid to fail",
            blockquote: "You can’t let your failures define you. You have to let your failures teach you.",
            blockquoteAuthor: "Barack Obama",
            body: "The greatest threat to success is avoiding failure. In a fixed mindset, you avoid challenging situations that might lead to failure because success depends upon protecting and promoting your set of fixed qualities and concealing your deficiencies. If you do fail, you focus on rationalizing the failure rather than learning from it and developing your capabilities. With a growth mindset, you focus on learning and development rather than failure and actively pursue the types of challenges that will likely lead to both learning and failure.",
            XXXXX: "What failures have you really learnt something from?",
            YYYYY: "And what did you learn?",
            journalQuestions: [
                "what_failures_have_you_really_learnt_something_from",
                "and_what_did_you_learn"
            ]
        },
        {
            title: "Cultivate grit",
            blockquote: "Do not judge me by my success, judge me by how many times I fell down and got back up again.",
            blockquoteAuthor: "Nelson Mandela",
            body: "Grit can be defined as the perseverance to meet your goals. It is the amount of effort you are willing to put in to accomplish something no matter how difficult things get. And at some point or other things are always going to get difficult, so in order to have a growth mindset, you have to cultivate grit. Here are some ways you can: Pursue your interests - You’re going to have a hard time sticking to goals that don’t fascinate you.So, the first step to growing your grit is to find something that really interests you. Practice, practice, practice - Hard work leads to competence, and we’re much more likely to stick with things we’re good at.Gritty people always want to improve, no matter what it takes or how good they already are. Compete with who you were yesterday - Do a slightly harder workout.Meditate a little longer.Stick with a boring task even though you don’t want to. Connect to a higher purpose - People who connect what they do to a higher purpose are grittier.Take a step back and understand how what you do contributes to the well - being or success of others. Cultivate hope - If you want to reach your goals, you first need to believe it’s possible.Go after your goals with the belief that you can improve if you work hard at it.Because you can. Surround yourself with gritty people – One of the most efficient ways to grow your grit is a kind of positive peer pressure. Surround yourself with gritty people, and it’s only a matter of time before you’re just as gritty yourself.",
            journalQuestions: [
                "when_have_you_shown_grit"
            ]
        },
    ]

}

////////////////////////////// ADD strengths_your_development_goals://///////////////////////////






export default CONTENT_ARRAY