import React from 'react'

import { Footer, TopNav, QuestionUI } from './nnv_components.js'


export const QuestionsPage_nnv_part3 = (props) => {
    /*  */
    return (
        <>
        <TopNav handleBack = {()=>props.handleBack()}
        handlePrint = {()=>props.handlePrint()}
        startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark last ">
                <div>
                    <h1>Part 3/4</h1>
                    <p><i>3 more mins and you’re there.</i></p>
                </div>
            </section>


            <section className="questions">
        <div>

          <QuestionUI
            state={props.state}
            handleChangeLikert={(e, name, value, thing) => props.handleChangeLikert(e, name, value, thing)}
            handleChangeSlider={(e) => props.handleChangeSlider(e)}
            handleChangeFreeText={(e) => props.handleChangeFreeText(e)}
            questionGroup={'part3'}
          />


        </div>
      </section>

            <section className="grey last">
                <div className='centred'>
                    
                    <button className="big"  onClick={()=>props.handlePage('QuestionsPage_nnv_part4')}>Continue to Part 4</button>
                </div>
            </section>




            <Footer handlePage={(p)=>props.handlePage(p)}/>


        </>
    );
};

export default QuestionsPage_nnv_part3