import React from "react";
import { isEmail, howManyAnswered } from "./helpers.jsx";
import {
	/* scrollToAndStop, */ scrollToNextQuestion,
} from "./questionUIFunctions.js";
import { buildReport } from "./buildReport.js";

/* RBE page html: */
import {
	StartPage_rbe,
	IntroPage,
	QuestionsPage,
	ReportFacetsPage,
	ReportMasteryPage,
	ReportSubFacetPageStrengths,
	ReportSubFacetPageGrowthMindset,
	ReportWorkoutPage,
	FeedbackPageA,
	FeedbackPageB,
	FeedbackPageC,
	FeedbackPageD,
	Bot,
	UserFeedbackA,
	ItemsDummyPage,
	JournalTEMPPage,
	JournalPage,
	UserTestIntro,
} from "./pageHtml/rbe_html.js";

/* NNV page html: */
import {
	StartPage_nnv,
	Consultancy,
	PrivacyPage,
	QuestionsFinalPage,
	QuestionsPage_nnv_part1,
	QuestionsPage_nnv_part2,
	QuestionsPage_nnv_part3,
	QuestionsPage_nnv_part4,
	ReportActionPage,
	ReportArchetypesPage,
	ReportGoalsPage,
	ReportInDetailPage,
	TermsPage,
	HiddenPage,
} from "./pageHtml/nnv_html.js";

import "./css/questionUi.css";

/***************** TO CHANGE CLIENT... ******************/
// ... css (right below)
// ... state.client
// ... state.showPage
/***********************************/
import "./css/nnv.css"; // nnv.css | rbe.css
// could try: https://blog.bitsrc.io/theming-react-components-with-css-variables-ee52d1bb3d90

export class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			client: "nnv", // nnv | rbe
			showPage: "StartPage_nnv", // StartPage_nnv | StartPage_rbe
			/***********************************************/
			accountQuestions: this.props.accountQuestions,
			questionsSTATIC: this.props.questionsSTATIC,
			uxquestions: this.props.uxquestions,
			//nnv_freeTextQuestionsObject: this.props.nnv_freeTextQuestionsObject,
			nnv_freeTextQuestionsObject: {},
			items: this.props.items,
			feedback: {},
			report: "",
			theScale: "strengths", //strengths, growthMindset
			theContent: { strengths: 1, growth_mindset: 1 }, // think zero causes errors
			emailIsOk: true,
			scrollToWhere: 0,
			fakeHigh: true,
			history: ["start"], // was 'account' to start with first page,
			showJumpMenu: true,
			queryWasSent: false,
		};
	}

	componentDidUpdate() {
		console.log("componentDidUpdate", this.state); //// debugging
	}

	componentWillUnmount() {
		window.removeEventListener("hashchange", () => this.handleHashChange()); // for back button
	}

	/////////// SETUP: GET QUESTIONS AND CONTENT /////////////
	componentDidMount() {
		this.fetchJournalQuestions(); // rbe
		this.fetchQuestions(this.state.client);
		this.fetchFeedback(this.state.client);

		console.log(process.env.REACT_APP_WHEREAMI); // just a check

		window.addEventListener("hashchange", () => this.handleHashChange()); // for back button
	}

	fetchQuestions(client) {
		let dbKey = process.env.REACT_APP_AIRTABLE_KEY;
		let dbUrl;
		if (client === "rbe") {
			dbUrl =
				"https://api.airtable.com/v0/appj6suh5Qa5CMNIt/questions?api_key=";
		}
		if (client === "nnv") {
			dbUrl =
				"https://api.airtable.com/v0/appugHbP2Uz3W3r1S/nnv-questions?api_key=";
		}
		// default to RBE
		if (!client) {
			dbUrl =
				"https://api.airtable.com/v0/appj6suh5Qa5CMNIt/questions?api_key=";
		}

		fetch(dbUrl + dbKey)
			.then((res) => res.json())
			.then((data) => {
				this.formatAndSaveQuestionsToState(data.records); ////
			})
			// now check url/cookie id
			.then(() => {
				//this.check_id_and_maybe_build_report();
				this.check_id();
			})
			.catch((error) => {
				console.log(error);
			});
	}
	formatAndSaveQuestionsToState(dbQuestions) {
		// reformat from airtable structure to ours
		let formatted = dbQuestions.map((dbQuestion) => ({
			q: dbQuestion.fields.question,
			qid: dbQuestion.id,
			question_id: dbQuestion.fields.question_id
				? dbQuestion.fields.question_id
				: dbQuestion.id, // fallback - use the Airtable id as above
			ui: dbQuestion.fields.ui ? dbQuestion.fields.ui.toLowerCase() : "likert",
			scale: dbQuestion.fields.subFacet.toLowerCase(),
			groupOfScales: dbQuestion.fields.facet.toLowerCase(),
			key: dbQuestion.id,
			questionGroup: dbQuestion.fields.questionGroup
				? dbQuestion.fields.questionGroup
				: null,
			order: dbQuestion.fields.order ? dbQuestion.fields.order : null,
		}));
		// NEW: convert to object
		let questionsObject = {};
		formatted.forEach(
			(question) => (questionsObject[question.question_id] = question)
		);

		this.setState({
			questions: formatted,
			questionsObject: questionsObject,
		});
	}
	//// TODO  RBE SHOULD USE
	fetchFeedback(client) {
		/* TEMP ONLY  */
		/* NOTE - not clear whether RBE currently uses feedback at all */

		if (client === "rbe") {
			this.setState({ feedback: this.props.feedback });
		}

		let dbKey = process.env.REACT_APP_AIRTABLE_KEY;
		let dbUrl;

		// default to nnv
		if (!client) {
			dbUrl =
				"https://api.airtable.com/v0/appugHbP2Uz3W3r1S/nnv-feedback?api_key=";
		}

		/* NNV-specific */
		if (client === "nnv") {
			dbUrl =
				"https://api.airtable.com/v0/appugHbP2Uz3W3r1S/nnv-feedback?api_key=";
		}

		fetch(dbUrl + dbKey)
			.then((res) => res.json())
			.then((data) => {
				this.formatAndSaveFeedbackToState_nnv(data.records);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	//// TODO  RBE SHOULD HAVE OWN VARIANT
	formatAndSaveFeedbackToState_nnv(records) {
		// reformat from airtable structure to ours (NB buildreport wants Object not array)
		let formatted = {};
		records.forEach(
			(record) =>
				(formatted[record.fields.scale] = {
					lo: [
						record.fields.lo_para0 || "!no lo_para0",
						record.fields.lo_para1 || "! no lo_para1",
					],
					hi: [
						record.fields.hi_para0 || "!no hi_para0",
						record.fields.hi_para1 || "! no hi_para1",
					],
					whatThisMeasures: "!still using whatThisMeasures", // was: record.fields.whatThisMeasures, //// remove when done
					about: [record.fields.whatThisMeasures],
					titles: [record.fields.title0 || "! no title0"],
				})
		);

		this.setState({ feedback: formatted });
	}
	// RBE only
	fetchJournalQuestions() {
		fetch(
			"https://api.airtable.com/v0/appj6suh5Qa5CMNIt/journalQuestions?api_key=" +
				process.env.REACT_APP_AIRTABLE_KEY
		)
			.then((res) => res.json())
			.then((data) => {
				this.formatAndSaveJournalQuestionsToState_nnv(data.records);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	formatAndSaveJournalQuestionsToState_nnv(dbJournalQuestions) {
		// reformat from airtable structure to Object
		/* e.g.:
    {And_what_did_you_learn:
      {hint: "Lorem ipsum"
      qid: "recRufoDQs2Pqnltv"
      question: "And what did you learn?"},
What_are_you_going_to_change_about_the_way_you_do_your_work_to_really_play_to_your_strengths:
      {hint: "Lorem ipsum"
      qid: "recegLmdxTHp2oFCf"
      question: "What are you going to change about the way you do your work to really play to your strengths?"}
} */
		// am doing it this way so that it's easy to manually reference the right question inside static html pages,.

		let journalQuestions = {};

		for (let q in dbJournalQuestions) {
			let which = dbJournalQuestions[q];

			journalQuestions = {
				[which.fields.name.toLowerCase()]: {
					question: which.fields.question,
					hint: which.fields.hint,
					qid: which.id,
				},
				...journalQuestions,
			};
		}
		this.setState({ journalQuestions });
	}

	/////////// PAGE CHANGE, BACK BUTTON and PAGE HASH /////////////
	handlePage(thePage, keepScrollPosition, theScale, theContent) {
		//// EXPERIMENT for back button
		//window.location.hash = thePage
		////
		this.handleSetPageHash(thePage);

		if (
			this.state.client === "rbe" &&
			thePage !== "account" &&
			thePage !== "intro" &&
			thePage !== "questions" &&
			!this.state.questions[this.state.questions.length - 1].a
		) {
			this.handleMakeFakeLikertAnswers();
		} // props.handlePage(p, k, s, c)  (s,c are for rbe)

		if (!keepScrollPosition) {
			window.scrollTo(0, 0);
		}
		let history = [thePage, ...this.state.history];

		let newContent = this.state.theContent;
		if (theContent) {
			newContent[theScale] = theContent;
		}

		this.setState({
			//showPage: thePage,
			history,
			theScale: theScale ? theScale : this.state.theScale,
			theContent: newContent,
		});
	}
	handleBack(e) {
		//e.preventDefault();
		let history = [...this.state.history, "start"];
		history.shift();
		this.setState({
			showPage: history[0],
			history: history,
		});
	}
	handleSetPageHash(whichPage) {
		////console.log('handleSetPageHash',whichPage)
		window.location.hash = whichPage;
		this.handleHashChange();
	}
	handleHashChange() {
		let locationHash = window.location.hash;
		/* in case it's the start page, and there's no hash top go to... */
		if (!window.location.hash) {
			console.log("location.hash is blank, to setting startPage");
			locationHash = "StartPage_nnv";
		}
		/* in case it's the start page and use is being scrolled to the normal anchor to agree and continue */
		if (window.location.hash === "#anchor") {
			console.log("anchor");
			return;
		}
		/* otherwise go to the hash page */
		locationHash = locationHash.replace("#", "");
		this.setState({ showPage: locationHash });
	}
	handleStartNew_nnv() {
		console.log("handleStartNew");
		this.remove_url_id();
		this.store_local_id("x"); // = remove
		//this.handlePage("StartPage_nnv");
		window.location.reload();
	}
	handleOpenReport(user_id) {
		// only if user had opened a shared link, but clicked 'Open Mine' instead...
		if (user_id === this.state.local_id) {
			this.store_url_id(user_id);
		}

		this.fetchUserDataAndBuildReport(user_id);
	}

	/////////// HANDLE ANSWERS /////////////

	handleChangeSlider(e) {
		this.saveAnswerToState(e);
		if (e.type === "mouseup") {
			/* this. */ scrollToNextQuestion(e);
		}
	}
	handleChangeFreeText(e) {
		let updatedQuestions = this.state.questions.map((question) => question);
		let whichQuestion = updatedQuestions[e.target.name - 0];
		whichQuestion.a = e.target.value;
		whichQuestion.isEmail = isEmail(e.target.value); //// IMPROVE
		if (isEmail(e.target.value)) {
			this.setState({ emailIsOk: true });
		}
		this.setState({ questions: updatedQuestions }); // stores answer
	}
	handleChangeAccountFreeText(e) {
		let updatedQuestions = this.state.accountQuestions.map(
			(accountQuestion) => accountQuestion
		);
		let whichQuestion = updatedQuestions[e.target.name - 0];
		whichQuestion.a = e.target.value;
		whichQuestion.isEmail = isEmail(e.target.value); //// IMPROVE
		if (isEmail(e.target.value)) {
			this.setState({ emailIsOk: true });
		}
		this.setState({ accountQuestions: updatedQuestions }); // stores answer
	}
	handleUserFeedback(e) {
		let updatedQuestions = this.state.uxquestions.map((question) => question);
		let whichQuestion = updatedQuestions[e.target.name - 0];
		whichQuestion.a = e.target.value;
		whichQuestion.isEmail = isEmail(e.target.value); //// IMPROVE
		if (isEmail(e.target.value)) {
			this.setState({ emailIsOk: true });
		}
		this.setState({ uxquestions: updatedQuestions }); // stores answer
	}
	handleChangeFreeText_nnv(e, qKey) {
		// NB using an object for this, not array like all the others. Seemed better because I want a highly tweaked UI (eg three answer boxes for one question, and specific character limits on others), and did not want to simply itrate through a list of qs returned from the db using generic UI)

		let questions = { ...this.state.nnv_freeTextQuestionsObject };

		if (!questions[qKey]) {
			questions[qKey] = {};
		}

		questions[qKey].a = e.target.value;

		this.setState({ nnv_freeTextQuestionsObject: questions }); // stores answer */
	}
	handleChangeLikert(e, name, value, thing) {
		////console.log("handleChangeLikert", e, name, value, thing);
		////console.log("querySelector", document.querySelector(`#${thing}`));
		////const what = document.querySelector(`#${thing}`);
		////console.log("y", what.getBoundingClientRect().y);
		this.saveAnswerToState(e, name, value, thing);
		/* this. */ //scrollToNextQuestion(e);
	}
	// on each click, save answer and rebuild report
	//// IMPROVE: relies on shaky idea that question 'name' is same as array position. Use ids from airtable instead
	saveAnswerToState(e, name, value, thing) {
		console.log("saveAnswerToState", e, name, value, thing);

		// if still using buttons...
		if (e) {
			name = e.target.name;
			value = e.target.value;
		}

		let updatedQuestions = this.state.questions.map((q) => q);
		//only works because I've given a numerical 'name' to each question when rendered, equal to its index in state.questions

		let which = updatedQuestions.find((q) => q.question_id === /* . */ name);
		which.a =
			which.ui === "likert_reverse"
				? 6 - /* e.target. */ value // likert reverse
				: /* e.target. */ value - 0; // normal likert ('-0' forces type to number)

		this.setState({
			questions: updatedQuestions,
			howManyAnswered: howManyAnswered(updatedQuestions),
			report: buildReport(
				updatedQuestions,
				this.state.feedback,
				this.state.client
			),
		}); // stores answer
	}
	/* saveAnswerToState(e) {

		let updatedQuestions = this.state.questions.map((q) => q);
		//only works because I've given a numerical 'name' to each question when rendered, equal to its index in state.questions

		let which = updatedQuestions.find((q) => q.question_id === e.target.name);
		which.a =
			which.ui === "likert_reverse"
				? 6 - e.target.value // likert reverse
				: e.target.value - 0; // normal likert ('-0' forces type to number)

		this.setState({
			questions: updatedQuestions,
			howManyAnswered: howManyAnswered(updatedQuestions),
			report: buildReport(
				updatedQuestions,
				this.state.feedback,
				this.state.client
			),
		}); // stores answer
	} */

	/////////// SAVE TO DATABASE AND SET ID /////////////
	handleSubmitConsultancyQueryAndStoreInAirtable_nnv(e) {
		// timestamp stored with query
		const dateAdded = Date.parse(new Date());
		// free text answers
		const freeTextQuestions = this.state.nnv_freeTextQuestionsObject;

		const user_email = freeTextQuestions.user_email
			? freeTextQuestions.user_email.a
			: "NO_EMAIL@noemail";
		const contact_name = freeTextQuestions.contact_name
			? freeTextQuestions.contact_name.a
			: "Contact Name was left blank";
		const contact_query = freeTextQuestions.contact_query
			? freeTextQuestions.contact_query.a
			: "Query was left blank";
		const user_id = this.state.user_id ? this.state.user_id : null;

		// format for airtable
		//////

		const fields = {
			fields: {
				dateAdded: dateAdded,
				user_id: user_id,
				user_email,
				contact_name,
				contact_query,
			},
		};

		// store to airtable
		fetch(
			"https://api.airtable.com/v0/appugHbP2Uz3W3r1S/nnv-consultancy-queries",
			{
				method: "POST",
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(fields),
			}
		)
			.then((res) => res.json())
			.then((data) => {
				console.log("response from consultancy POST:", data);
				alert("Thank you - we'll reply as soon as we can");
				this.setState({ queryWasSent: true });
			})
			.catch((error) => alert(error));
		e.preventDefault();
	}
	handleSubmitUserAnswersAndStoreInAirtable_nnv(e) {
		// timestamp stored with answers
		const dateAdded = Date.parse(new Date());
		// free text answers
		const freeTextQuestions = this.state.nnv_freeTextQuestionsObject;
		// generate user id to store with answers
		const user_email = freeTextQuestions.user_email
			? freeTextQuestions.user_email.a
			: "NO_EMAIL@noemail";
		const user_id = this.make_user_id(user_email);
		// store id -> state
		this.setState({ user_id });
		// store id -> local storage
		this.store_local_id(user_id);

		// format for airtable
		//////

		const fields = {
			fields: {
				dateAdded: dateAdded,
				user_id: user_id,
			},
		};
		/* include likert answers */
		this.state.questions.forEach((q) => (fields.fields[q.question_id] = q.a));
		/* include free text answers (eg user_email) */
		Object.keys(freeTextQuestions).forEach(
			(key) => (fields.fields[key] = freeTextQuestions[key].a)
		);

		// store to airtable
		fetch("https://api.airtable.com/v0/appugHbP2Uz3W3r1S/nnv-user-answers", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(fields),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log("response from POST:", data);
				console.log("should store:", data.id);
				const user_id = data.id;
				// store id -> state
				this.setState({ user_id });
				// store id -> local storage
				this.store_local_id(user_id);
				// store id -> set as a url
				this.store_url_id(user_id);
			})
			.catch((error) => alert(error));
		e.preventDefault();
	}
	make_user_id(user_email = "anon") {
		const first = user_email.split("@")[0]; // eg aled@test.com -> 'aled'
		return first + "@" + Date.parse(new Date()); // eg anon@1628850493000
	}
	store_local_id(user_id = "dummy_local_id1234567890") {
		console.log("store_local_id(id)", user_id);
		if (user_id === "x") {
			localStorage.removeItem("local_id");
			return;
		}
		localStorage.setItem("local_id", user_id);
		//// promise? how to catch error
	}
	store_url_id(user_id = "dummy_url_id1234567890") {
		console.log("store_url_id", user_id);

		window.history.replaceState(
			{},
			"",
			`${window.location.pathname}?user_id=${user_id}`
		);

		//const queryString = window.location.search;
		//const urlParams = new URLSearchParams(queryString);
		/* urlParams.append("color", "pink");
		urlParams.set("foo", "bar"); */
	}
	remove_url_id() {
		console.log("remove_url_id");
		window.history.replaceState({}, "", `${window.location.pathname}`);
	}

	/////////// READ ID and REBUILD REPORT /////////////
	// newer version:

	check_id() {
		console.log("check_id", this.read_url_id(), this.read_local_id());
		const url_id = this.read_url_id();
		const local_id = this.read_local_id();
		this.setState({
			url_id,
			local_id,
		});
	}

	/* check_id_and_maybe_build_report() {
		////const state_id = this.state.user_id;
		const url_id = this.read_url_id();
		const local_id = this.read_local_id();
		let user_id;
		console.log("check_id_and_maybe_build_report()");
		console.log("url_id", url_id);
		console.log("local_id", local_id);

		//decide which user_id to fetch report from:
		// check if there are user_ids in either local storage or url params

		//... A. neither means fresh user -> do nothing
		if (!url_id && !local_id) {
			console.log("a");
			return;
		}
		//... B. both + match -> returning user, get data
		if (url_id && url_id === local_id) {
			console.log("b");
			user_id = local_id;
		}
		//... C. only local -> returning user, get data
		if (!url_id && local_id) {
			console.log("c");
			user_id = local_id;
		}
		//... D. both + differ -> local is theirs, but url could be theirs from elsewhere/email - ask (view or adopt)
		if (url_id && local_id && url_id !== local_id) {
			console.log("d");
			//window.confirm("d - overwrite?");
			user_id = url_id;
		}
		//... E. only url -> ask (view or adopt or start fresh)
		if (url_id && !local_id) {
			console.log("e");
			//window.confirm("e - overwrite?");
			user_id = url_id;
			return;
		}

		this.fetchUserDataAndBuildReport(user_id);
	} */
	read_local_id() {
		if (!localStorage.getItem("local_id")) {
			return false;
		}

		const local_id = localStorage.getItem("local_id");
		console.log("read_local_id:", local_id);
		return local_id;
	}
	read_url_id() {
		const urlParams = new URLSearchParams(window.location.search);
		console.log("read_url_id", urlParams.get("user_id"));
		return urlParams.get("user_id");
	}
	fetchUserDataAndBuildReport(user_id) {
		// construct airtable query inserting the user_id
		let dbUrlFirst =
			"https://api.airtable.com/v0/appugHbP2Uz3W3r1S/nnv-user-answers/";
		let dbUrlSecond = user_id;
		let dbUrlThird = "?api_key=";
		let dbKey = process.env.REACT_APP_AIRTABLE_KEY;

		let dbQuery = dbUrlFirst + dbUrlSecond + dbUrlThird + dbKey;

		// fetch data
		fetch(dbQuery)
			.then((res) => res.json())
			.then((data) => {
				console.log("tempTest fetching answers:", data);
				//fire populateAnswers
				this.populateAnswers(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	populateAnswers(data) {
		console.log("this.populateAnswers", data);

		// for each question in state.questions
		const questions = [...this.state.questions];
		questions.forEach(
			(q) =>
				// insert the answers to likert questions from the db data
				(q.a = data.fields[q.question_id])
		);
		// update to state
		this.setState({ questions });

		// same for free text questions
		// other way around though...
		//... find the fields that are likely to be free text answers in the db data...
		const keysToIgnore = ["user_id"]; //// might not actually matter that we filter these out
		const freeTextQuestions = { ...this.state.nnv_freeTextQuestionsObject };
		Object.keys(data.fields).forEach((key) => {
			if (
				typeof data.fields[key] === "string" &&
				keysToIgnore.indexOf(key) === -1
			) {
				// insert db answers -> state...
				// ...create fields (overwrites)
				freeTextQuestions[key] = {};
				// ...insert answer
				freeTextQuestions[key].a = data.fields[key];
			}
		});

		// finally, rebuild report
		this.setState({
			nnv_freeTextQuestionsObject: freeTextQuestions,
			report: buildReport(questions, this.state.feedback, "nnv"),
			showPage: "ReportGoalsPage",
		});
		console.log("updating report updated", this.state);
	}

	///////////////// other handlers /////////////////////
	handleSubmitUXSurvey(e) {
		const dateAdded = Date.parse(new Date());
		const fields = {
			fields: {
				dateAdded: dateAdded,
				userTest00: this.state.uxquestions[0].a,
				userTest01: this.state.uxquestions[1].a,
				userTest02: this.state.uxquestions[2].a,
			},
		};
		console.log("handleSubmitUXSurvey", dateAdded, fields);

		fetch("https://api.airtable.com/v0/appj6suh5Qa5CMNIt/userFeedback", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(fields),
		})
			.then(() => alert("Form Sent!"))
			.catch((error) => alert(error));

		e.preventDefault();
	}
	handleToggleKeepItem(e) {
		let items = this.state.items.map((i) => i);
		let itemIds = this.state.items.map((i) => i.id);
		let find = itemIds.find((f) => f === e.target.name);
		let whichItem = itemIds.indexOf(find);
		items[whichItem].isKept = !items[whichItem].isKept;
		console.log(items, whichItem, itemIds, find); ////
		this.setState({ items });
	}
	handleJournalEntry(e, which) {
		let journalUpdate = { ...this.state.journalQuestions };
		journalUpdate[which].a = e.target.value;
		this.setState({ journalQuestions: journalUpdate });
	}
	handleShowJumpMenu() {
		this.setState({ showJumpMenu: true });
	}
	handlePrint() {
		window.print();
	}

	///////////////// TESTING AND FAKE DATA /////////////////////
	tempTest() {
		console.log("no test here");
	}
	handleMakeFakeLikertAnswers() {
		// fake answers: add a : 5 to each question
		let updatedQuestions = this.state.questions.map((question) => question);
		updatedQuestions.forEach((question) => {
			question.a = Math.floor(Math.random() * 5 + 1);
		});
		this.setState({ questions: updatedQuestions });
		let feedback = this.state.feedback;
		this.setState({
			report: buildReport(updatedQuestions, feedback, this.state.client),
		});
		//this.handlePage("ReportIntroPage")
	}
	handleFakeAvgScores() {
		// make average scores have more variation
		const tempReport = this.state.report;

		Object.keys(tempReport.scales).forEach(
			(name) =>
				// add text section to scale's report
				(tempReport.scales[name].avgScore = Math.floor(Math.random() * 100 + 1))
		); //random 1-100

		this.setState({
			report: tempReport,
		});
	}
	handleSetArchetype_for_testing(archetype_name) {
		let report = this.state.report;
		report.archetype = archetype_name;
		this.setState({ report });
	}
	///////////////// TESTING AND FAKE DATA /////////////////////

	renderPage(page) {
		// solution for DYNAMIC COMPONENT NAMES
		// 1. put component refs into an object
		const Pages = {
			// RBE //
			UserTestIntro: UserTestIntro,
			StartPage_rbe: StartPage_rbe,
			IntroPage: IntroPage,
			QuestionsPage: QuestionsPage,
			ReportFacetsPage: ReportFacetsPage,
			ReportMasteryPage: ReportMasteryPage,
			ReportSubFacetPageStrengths: ReportSubFacetPageStrengths,
			ReportSubFacetPageGrowthMindset: ReportSubFacetPageGrowthMindset,
			ReportWorkoutPage: ReportWorkoutPage,
			FeedbackPageA: FeedbackPageA,
			FeedbackPageB: FeedbackPageB,
			FeedbackPageC: FeedbackPageC,
			FeedbackPageD: FeedbackPageD,
			Bot: Bot,
			UserFeedbackA: UserFeedbackA,
			ItemsDummyPage: ItemsDummyPage,
			JournalTEMPPage: JournalTEMPPage,
			JournalPage: JournalPage,

			// NNV //
			StartPage_nnv: StartPage_nnv,
			Consultancy: Consultancy,
			PrivacyPage: PrivacyPage,
			QuestionsFinalPage: QuestionsFinalPage,
			QuestionsPage_nnv_part1: QuestionsPage_nnv_part1,
			QuestionsPage_nnv_part2: QuestionsPage_nnv_part2,
			QuestionsPage_nnv_part3: QuestionsPage_nnv_part3,
			QuestionsPage_nnv_part4: QuestionsPage_nnv_part4,
			ReportActionPage: ReportActionPage,
			ReportArchetypesPage: ReportArchetypesPage,
			ReportGoalsPage: ReportGoalsPage,
			ReportInDetailPage: ReportInDetailPage,
			TermsPage: TermsPage,
			HiddenPage: HiddenPage,
		};
		// 2. check for unrecognised page param
		if (!Pages[page]) {
			return false;
		}

		// 3. by-pass jsx and use basic react...
		return React.createElement(
			// ... Call the component from the object (list) above (here the actual name was passed in as the function param 'page' . e.g. renderPage('IntroPage') - see further down in the render)
			Pages[page],
			{
				//... convert props to object items like this
				state: this.state,
				handlePage: (p, keep, s, c) => this.handlePage(p, keep, s, c),
				handleBack: () => this.handleBack(),
				/* handleBack: (e) => this.handleBack(e), */
				handleShowJumpMenu: () => this.handleShowJumpMenu(),
				handleMakeFakeLikertAnswers: () => this.handleMakeFakeLikertAnswers(),
				handleFakeAvgScores: () => this.handleFakeAvgScores(),
				handleChangeLikert: (e, name, value, thing) =>
					this.handleChangeLikert(e, name, value, thing),
				handleChangeSlider: (e) => this.handleChangeSlider(e),
				handleChangeFreeText: (e) => this.handleChangeFreeText(e),
				handleChangeFreeText_nnv: (e, qKey) =>
					this.handleChangeFreeText_nnv(e, qKey),
				handleChangeAccountFreeText: (e) => this.handleChangeAccountFreeText(e),
				emailIsOk: this.state.emailIsOk,
				handleJournalEntry: (e, which) => this.handleJournalEntry(e, which),
				theScale: this.state.theScale,
				theContent: this.state.theContent,
				report: this.state.report,
				journalQuestions: this.state.journalQuestions,
				handleKeepAdvice: (e) => this.handleKeepAdvice(e),
				handleToggleKeepItem: (e) => this.handleToggleKeepItem(e),
				handleSetArchetype_for_testing: (archetype_name) =>
					this.handleSetArchetype_for_testing(archetype_name),
				handleSubmitUserAnswersAndStoreInAirtable_nnv: (e) =>
					this.handleSubmitUserAnswersAndStoreInAirtable_nnv(e),
				handleSubmitConsultancyQueryAndStoreInAirtable_nnv: (e) =>
					this.handleSubmitConsultancyQueryAndStoreInAirtable_nnv(e),
				handlePrint: () => this.handlePrint(),
				tempTest: () => this.tempTest(),
				handleClearLocalId: () => this.store_local_id("x"),
				handleStartNew_nnv: () => this.handleStartNew_nnv(),
				handleOpenReport: (user_id) => this.handleOpenReport(user_id),
			}
		);
	}

	render() {
		/////////////////////// trying catch-all   ////////////////
		if (this.renderPage(this.state.showPage)) {
			return this.renderPage(this.state.showPage);
		}
		/////////////////////// special case to handle hitting 'back' too far ///////////////////////////////
		if (this.state.showPage === "start") {
			if (this.state.client === "nnv") {
				return this.renderPage("StartPage_nnv");
			}
			if (this.state.client === "rbe") {
				return this.renderPage("account");
			}
		}

		/////////////////////// NNV html ///////////////////////////////
		if (this.state.showPage === "StartPage_nnv") {
			return this.renderPage("StartPage_nnv");
		}

		/////////////////////// RBE html   ////////////////
		if (this.state.showPage === "UserTestIntro") {
			return this.renderPage("UserTestIntro");
		}
		if (this.state.showPage === "Bot") {
			return this.renderPage("Bot");
		}
		if (this.state.showPage === "account") {
			return this.renderPage("StartPage_rbe");
		}
		if (this.state.showPage === "intro") {
			return this.renderPage("IntroPage");
		}

		if (this.state.showPage === "questions") {
			return this.renderPage("QuestionsPage");
		}

		if (this.state.showPage === "ReportIntroPage") {
			return (
				<div>
					{" "}
					Let Aled know you're seeing "ReportIntroPage" and what you clicked
					right before this{" "}
				</div>
			);
		}
		if (this.state.showPage === "ReportFacetsPage") {
			return this.renderPage("ReportFacetsPage");
		}
		if (this.state.showPage === "ReportMasteryPage") {
			return this.renderPage("ReportMasteryPage");
		}

		if (this.state.showPage === "ReportSubFacetPageStrengths") {
			return this.renderPage("ReportSubFacetPageStrengths");
		}
		if (this.state.showPage === "ReportSubFacetPageGrowthMindset") {
			return this.renderPage("ReportSubFacetPageGrowthMindset");
		}
		if (this.state.showPage === "ReportWorkoutPage") {
			return this.renderPage("ReportWorkoutPage");
		}
		if (this.state.showPage === "feedbackA") {
			return this.renderPage("FeedbackPageA");
		}
		if (this.state.showPage === "feedbackB") {
			return this.renderPage("FeedbackPageB");
		}
		if (this.state.showPage === "feedbackC") {
			return this.renderPage("FeedbackPageC");
		}
		if (this.state.showPage === "feedbackD") {
			return this.renderPage("FeedbackPageD");
		}
		if (this.state.showPage === "ItemsDummyPage") {
			return this.renderPage("ItemsDummyPage");
		}
		if (this.state.showPage === "JournalTEMPPage") {
			return this.renderPage("JournalTEMPPage");
		}
		if (this.state.showPage === "JournalPage") {
			return this.renderPage("JournalPage");
		}
		if (this.state.showPage === "UserFeedbackA") {
			return this.renderPage("UserFeedbackA");
		}

		/////////////

		/////////////////////// default   ////////////////

		return <>Oops nothing rendered from App</>;
	}
} // ====== end App ===========================

export default App;
