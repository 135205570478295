import React from 'react'

import { Footer, TopNav } from './nnv_components.js'

import {
    nn_book_cover_on_white
} from '../nnv_importImages.js'


export const Consultancy = (props) => {

    const questions = props.state.nnv_freeTextQuestionsObject
    
    /*  */
    return (
        <>

            <TopNav handleBack = {()=>props.handleBack()}
            handlePrint = {()=>props.handlePrint()} 
            startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark">
                <div>
                    <h1><span>Apply this to</span> your business</h1>
                    <p><i>Want a more detailed, personalised approach to understanding, expressing or pivoting your brand and
                        business values?</i></p>
                    <p><i>We can help.
                    </i></p>
                </div>
            </section>

            <section className="dark tabs">
                <div>
                <div>
                        <div className="tab" onClick={()=>props.handlePage('ReportGoalsPage')}>Your Goals</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportArchetypesPage')}>Archetypes</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportInDetailPage')}>In detail</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportActionPage')}>Action</div>
                    </div>
                </div>
            </section>

            <section className="grey">
                <div>
                    <h1>Consultancy</h1>
                    <p><b>Do you want Nathalie Nahai and Dr Kiki Leutner to come and work with your team? </b></p>
                    <p>Get in touch if you want us to help you apply The Values Map to:</p>
                    <ul className="benefits">
                        <li>Differentiate your Business mission and product</li>
                        <li>Create trust with your Branding and Advertising</li>
                        <li>Connect to the right market through Marketing and PR</li>
                        <li>Build a meaningful Business Culture</li>
                        <li>Inspire purposeful Teams</li>
                        <li>Starting, founding, discovering?</li>
                    </ul>
                </div>
            </section>

            <section className="grey last">
                <div>
                    <form action="">
                        <fieldset>
                            <input type="text" placeholder="Name" name="" id="" 
                            onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'contact_name')}
                            value = {questions['contact_name']
                        ? questions['contact_name'].a
                        : null}
                            />
                            <input type="text" placeholder="Email" name="" id="" 
                            onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'user_email')}
                            value = {questions['user_email']
                        ? questions['user_email'].a
                        : null}
                            />
                            <textarea placeholder="Query" name="" id="" cols="30" rows="10"
                            onChange = {(e)=>props.handleChangeFreeText_nnv(e, 'contact_query')}
                            value = {questions['contact_query']
                        ? questions['contact_query'].a
                        : null}
                            ></textarea>
                        </fieldset>
                        { !props.state.queryWasSent 
                        ? <button onClick={(e)=>props.handleSubmitConsultancyQueryAndStoreInAirtable_nnv(e)} type="submit" className="big">Talk to us</button>
                        : <h3 >Thank you</h3>
                    }
                    </form>
                </div>
            </section>

            <section>
                <div>
                    <h3>Discover the book</h3>
                    <p>The Values Map is a companion tool to <span className='link'> <a href="https://www.businessunusualthebook.com/"> Business Unusual: Values, Uncertainty and the
                        Psychology of Brand Resilience</a></span>, the new book by best-selling author Nathalie Nahai. A rich, science-based tour de force,
                        this is a great place to start if you want to understand how to meet evolving consumer needs and build
                        resilience in the face of complex change.</p>
                        <div >
                        <a className='noUnderline' href="https://www.businessunusualthebook.com/"><img className="bookCover" src={nn_book_cover_on_white} alt="Business Unusual: Values, Uncertainty and the Psychology of Brand" /></a>
                        </div>

                </div>
            </section>

            <section className=' grey last'>
                <div>
                    <h3>Share with colleagues</h3>
                    <div>on <a href="https://twitter.com/intent/tweet?text=The%20Values%20Map&amp;url=https://thevaluesmap.com/"  target="_blank" rel="noopener noreferrer">Twitter</a>
                        , or  <a href="http://www.facebook.com/share.php?u=https://thevaluesmap.com/"  target="_blank" rel="noopener noreferrer">Facebook</a>
                        , or <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://thevaluesmap.com/"  target="_blank" rel="noopener noreferrer">LinkedIn</a>
                        </div>
                </div>
            </section>
            <section className='grey last'><div></div></section>

            <section className="dark">
                <div>
                    <h1>About us</h1>
                </div>
            </section>

            <section className="dark">
                <div>
                    <p>
                        <span className='h5'>Nathalie Nahai</span> is an international speaker, consultant and best-selling author
                        of Webs of Influence
                        and Business Unusual, whose work explores persuasive technology, ethics, and the psychology behind
                        evolving
                        consumer behaviours. A member of the BIMA Human Insights Council, she also hosts The Hive Podcast and
                        contributes to national media on consumer behaviour and the impact of technology in our lives.
                    </p>
                    <p>Website: <a target='_blank' rel="noreferrer" href='https://www.nathalienahai.com/'>nathalienahai.com</a></p>
                </div>
            </section>

            <section className='dark'>
                <div>
                    <p>
                        <span className='h5'>Dr. Kiki Leutner</span> is a business psychologist and an expert in innovative
                        psychometric assessment,
                        personality theory, and behavioral analytics. She is a Lecturer at Goldsmiths College, University of
                        London.
                        Her academic work is published in peer reviewed journals.
                    </p>
                    <p>Website: <a target='_blank' rel="noreferrer" href='https://www.gold.ac.uk/institute-management-studies/staff/leutner-franziska-kiki/'>gold.ac.uk/institute-management-studies/staff/leutner-franziska-kiki/</a></p>
                </div>
            </section>

            <section className='dark last'>
                <div>
                    <p>App created by <span className='h5'>Aled Evans</span>. Aled directs interface design and digital customer
                        journeys for brands that want a more humane, ethical
                        and
                        emotionally intelligent connection with the people they seek to serve.</p>
                </div>
            </section>

  

            <Footer handlePage={(p)=>props.handlePage(p)}/>

        </>
    );
};

export default Consultancy