export const ITEMS = [
    /* 
    {
        id: 'xxx',
        type: 'xxx',
        thing: 'xxx',
        scale: 'xxx',
        kept: false, // timestamp ( | 'deleted' |)
        dateKept: 'xxx',
    },
    */
    {
        id: 'xxx',
        type: 'xxx',
        thing: 'xxx',
        scale: 'xxx',
        isKept: true,
        dateKept: 'xxx',
    },
    {
        id: 'yyy',
        type: 'yyy',
        thing: 'yyy',
        scale: 'yyy',
        isKept: false,
        dateKept: 'yyy',
    },
    {
        id: 'zzz',
        type: 'zzz',
        thing: 'zzz',
        scale: 'zzz',
        isKept: false,
        dateKept: 'zzz',
    }
]
export default ITEMS