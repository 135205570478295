
export const SUBFACET_CONTENT = {

    listen_to_others: {
        title: "Listen to Others",
        blockquote: "When people talk, listen completely. Most people never listen.",
        blockquoteAuthor: "Ernest Hemingway",
        body: "You can develop a more accurate self-perception by listening to what others have to say about you and your work. One of the most reliable sources for what you do best are the opinions of people who work with you regularly. Pay attention when co-workers mention your positive traits and give you feedback about your work performance. Think about what aspects of your work receive the most compliments and determine which personality traits or skills allow you to complete those tasks.",
        XXXXX: "What aspects of your work receive the most positive feedback from colleagues?",
        YYYYY: "Which traits or skills do you think make you good at these things?",
    },

    think_about_the_things_you_really_love: {
        title: "Think about the things you really love",
        blockquote: "You can only become truly accomplished at something you love.",
        blockquoteAuthor: "Maya Angelou",
        body: "When you enjoy doing something, it is often easier to hone your skills in that area and develop them into some of your key strengths. Understanding your natural talents and passions can help you determine where you are most willing to put in the work to become an expert. So think about what you most enjoy doing.",
        XXXXX: "In the last month, what did you really enjoy doing at work?",
        HHHHH: "Some tips when you compile your list Do you actively look forward to the activity?When you are doing the activity, does time fly by?",
        YYYYY: "Do you find it very easy to concentrate when you are doing this activity?",
        ZZZZZ: "Afterwards, are you invigorated and authentically proud, strong, or happy?",
        AAAAA: "And what did you really not enjoy?",
        AHAHAHAHAH: "Some tips when you compile your list Do you procrastinate or feel apprehensive before starting the activity? Do you struggle to complete the activity because you are bored or distracted? Does the completion of the activity leave you drained?",
    },

    pay_attention_to_when_you_are_most_productive: {
        title: "Pay attention to when you are most productive",
        blockquote: "My success, part of it certainly, is that I have focused in on a few things.",
        blockquoteAuthor: "Bill Gates",
        body: "Throughout your day-to-day activities, make note of how long different tasks take and how productive you are during that time. If it feels like time is passing quickly and you accomplish a lot in a short period of time, it is likely that you are using some of your biggest strengths. Make a list of when you feel the most focused, and consider what characteristics motivate you during those times. When do you feel most focused at work; and why? Conversely, if you notice that time drags on during certain tasks, think about what makes you feel less motivated so that you can either avoid those situations or actively try to grow those skills.",
        XXXXX: "What really demotivates you at work; and why?",
    },

    take_a_personality_test: {
        title: "Take a personality test",
        blockquote: "Knowing yourself is the beginning of all wisdom.",
        blockquoteAuthor: "Aristotle",
        body: "Taking a personality test can help you not only recognise the areas where you are already successful, but they can also identify your aptitude for skills and activities that you don't have experience in. There are many personality tests based specifically on workplace strengths that can help you choose a career path that will suit your natural talents and interests and one of the very best is Red Bull’s Wingfinder. Developed by Red Bull and an expert team of psychology professors from University College London and Columbia University New York, Wingfinder is an accurate, free personality assessment that focuses on your strengths. If you haven’t taken Wingfinder yet you should do!! Wingfinder link",
    },

    seek_out_new_experiences: {
        title: "Seek out New Experiences",
        blockquote: "I am always doing that which I cannot do, in order that I may learn how to do it.",
        blockquoteAuthor: "Pablo Picasso",
        body: "Trying new skills, hobbies and activities is essential for identifying your strengths. Regularly seek out professional development opportunities and take risks by asking to get involved in projects outside of your usual skillset. You can seek out new experiences by taking classes, asking colleagues if you can shadow them, learning new technical skills and taking on leadership roles in new areas. You may be surprised at what strengths you discover by taking the risk of trying something new – whether it’s work-related or not. Think of something outside your usual skillset you have always wanted to do but, for whatever reason, you have never got around to doing. Learning a new language, sky-diving, being able to work pivot tables in excel ... something that is going to stretch you.",
    },

    build_strong_habits: {
        title: "Build Strong Habits",
        blockquote: "Practice isn't the thing you do once you're good. It's the thing you do that makes you good.",
        blockquoteAuthor: "Malcolm Gladwell",
        body: "Successful people aren’t successful because they just rely on their strengths but because they continually use them and build upon them. A core concept here is the idea of 'deliberate practice', which means putting regular time into activities with the aim of improvement.",
        XXXXX: "Think about a strength you have that you really want to make the most of.How could you practice that 'strength' more in your day- to - day work?",
        YYYYY: "What could you change about your routine or the way you go about your work to really play to that strength?",
    },

    ////////////////////////////// ADD strengths_your_development_goals://///////////////////////////



};


export default SUBFACET_CONTENT