import React from 'react'
import { ReactComponent as Logo } from '../images/redbullcom-logo.svg'
import { QuickPageMenu } from './QuickPageMenu.jsx'

export const NavLogoHeader = props => {

  return (
    <>
      <div className="NavLogoHeader">
        <div className="zTest">
          {/* <div className='logoWrapper'> */}
          <div className="Logo" onClick={() => props.handlePage("account", 0)}>
            <Logo />

          </div>
          <span
            className="backBtn smallLink"
            onClick={(e) => props.handleBack(e)}
          > <i className="fa fa-chevron-left" aria-hidden="true"></i> back</span>
          <div className="menu">
            {props.showJumpMenu && <QuickPageMenu
              handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
              handlePage={(p) => props.handlePage(p, 0)}
            />}

          </div>
          {/* </div> */}
          <button className="demoBtn" onClick={() => { window.history.go(-1) }}>Exit the Demo</button>
        </div></div>
    </>)
}

export default NavLogoHeader