import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { FacetsNav } from '../../components/FacetsNav.jsx';
import { CONTENT_ARRAY } from '../../tempData/CONTENT_ARRAY.jsx';
/* import { CONTENT } from '../../tempData/CONTENT.jsx'; */
/* import { GROWTH_CONTENT } from '../../tempData/GROWTH_CONTENT.jsx'; */

/* import arrow_circle_left_ICON from '../../images/arrow_circle_left_ICON.png'; */
import { SaveBtn } from '../../components/SaveBtn.jsx';
// import './css/rbe.css'


import { rbe_arrowcircleleftICON, rbe_arrowcirclerightICON } from '../rbe_importImages.js'


export const ReportWorkoutPage = props => {

    const [whichContent, setWhichContent] = React.useState(props.theContent[props.theScale])

    const content = CONTENT_ARRAY[props.theScale][whichContent];

    const whichBg = (props.theScale === 'strengths' ? "bgImgMedicineBall" : "bgImgSkatingAir")

    const whichBackLinkText = (props.theScale === 'strengths' ? "Leverage your Strengths" : "Develop a Growth Mindset")

    const whichBackLinkTarget = (props.theScale === 'strengths' ? "ReportSubFacetPageStrengths" : "ReportSubFacetPageGrowthMindset")

    return (<>

        <NavLogoHeader
            handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
            handlePage={(p) => props.handlePage(p, 0)}
            handleBack={(e) => props.handleBack(e)}
            showJumpMenu={props.state.showJumpMenu} />

        <section className={`FacetsNavHeader slightlyDeeper ${whichBg} `}>
            <div>
                <FacetsNav
                    handlePage={(p) => props.handlePage(p)}
                />
                <h4 onClick={() => props.handlePage(whichBackLinkTarget, 0)} ><i className="fa fa-chevron-left" aria-hidden="true"></i>{whichBackLinkText}</h4>
            </div>
        </section>

        <section className={`navheader cardTop noPadding ${whichBg}`}>
            <div className=''>
                <h1>{content.title}</h1>
                <blockquote>{content.blockquote}</blockquote> <span className="comment">{content.blockquoteAuthor}</span> <SaveBtn />

                <div className="fakeCard fakeCardLeft" onClick={() => prevContent()}><h1>Seek out new experiences</h1></div>
                <div className="fakeCard fakeCardRight" onClick={() => nextContent()}><h1 >Think about the things you really love</h1></div>
                <div className="arrowLeft" onClick={() => prevContent()}><img src={rbe_arrowcircleleftICON} alt="Previous" /></div>
                <div className="arrowRight" onClick={() => nextContent()}><img src={rbe_arrowcirclerightICON} alt="Next" /></div>
            </div>
        </section>

        <section className="cardBottom">
            <div className=''>
                <div className="standfirst" dangerouslySetInnerHTML={createMarkup(content.body)}>

                </div>


                <div className="journalQuestions">
                    {content.journalQuestions
                        ? content.journalQuestions.map(jq =>
                            <>
                                <JournalEntry
                                    which={props.journalQuestions[jq]}
                                    handleJournalEntry={(e) => props.handleJournalEntry(e, jq)}
                                    handleJournalButton={() => props.handlePage("JournalPage", 0, props.theScale, whichContent)}
                                /></>
                        )
                        : null}
                </div>


                {content.journalQuestions
                    ? (
                        <div><button className='big' onClick={() => props.handlePage("JournalPage", 0, props.theScale, whichContent)}>View in My Journal</button></div>
                    )
                    : null}

            </div>
        </section>

        <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>)

    function nextContent() {
        console.log('next ', whichContent)
        if (whichContent < CONTENT_ARRAY[props.theScale].length - 1) {
            setWhichContent(whichContent + 1)
        }
    }
    function prevContent() {
        console.log('prev ', whichContent)
        if (whichContent > 1) { // avoiding zero
            setWhichContent(whichContent - 1)
        }
    }

    function createMarkup(which) {
        return { __html: which };
    }



}

export default ReportWorkoutPage

/////////////////

const JournalEntry = props => {
    return (<>
        <div className="journalEntry">
            <div className="workoutQuestion strong">{props.which.question}</div>
            <div className="workoutResponse">
                <textarea
                    rows='4'
                    onChange={(e) => props.handleJournalEntry(e, props.which)}
                />
                <div className="hint">{props.which.hint}</div>


            </div>
        </div>
    </>)
}


