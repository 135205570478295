import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { ItemsDummy } from '../../components/ItemsDummy.jsx';
import { ItemsKept } from '../../components/ItemsKept.jsx';
// import './css/rbe.css'

export const ItemsDummyPage = props => {
    /*  */
    return (
        <>
            <NavLogoHeader />

            <section className="intro header">
                <div>
                    <h1>ItemsDummyPage</h1>
                    <div className="standfirst">
                        Demo: click items to add to Locker
                    </div>
                    <div>
                        <button
                            className="big strong"
                            onClick={() => props.handlePage()}
                        > Start </button>
                    </div>
                </div>
            </section>

            <section>
                <div>
                    <ItemsDummy
                        items={props.items}
                        handleClick={(e) => props.handleToggleKeepItem(e)}
                    />
                    <p />.<p />.<p />
                    <ItemsKept
                        items={props.items}
                        handleClick={(e) => props.handleToggleKeepItem(e)}
                    />
                </div>
            </section>

            <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />

        </>
    );
};

export default ItemsDummyPage

