import React from 'react'
import { FreeTextAnswers } from './FreeTextAnswers.jsx';


export const AccountQuestionUI = (props) => {
  let listOfQuestions = props.state.accountQuestions.map((question) => (
    <div className="accountQuestion">
      {/* <div className = 'questionText'>{question.q}</div> */}
      <FreeTextAnswers
        name={props.state.accountQuestions.indexOf(question)}
        placeholder={question.placeholder}
        password={question.password}
        handleThisChange={(e) =>
          props.handleChangeAccountFreeText(e)
        }
      />
    </div>
  ));
  return <div className="listOfAccountQuestions">{listOfQuestions}</div>;
};

export default AccountQuestionUI