import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
// import './css/rbe.css'

export const FeedbackPageC = (props) => {
  /* let listOfFeedback = 'listOfFeedback' */
  return (
    <>
      <NavLogoHeader />

      <section className="navheader">
        <div>
          <h1>Report page 3</h1>
          <div className="standfirst">
            Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam
          </div>

          <Navtabs
            t={2}
            handlePage={
              (p) => props.handlePage(p, 1) /* tabs keep scroll postion */
            }
          />
        </div>
      </section>

      <section className="cta">
        <div>
          <h2>Call to action</h2>
          <div className="standfirst">
            Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam
          </div>
          <div>
            <button className="big strong ">Action</button>
          </div>
        </div>
      </section>

      <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>
  );
};

export default FeedbackPageC