// looks nice in questions page scrolling
//scrolls down height of (e.target)

export function scrollToNextQuestion(e, pause, yScrollDist) {
	const fudgeFactor = 0; // adding extra px if (eg) height is/was changed by hiding labels.
	let which;
	if (e) {
		which =
			e.target.parentElement.parentElement.parentElement.getBoundingClientRect();
	}

	/* /////////// changing behaviour for Roger  - now will scroll to specific point  ////////////////// */
	let targetY = 60; /* set target height from top */
	let dy = which.y - targetY;
	let distanceToMove = which.height + dy;

	////// TO DO uuuuugh. actually i need the height of the next question, not this. ////Later.
	///// ALSO... was trivial css (scroll-behavior: smooth) except for safari, so had to do js anim

	//scrollToAndStop(window.scrollY - (which.height + fudgeFactor), pause);
	scrollToAndStop(window.scrollY - (distanceToMove + fudgeFactor), pause);
}
export function scrollToAndStop(whereTo, pause) {
	const numFrames = 30;
	const accel = 1.1;
	const dur = 700; // ms duration of motion
	const initialPause = pause ? pause : 1000; //ms before motion starts

	/* ////////////////////////////// changing behaviour for Roger / RBE  ///////////////////////////////////// */
	// originally designed to
	let yDistance = window.scrollY - whereTo;

	// calc ease-out steps like 'falling in reverse'
	let arr = [];
	let factor = 1;
	for (let x = 1; x <= numFrames; x++) {
		let xFromEnd = numFrames - x;
		arr[xFromEnd] = x * factor;
		factor *= accel;
	}

	// calc a scale factor so that steps add up to 1x the desired distance
	let sum = 0;
	for (let s = 0; s < arr.length; s++) {
		sum += arr[s];
	}
	let steps = arr.map((y) => (y / sum) * yDistance);

	// set a bunch of timeouts that adjust window.scroll by this set of steps
	for (let f = 0; f < steps.length; f++) {
		setTimeout(
			() => window.scrollBy(0, steps[f]),
			Math.round(dur / numFrames) * f + initialPause
		);
	}
}
