import React from 'react'

export const SaveBtn = props => {
    // eg <SaveBtn saved>

    return (
        <button className='SaveBtn'>
            {props.saved
                ? <i className="fa fa-star"></i>
                : <i className="fa fa-star-o" aria-hidden="true"></i>}
            Save
        </button>
    );
};

export default SaveBtn