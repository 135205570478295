import React from 'react'


export const FreeTextAnswers = (props) => {
  return (
    <div className="freeText">
      <input
        type={props.password ? "password" : "text"}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.handleThisChange(e)}
      ></input>
    </div>
  );
};

export default FreeTextAnswers