import React from 'react'

import {
    nnv_exampleduckduckgoIMG,
    nnv_examplenikeIMG,
    nnv_examplepatagoniaIMG
} from '../nnv_importImages.js'


export const content = (props) => {


    /*  */
    return (
        <>

                <section>
                    <div>
                        <h3>Mission</h3>
                        <p>Your mission statement should connect everyone to your vision for a healthy society and natural
                            world,
                            and create a sense of action and novelty. It should also spark excitement and curiosity as you
                            explore
                            new paths that lead the way towards a brighter future. Your language should convey togetherness and
                            a
                            deep common commitment for the welfare of all.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Culture</h3>
                        <p>The internal culture of your organisation rewards collaboration, listening and the support of others
                            in
                            and outside the company. You expect employees to be loyal and supportive, to promote sustainable
                            practices and to practice justice. Your culture rewards independent, creative behaviour. Your team
                            asks
                            questions, is curious and is constantly on the lookout for the new. Employees act independently to
                            get
                            things done rather than relying on hierarchy. Enjoyment and excitement are a part of everyday work.
                        </p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Brand</h3>
                        <p>Externally, your brand portrays a sense of care and commitment to the greater good, through offering
                            change and something new and unique. You break with conventions and pursue excitement and joy. You
                            stand
                            for justice and welfare for all, and strive to bring acceptance and kindness to every area you
                            touch.
                        </p>
                    </div>
                </section>

                <section className="last">
                    <div>
                        <h3>Communication</h3>
                        <p>To communicate your values effectively, focus on others and the concerns of the larger group. Do
                            provide
                            advice, volunteer to help and feel free to criticise the status quo, expressing worry or concerns
                            about
                            important topics where necessary. Use language that signifies deep thinking and analysis of themes
                            that
                            matter to you, and show that you address complex topics. Feel free to use unconventional and
                            colloquial
                            language (even swear words if appropriate!) and make convicted statements of certainty based on your
                            analyses. You needn’t be restrained by rules around language and writing. In terms of content,
                            address
                            future-looking topics and avoid writing about the past or family. Connecting to leisure, music, sex
                            and
                            art will help communicate a more hedonistic brand image.

                        </p>
                        <div className='footnote'><hr />Communication recommendations for your values camp are based on <a href='https://journals.sagepub.com/doi/full/10.1002/per.2254'>scientific research on
                            values
                            and
                            word use.</a></div >
                    </div>
                </section>

                <section className="grey last">
                    <div>
                        <h3>Key words</h3>
                        <p>Using key words like these will help to communicate your values to customers, and help manifest your
                            company culture internally.
                        </p>
                        <div>
                            { ['Humans', 'inclusive', 'ally', 'friend', 'social', 'we', 'us', 'think', 'can', 'know', 'but', 'parent', 'friend', 'people', 'neighbour', 'afraid', 'include', 'health', 'always', 'everything', 'never', 'swear words', 'see', 'watch', 'hear', 'music', 'love', 'sex', 'game', 'movie', 'beer' ].map(word=> <span className="tag">{word}</span>)}

                        </div>
                        <div className='footnote'><hr />Key words are matched to your value camp based on <a href='https://repositories.lib.utexas.edu/handle/2152/31333'>scientific research on values
                            and
                            word use.</a></div>

                    </div>
                </section>

                <section>
                    <div>
                        <h3>Real world examples <span>Brands in this Archetype</span></h3>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Patagonia</h3>
                        <div className="example">
                            <img src={nnv_examplepatagoniaIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>This example from Patagonia communicates that the brand is solving problems both for humanity and the environment. By including consumers in the manufacturing process they create transparancy, as well as a sense of togetherness and care for each other and nature. Addressing worries around consumption, Patagonia showcases leadership and innovation through the adaption of new, forward-looking manufacturing processes. Imagery of the natural world clearly communicates the brand’s passion for, and focus on, caring for the living world.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>Nike</h3>
                        <div className="example">
                            <img src={nnv_examplenikeIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>For Nike's 30th aniversary campaign, their decision to work with NFL athlete, Colin Kaepernick (widely cited as the originator of bend-the-knee protests against racial injustice and police brutality) sends a strong signal of the brand's stance on social justice issues. The use of decisive language conveys conviction and a bold call to action.</p>
                    </div>
                </section>

                <section>
                    <div>
                        <h3>DuckDuckGo</h3>
                        <div className="example">
                            <img src={nnv_exampleduckduckgoIMG} alt="" />
                        </div>
                        <p><i>Key features</i></p>
                        <p>In the face of heightened concerns around increasing surveillance, this simple ad conveys a radical focus on the core service a search engine should provide: privacy. By standing in opposition to the complex and questionable approaches of advertising-based search engines, this message puts Duck Duck Go on the side of the consumer, creating a common goal. The use of red creates a sense of danger, urgency and action, and the white text, "Take back your privacy!" stands in contrast, conveying a sense of safety and purity.</p>
                    </div>
                </section>

        </>
    );
};

export default content