import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { scrollToNextQuestion } from '../../questionUIFunctions.js'
// import './css/rbe.css'


const botQuestions = [
    { q: 'What is your development goal?', scale: 'strengths' },
    { q: 'How do you plan to achieve it?', scale: 'strengths', secondary: "Top Tip: Research shows that the best way to learn is to focus 70% of your time practicing the skill you want to develop, 20% getting feedback or learning from others and 10% reading or attending a workshop/course" },
    { q: 'Things I am going to practice', scale: 'strengths' },
    { q: 'How I plan to learn from colleagues', scale: 'strengths' },
    { q: 'Training and development resources I am going to take up?', scale: 'strengths' },
    { q: 'When do you aim to achieve it by?', scale: 'strengths' },
    { q: '(no more in this part of the demo)', scale: 'strengths' }
]




export const Bot = (props) => {



    const [nextQuestion, setNextQuestion] = React.useState(1);

    botQuestions[0].show = 1;

    let botSequence = botQuestions.map(q =>
        <div key={q.q}
            className={
                (q.show && (botQuestions.indexOf(q) !== (botQuestions.length - 1)))
                    ? 'show step'
                    : 'hide step'}>
            <div className={
                q.done
                    ? 'done'
                    : ''}>
                <div className="botQuestion">{q.q}</div>
                {q.secondary
                    ? <div className="botSecondary">{q.secondary}</div>
                    : null}
                {!q.done
                    ? (<div>
                        <input type="text" onChange={(e) => handleAnswerTEMP(e)}></input>
                        <button
                            className='saveBtn'
                            onClick={(e) => handleNext(e)}
                        >Enter</button>
                    </div>)
                    : <div
                        className="botAnswer"><div onClick={(e) => scrollToNextQuestion(e)}>{q.a}</div></div>}
            </div>
        </div>
    )

    const lastQuestion = botQuestions.length - 1;
    botQuestions[lastQuestion].show = false;



    return (
        <>
            <NavLogoHeader
                handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
                handlePage={(p) => props.handlePage(p, 0)}
                handleBack={(e) => props.handleBack(e)}
                showJumpMenu={props.state.showJumpMenu} />
            <section className='botSection bgImgMountaintop'><div>Thinking about your ‘mindset’ and how it impacts on your work, what goals can you set yourself that could make a difference to your performance now and in the future.</div></section>
            <section className='botSection bgImgMountaintop'>
                <div className="Bot">
                    {botSequence}</div>
            </section>

            <section className='botSection bgImgMountaintop'><div>If you want to share your goals with your manager for input or with anyone else …. Alternatively, use your development goals around your mindset to</div></section>
            <section className='botSection bgImgMountaintop'><div>
                <button
                    onClick={() => props.handlePage("ReportMasteryPage", 0)}
                    className='big strong'>Back to Mastery</button></div></section>
            <Footer />




        </>
    )


    function handleAnswerTEMP(e) {
        console.log('a:', e.target.value)
        botQuestions[nextQuestion - 1].a = e.target.value
    }

    function handleNext(e) {
        botQuestions[nextQuestion - 1].done = 1;
        botQuestions[nextQuestion].show = 1;
        setNextQuestion(nextQuestion + 1)
    }
}




