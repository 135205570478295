import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
import { FacetsNav } from '../../components/FacetsNav.jsx';
import { ScaleRoundGraph } from '../../components/ScaleRoundGraph.js';
// import './css/rbe.css'

export const ReportMasteryPage = (props) => {
    const whichGroup = 'mastery'



    const rankedSubFacets = ["strengths", "growth_mindset", "learning", "progress"];



    return (
        <>
            <NavLogoHeader
                handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
                handlePage={(p) => props.handlePage(p, 0)}
                handleBack={(e) => props.handleBack(e)}
                showJumpMenu={props.state.showJumpMenu} />
            <section className="FacetsNavHeader bgGradWideGraphs bgImgSurfTubeDarker">
                <div>
                    <FacetsNav
                        handlePage={(p) => props.handlePage(p)}
                    />
                </div>
            </section>

            <section className="navheader bgGradWideGraphs bgImgSurfTubeDarker">
                <div>
                    <div >
                        <div className='heroGraph'><ScaleRoundGraph score={props.report.scales[whichGroup].avgScore} sizeFactor={1.6} /></div>

                        <h1>Mastery</h1>
                        <p>{props.report.scales[whichGroup].whatThisMeasures}</p>
                    </div>
                    <div className='facetIntro'><div className="standfirst">Mastery is a fundamental human motivation. It encompasses your desire to get better at the work you do and also your sense of personal growth and progress. Satisfying the need for Mastery means embracing your innate curiosity and appetite for learning. But it also means understanding and leveraging your strengths.</div></div>


                    <Navtabs
                        t={0}
                        handlePage={p => props.handlePage(p, 0)}
                    />
                </div>
            </section>





            <section><div>
                <h4>Your score for Mastery is {props.report.scales[whichGroup].avgScore}%. <span className='aboutH4'>What does this mean?</span></h4>
                <p className="standfirst">The higher you score, the more Mastery you have.</p>
                <p>There is no good or bad score and you are not being compared with anyone else. But high levels of Mastery are associated not just with higher performance at work but with a profound sense of achievement, flow, and personal progress. So, whatever your score, increasing it can only be good.</p>

            </div></section>

            <section className='navheader bgGradWideGraphs'><div>
                To really make sense of your score for Mastery we need to look at what it’s made up of. Mastery is made up of four things. <em>You score best for ‘{displayNiceSubFacetName(rankedSubFacets[0])}’ and your lowest score is for ‘{displayNiceSubFacetName(rankedSubFacets[3])}’.</em>

            </div></section>

            <section className=" navheader wideBarGraphs bgGradWideGraphs">

                {/* {showWideBarGraph(rankedSubFacets[0], 'Highest score')}
                {showWideBarGraph(rankedSubFacets[1], 'Highest score')}
                {showWideBarGraph(rankedSubFacets[2], 'Highest score')}
                {showWideBarGraph(rankedSubFacets[3], 'Highest score')} */}
                {showWideBarGraph('strengths', 'live')}
                {showWideBarGraph('growth_mindset', 'live')}
                {showWideBarGraph('learning')}
                {showWideBarGraph('progress')}

                <div>But remember, you want to move the dial on all these dimensions of your Mastery.</div>

            </section>


            <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
        </>
    );

    //////////////////////////////////



    function showWideBarGraph(scale, isLive) {

        const scoreAsFraction = props.report.scales[scale].avgScore / 100;
        const maxVWidthUnitsFromCenter = 25;
        const zeroPoint = 50 - maxVWidthUnitsFromCenter;
        const scoreToBarLength = zeroPoint + (scoreAsFraction * maxVWidthUnitsFromCenter * 2)
        let barWidthStyle = `width : ${scoreToBarLength}vw`;
        const holderWidth = zeroPoint + (maxVWidthUnitsFromCenter * 2)
        const holderStyle = `width : ${holderWidth}vw`

        return (<>
            <span className={isLive ? "live" : null}>
                <div className='subFacetWideBarGraph'>
                    <div
                        className='holder bgImgFencer'
                        Style={holderStyle}
                        onClick={() => props.handlePage(openWhichPage(scale), 0, scale)}
                    >
                        <div
                            className="bar"
                            Style={barWidthStyle}>

                            <h4>{displayNiceSubFacetName(scale)}{' '}<span className='aboutH4'>{Math.round(props.report.scales[scale].avgScore)}%</span></h4>
                        </div>
                        <button className="strongBtn open"
                        > <i className="fa fa-chevron-right" aria-hidden="true"></i>Improve </button>
                    </div>
                </div>
            </span>
        </>)

    }


    function openWhichPage(scale) {
        return (scale === 'strengths' ? 'ReportSubFacetPageStrengths' : 'ReportSubFacetPageGrowthMindset')
    }

    function displayNiceSubFacetName(facet) {
        switch (facet) {
            case 'strengths': return 'Strengths'
            case 'growth_mindset': return 'Growth Mindset'
            case 'learning': return 'Learning'
            case 'progress': return 'Progress'
            default: return facet
        }
    }
};

export default ReportMasteryPage