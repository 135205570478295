import React from 'react'
///// fix legal links
export const Footer = (props) => {
  return (
    <section className="footer last">
      <div>
        <a href='https://www.redbull.com/' className="smallLink">
          &#169; Red Bull 2021
          </a>


      </div>
      <p onClick={() => props.handleShowJumpMenu()}>..</p>
    </section>
  );
};

export default Footer