import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { ScaleRoundGraph } from '../../components/ScaleRoundGraph.js';
// import './css/rbe.css'


export const ReportFacetsPage = (props) => {

    return (
        <>
            <NavLogoHeader
                handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
                handlePage={(p) => props.handlePage(p, 0)}
                handleBack={(e) => props.handleBack(e)}
                showJumpMenu={props.state.showJumpMenu} />

            <section className="header bgGradFacets last">
                <div>
                    <h1>Thank you</h1>
                    <div>The questions you’ve answered measure four fundamental aspects of your relationship with your work</div>
                    <div className="comment">
                        Scores updated: {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date())}
                    </div>
                    <div className='facetGraphs '>
                        {/* Roger briefed: 'first and last, and then the others'*/}
                        {showGraph(0, 'rankedGroupNames', 'Highest score')}
                        {showGraph(3, 'rankedGroupNames', 'Lowest score')}
                        {showGraph(1, 'rankedGroupNames')}
                        {showGraph(2, 'rankedGroupNames')}
                    </div>


                </div>
            </section>


            <section className="">
                <div>
                    <h4>Research shows that these are four fundamental human needs</h4>
                    <p>and your scores are a measure of how much these needs are met by the work you do. The more they are met, the greater the impact you are likely to have at work, whatever you do.</p>

                    <p>My Experience gives you tools and resources you need to help you meet these fundamental needs.</p>
                </div>
            </section>

            <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
        </>
    );

    //////////////////////////////////

    function showGraph(which, whichList, about) {
        const scale = props.report[whichList][which];

        return (<>
            <div
                className="facetsPageGraph card"
                id={scale}
                onClick={() => props.handlePage(`ReportMasteryPage`, 0)}
            >
                <ScaleRoundGraph score={props.report.scales[scale].avgScore} />
                <div className='facetGraphTitle'>
                    <h4>
                        < span className='aboutH4'>{about}</span><br />

                        {displayNiceFacetName(scale)}

                    </h4>
                </div>

                <p className='definition'>{props.report.scales[scale].whatThisMeasures}</p>
                <button className='big outline' > Open</button>
            </div>
        </>)
    }

    function displayNiceFacetName(facet) {
        switch (facet) {
            case 'meaningfulness': return 'Meaningfulness'
            case 'freedom_responsibility': return 'Freedom and Responsibility'
            case 'relatedness': return 'Relatedness'
            case 'mastery': return 'Mastery'
            default: return facet
        }
    }



};

export default ReportFacetsPage


