import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
import { FacetsNav } from '../../components/FacetsNav.jsx';
import { SaveBtn } from '../../components/SaveBtn.jsx';
// import './css/rbe.css'

export const ReportSubFacetPageGrowthMindset = props => {
    return (<>
        <NavLogoHeader
            handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
            handlePage={(p) => props.handlePage(p, 0)}
            handleBack={(e) => props.handleBack(e)}
            showJumpMenu={props.state.showJumpMenu} />
        <section className="FacetsNavHeader bgImgSkatingAir">
            <div>
                <FacetsNav
                    handlePage={(p) => props.handlePage(p)}
                />
            </div>
        </section>

        <section className="navheader bgImgSkatingAir">
            <div>
                <h1>Growth Mindset</h1>
                <blockquote>Love challenges, be intrigued by mistakes, enjoy effort and keep on learning</blockquote>
                <div className="blockquoteAuthor">Carol Dweck</div>
                <SaveBtn />

                <Navtabs
                    t={0}
                    handlePage={
                        (p) => props.handlePage(p, 1) /* tabs keep scroll postion */
                    }
                />

            </div>
        </section>
        <section className="">
            <div>
                <div className="standfirst">
                    A key dimension of Mastery is a growth mindset. People with growth mindsets are constantly trying to learn and grow to better themselves.  They enjoy challenges and believe that their intelligence, talents, and basic abilities can be increased or enhanced through hard work and dedicated effort. In a fixed mindset, “your qualities are carved in stone.” Whatever skills, talents and capabilities you have are predetermined and finite. Whatever you lack, you will continue to lack. This fixed mindset applies not just to your own qualities, but to the qualities of others. In a growth mindset, “your basic qualities are things you can cultivate through your efforts…everyone can change and grow through application and experience.” Qualities like intelligence are a starting point, but success comes as a result of effort, learning, and persistence.
                </div>
                <div>In a growth mindset</div>
                <p><i className="fa fa-cog fa-spin"></i>your basic qualities are things you can cultivate through your efforts</p>
                <p><i className="fa fa-cog fa-spin"></i>everyone can change and grow through application and experience</p>
                <p><i className="fa fa-cog fa-spin"></i>Qualities like intelligence are a starting point, but success comes as a result of effort, learning, and persistence</p>

            </div>
        </section>
        <section className="header last">
            <div>
                <h4>How can I develop a growth mindset?</h4>
                <div>
                    Having a growth mindset means you embrace challenges, persist in the face of setbacks, take responsibility for your words and actions, and acknowledge that effort is the path toward mastery. It is basically the reason why “practice makes perfect."
                </div>

            </div>
        </section>
        <section><div>
            By choosing to make the extra effort to build a growth mindset, you can make your mental processes work for you, resulting in a greater likelihood that you get the results you're looking for and live the life you want to live.
        </div></section>
        <section>
            <div>
                <h4><em>Use these steps</em> as a guide toward developing a growth mindset:</h4>
                <div className='workouts'>

                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'growth_mindset', 1)} ><i className="fa fa-paper-plane" aria-hidden="true"></i>Take the first step<button >5min exercise</button></div>

                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'growth_mindset', 2)}  ><i className="fa fa-paper-plane" aria-hidden="true"></i>Reflect<button >15min exercise</button></div>

                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'growth_mindset', 3)} ><i className="fa fa-paper-plane" aria-hidden="true"></i>Seek out learning opportunities<button >5min exercise</button></div>

                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'growth_mindset', 4)} ><i className="fa fa-paper-plane" aria-hidden="true"></i>Don’t be afraid to fail<button > weekly exercise</button></div>

                    <div className='card'
                        onClick={() => props.handlePage('ReportWorkoutPage', 0, 'growth_mindset', 5)} ><i className="fa fa-paper-plane" aria-hidden="true"></i>Cultivate grit<button >5mins daily</button></div>

                    <div className='card goals' onClick={() => props.handlePage('Bot', 0)}>Your Development Goals<button >Lorem ipsum</button></div>

                </div>
            </div>
        </section>
        <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>)
}

export default ReportSubFacetPageGrowthMindset