import React from 'react'


export const ScaleRoundGraph = (props) => {
  const scoreAsFraction = (props.score / 100)
  const deg = props.score ? (scoreAsFraction * 360) : 10;
  const cssRound = `--rotateGraphTo: ${deg}deg`
  return (
    <>
      <div
        className="ScaleRoundGraph"
        Style={cssRound}>

        <div className="ScaleRoundGraphOuter">
          <div className="ScaleRoundGraphInner"></div>

        </div>
        <div className="ScaleRoundMetric">
          <div className="number"><h4>{Math.floor(props.score)}</h4>%</div>
        </div>
      </div>
    </>
  );
};

export default ScaleRoundGraph