import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { FacetsNav } from '../../components/FacetsNav.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
import { CONTENT_ARRAY } from '../../tempData/CONTENT_ARRAY.jsx';
// import './css/rbe.css'




export const JournalPage = props => {

    console.log(props.state.theScale, props.state.theContent)


    if (!props.journalQuestions) { return 'loading journal' }

    let contentSection = CONTENT_ARRAY[props.state.theScale].map(x => x) // 

    const contentIndex = props.state.theContent[props.state.theScale]
    const workout = contentSection[contentIndex] // CONTENT_ARRAY.strengths[1]


    function journalEntries() {
        let journalEntriesTemp;
        if (workout.journalQuestions) {
            journalEntriesTemp = workout.journalQuestions.map(i =>
                props.journalQuestions[i].a
                    ? <div key={props.journalQuestions[i].qid} className='was_card'
                        onClick={() => { console.log(contentSection.indexOf(workout)) }}>
                        <div className="journalQuestion" onClick={() => props.handlePage("ReportWorkoutPage", 0, props.state.theScale, contentSection.indexOf(workout))}>{props.journalQuestions[i].question}</div>
                        <div className="journalAnswer">
                            <span className=''>{props.journalQuestions[i].a}</span>
                        </div>
                    </div>
                    : <div key={props.journalQuestions[i].qid}
                        className='was_card journalQuestion'
                        onClick={() => props.handlePage("ReportWorkoutPage", 0, props.state.theScale, contentSection.indexOf(workout))}
                    >
                        <div className="">{props.journalQuestions[i].question}</div>
                    </div>)
        }
        else {
            journalEntriesTemp = <div
                className="journalQuestion"
                onClick={() => props.handlePage("ReportWorkoutPage", 0, props.state.theScale, contentSection.indexOf(workout))}>Not a writing exercise (take a look...)</div>
        }
        return journalEntriesTemp
    }

    /////////////////////////////


    const workoutsMenu = (
        <select
            onChange={(e) => props.handlePage('JournalPage', 1, props.state.theScale, e.target.value)}
            className="workoutsMenu"
            value={contentIndex}
        >
            {contentSection.map(workout =>
                <option
                    value={contentSection.indexOf(workout)}
                >{workout.title}</option>
            )}
        </select>)



    return (
        <>
            <NavLogoHeader
                handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
                handlePage={(p) => props.handlePage(p, 0)}
                handleBack={(e) => props.handleBack(e)}
                showJumpMenu={props.state.showJumpMenu} />
            <section className="FacetsNavHeader bookHeader">
                <div>
                    <FacetsNav
                        handlePage={(p) => props.handlePage(p)}
                    />
                </div>
            </section>
            <section className="navheader bookHeader">
                <div>

                    <Navtabs
                        t={1}
                        handlePage={
                            (p) => props.handlePage(p, 1) /* tabs keep scroll postion */
                        }
                    />
                </div>
            </section>

            <section className="journalNav bookNav">
                <div>
                    {/* facetsMenu */}

                    <select
                        onChange={(e) => props.handlePage('JournalPage', 1, e.target.value)}
                        id="journalNavSubFacets"
                        name="journalSubNavFacets"
                        value={props.state.theScale}>

                        <option value="strengths" defaultValue>My Strengths</option>

                        <option value="growth_mindset">My Growth Mindset</option>
                        <option value="growth_mindset">My Learning</option>
                        <option value="growth_mindset">My Progress</option>
                    </select>

                    {workoutsMenu}
                </div>
            </section>
            <section className="book">
                <div className="leftPage">

                    <div>
                        <h4><span className="aboutH4">{props.state.theScale === 'strengths' ? "Strengths Journal: " : "Growth Mindset Journal: "}</span><br /> {workout.title}</h4>

                        {journalEntries()}

                    </div>
                    <div className="bookRightPage"></div>
                    <div className="bookRightPageGutter"></div>
                </div>
            </section>


            <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />

        </>
    );
};

export default JournalPage

