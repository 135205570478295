
export const FEEDBACK = {

	meaningfulness: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "The extent to which the work you do has real meaning and purpose",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	relatedness: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "The extent to which you have healthy, trusting and constructive relationships with your colleagues at work",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	mastery: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "The extent to which you leverage your strengths at work and you learn and grow in your role",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	freedom_responsibility: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "The extent to which you are free to do your work in the way you want to do it and embrace the responsibility that goes with that freedom",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},

	purpose: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	trust: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	goals: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	progress: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	growth_mindset: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	support: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	autonomy: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	inclusion: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	clear_goals: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	accountability: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	contribution: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	self_expression: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	learning: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	strengths: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	ownership: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	consistency: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},
	respect: {
		hi: [
			"High Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis.",
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit."
		],
		lo: [
			"Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et. Minima id qui et dolores rerum. Sint architecto doloremque et aut laboriosam ut. Quae id id asperiores ad magnam ut voluptatibus vitae. Et facilis laboriosam unde voluptate consequatur at sit.",
			"Similique omnis omnis blanditiis magni quia nihil. Eaque molestias deleniti consequuntur alias distinctio. Velit quaerat soluta et consequatur. Consectetur tempora exercitationem vel quas repudiandae aperiam perferendis."
		],
		whatThisMeasures: "Voluptas voluptatem eius aut est aliquam. Fugiat alias dolorem numquam odio nihil ipsa excepturi et.",
		advice: [
			"Advice 1 doloremque et aut laboriosam ut.",
			"Advice 2 fugiat alias dolorem numquam odio nihil ipsa excepturi et."
		]
	},



	////////////////////////////
};


export default FEEDBACK