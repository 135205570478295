import React from 'react'
///// fix legal links
export const FacetsNav = (props) => {
    return (<>
        <div className='FacetsNav'>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportFacetsPage')}>All</span>
            <span className='FacetsNavLink this' onClick={() => props.handlePage('ReportMasteryPage')}>Mastery</span>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportMasteryPage')}>Freedom</span>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportMasteryPage')}>Meaning</span>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportMasteryPage')}>Relatedness</span>


        </div>
    </>)
};

export default FacetsNav