import React from 'react'



export const ItemsKept = (props) => {

    const list = props.items.filter(item => (item.isKept !== false))

    return (
        <div className="items">
            {list.map(item =>
                <div className="itemCard">
                    <span className="item">Kept: {item.thing} </span>
                    <button name={item.id} onClick={(e) => props.handleClick(e)}>{item.isKept ? 'remove' : 'keep'}</button>
                </div>)}
        </div>)
};

export default ItemsKept