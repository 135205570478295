import { add_nnv_reportStuff } from './nnv_reportFunctions.js';




export function buildReport(questions, feedback, client) {
    // Report builds itself from whatever scales and groups it finds in the questions.  
    let report = pushScalesAndScoresToReport(questions);
    setAverageScores(report, questions);
    // NB feedback needs names that match those in questions
    setReportText(report, feedback)

    // add nnv-specific additions eg archetype
    if ( client=== 'nnv' ){ add_nnv_reportStuff(report) }

    return (report);
}


////////////////////////////////////////////////////////

function pushScalesAndScoresToReport(questions) {
    let report = { scales: {}, /* groupsOfScales: {}  */};
    questions.forEach(q => (pushy(q, report)))    // collects scores of each questions's scale
    questions.forEach(q => (pushy(q, report, 'yes')))  // then collects scores of each questions's groupOfScale
    return report
}
function pushy(q, report, isGroupOfScales) {
    let scales = report.scales, scale = q.scale, a = q.a;

    if (isGroupOfScales) { scale = q.groupOfScales }; // enables the hack above to treat the group like another scale
    scales[scale] // if scale exists...
        ? scales[scale].scores.push(getLikertAsPercent_rbe(a)) // ... appends score
        : scales[scale] = { scores: [getLikertAsPercent_rbe(a)] } // ...or adds scale and score if not
}
function getLikertAsPercent_rbe(a) {  // converting to percent will allow scores from different question types to work together
    switch (a) {
        case 1: return 0;
        case 2: return 20;
        case 3: return 50;
        case 4: return 70;
        case 5: return 90;
        default: return null
    }
}//// NB RedBull specific: based on email from Roger, 13 april 21





function setAverageScores(report, questions) {
    let listOfScaleNames = getListOfScaleNames(questions);
    let listOfGroupNames = getListOfGroupNames(questions);

    listOfScaleNames.forEach(scale =>
        report.scales[scale].avgScore = getAvg(report.scales[scale].scores)); //getAvg ignores zeros

    listOfGroupNames.forEach(scale =>
        report.scales[scale].avgScore = getAvg(report.scales[scale].scores)); //getAvg ignores zeros

    report.rankedScaleNames = sortByAverage(listOfScaleNames, report.scales);
    report.rankedGroupNames = sortByAverage(listOfGroupNames, report.scales);

    return report
}
function getListOfScaleNames(questions) {
    let scaleNames = [];
    questions.forEach((question => scaleNames.push(question.scale)))
    const nameSet = new Set(scaleNames)
    const nameArr = [...nameSet];
    return nameArr
}
function getListOfGroupNames(questions) {
    let groupNames = [];
    questions.forEach((question => groupNames.push(question.groupOfScales)))
    const nameSet = new Set(groupNames)
    const nameArr = [...nameSet];
    return nameArr
}
function getAvg(array) {
    let arrayNoZeros = array.filter(n => n !== null); //// understand better, and make more robust? (using null because zero is a value in Roger's scoring. Might break with undefined?)
    let sum = 0;
    arrayNoZeros.forEach(num => sum += num);
    let avg = (sum / arrayNoZeros.length)
    return (arrayNoZeros.length > 0) ? avg : null;
}// NB getAvg now ignores zeros
function sortByAverage(list, reportList) {
    let sorted = list
        .map(s => s)
        .sort(function (a, b) {
            return reportList[b].avgScore - reportList[a].avgScore;
        }); // sorts numerically not alpha // (b-a) gives descending

    return sorted;
}


function setReportText(report, feedback) {
    // iterate object (NB there are group names in with scales - they have dummy text in the db)
    Object.keys(report.scales).forEach(name =>
        // add text section to scale's report
        report.scales[name] = {
            ...report.scales[name],
            feedback: [getHiOrLoText(feedback[name], report.scales[name].avgScore)],
            whatThisMeasures: feedback[name].whatThisMeasures,
            //advice: feedback[name].advice
        }
    )
    return report
}
function getHiOrLoText(feedback, avgScore) {
    if (avgScore === null) { return }
    let hiOrLo = avgScore >= 50 ? 'hi' : 'lo'
    return feedback[hiOrLo]
}


