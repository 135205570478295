
export const DBQUESTIONSRB = [
    {
        id: "rec0QsLt31ngOeAQM",
        fields: {
            question: "It is important to me that my work is meaningful.",
            facet: "meaningfulness",
            subFacet: "purpose"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rec1L7rLKa0e5rJ14",
        fields: {
            question: "I trust the people I work with and I feel like they trust me",
            facet: "relatedness",
            subFacet: "trust"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rec4EfbpbcWZzGOON",
        fields: {
            question: "I reflect on my work and set myself goals to aim higher",
            facet: "meaningfulness",
            subFacet: "goals"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rec5PJpzWEBTCmSKC",
        fields: {
            question: "I am driven by a fear of failure rather than a drive to achieve my goals",
            facet: "mastery",
            subFacet: "progress"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rec6PXZlaDP98PtHl",
        fields: {
            question: "When faced with a challenge, I sometimes give up too easily",
            facet: "mastery",
            subFacet: "growth_mindset"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rec6bLanPygUqoTYe",
        fields: {
            question: "I am not afraid to ask for my colleagues' help when I need it",
            facet: "relatedness",
            subFacet: "support"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rec6k2pkrH8Ldnu9H",
        fields: {
            question: "I always have a say in important decisions that affect my work",
            facet: "freedom_responsibility",
            subFacet: "autonomy"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recBQz0xGpLmb7Vjd",
        fields: {
            question: "This is an organisation where you can have different opinions and beliefs and still get along with one another",
            facet: "relatedness",
            subFacet: "inclusion"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recBkCRWQa0cz42E5",
        fields: {
            question: "My manager and I are clear about the goals I am working towards",
            facet: "freedom_responsibility",
            subFacet: "clear_goals"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recCMK8QzrkY1fcQt",
        fields: {
            question: "I struggle with the pressure that comes with people relying on me at work",
            facet: "freedom_responsibility",
            subFacet: "accountability"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recCscNOdOGDoYRLo",
        fields: {
            question: "I ask colleagues, customers and clients for feedback on my work",
            facet: "meaningfulness",
            subFacet: "contribution"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recDrFcUhdIpq07Qb",
        fields: {
            question: "People at work rely on me to do my job well and I enjoy that responsibility",
            facet: "freedom_responsibility",
            subFacet: "accountability"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recEHnpkc5fanfN5d",
        fields: {
            question: "The people I work with are not afraid to be absolutely honest with each other",
            facet: "relatedness",
            subFacet: "trust"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recExJMWXCgeFbogV",
        fields: {
            question: "I know what the organisation, my team and my manager expect from me",
            facet: "freedom_responsibility",
            subFacet: "clear_goals"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recO6yItcTrACsPFC",
        fields: {
            question: "I don't feel able to express myself at work - my opinion is not valued",
            facet: "freedom_responsibility",
            subFacet: "self_expression"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recOwCvZOTtAIrxeN",
        fields: {
            question: "At work, I don't seem to have a clear role or responsibilities",
            facet: "freedom_responsibility",
            subFacet: "clear_goals"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recSBOe6qd4fGADfN",
        fields: {
            question: "I have a clear set of goals and I understand why realising those goals is important both for me and for the organisation",
            facet: "meaningfulness",
            subFacet: "goals"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recSbbJsEQXnZrPuR",
        fields: {
            question: "I believe I can achieve anything with hard work and the right mindset",
            facet: "mastery",
            subFacet: "growth_mindset"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recSo492socdYvAib",
        fields: {
            question: "I am confident I can innovate and take risks in my work and, even if I fail, my colleagues will support me",
            facet: "relatedness",
            subFacet: "support"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recUiAhxrcEVDy8pT",
        fields: {
            question: "I am not really goal oriented. I just take things as they come one day at a time",
            facet: "meaningfulness",
            subFacet: "goals"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recUp5YoZunazYnJ9",
        fields: {
            question: "If I make a mistake at work I really worry what my colleagues will think",
            facet: "mastery",
            subFacet: "learning"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recX4TNtkTDmSCuU4",
        fields: {
            question: "I am comfortable expressing my opinions and beliefs at work",
            facet: "relatedness",
            subFacet: "inclusion"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recY6CDRc61Ubdfhy",
        fields: {
            question: "I am not really sure what my strengths are",
            facet: "mastery",
            subFacet: "strengths"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recYkrLxDrIik98vE",
        fields: {
            question: "It is important to me that I get better at what I do",
            facet: "mastery",
            subFacet: "growth_mindset"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recbFEVZDCSArfjzm",
        fields: {
            question: "I regularly go outside my comfort zone to master new skills",
            facet: "mastery",
            subFacet: "growth_mindset"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recbe8cb9v0GP210Q",
        fields: {
            question: "Failure never worries me too much because you can always learn from it",
            facet: "mastery",
            subFacet: "learning"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recch1AEARvsNxnuI",
        fields: {
            question: "I am able to explain why my work is important to me",
            facet: "meaningfulness",
            subFacet: "purpose"
        },
        createdTime: "2021-04-07T10:07:27.000Z"
    },
    {
        id: "recco4yJmuUSk8G2i",
        fields: {
            question: "I work hard to build on my strengths",
            facet: "mastery",
            subFacet: "strengths"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recdOz15PfXIlALpv",
        fields: {
            question: "My attitude is, 'It's just work'. I don't really think about my work beyond that",
            facet: "meaningfulness",
            subFacet: "purpose"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recf0ZFZD1l00NrgY",
        fields: {
            question: "I am confident about expressing my views and opinions",
            facet: "freedom_responsibility",
            subFacet: "autonomy"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "rechzWF5wkdCrYzO8",
        fields: {
            question: "I have very little freedom to do my job as I would want to - I am micromanaged",
            facet: "freedom_responsibility",
            subFacet: "ownership"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recm5yqDrO0RMxhbj",
        fields: {
            question: "I understand the impact of my work - why it's important and why others value what I do",
            facet: "meaningfulness",
            subFacet: "contribution"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recnGjeP6HAHkOWIm",
        fields: {
            question: "The organisation applies its values and philosophies consistently and fairly",
            facet: "relatedness",
            subFacet: "consistency"
        },
        createdTime: "2021-04-07T10:07:27.000Z"
    },
    {
        id: "recp3njwOj7pwYNrB",
        fields: {
            question: "I can identify how my role contributes to the organisation as a whole",
            facet: "meaningfulness",
            subFacet: "contribution"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recqWddE9YSyApyLE",
        fields: {
            question: "At work, I make a conscious effort to understand other people's point of view",
            facet: "relatedness",
            subFacet: "respect"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recsypWN8ArkUUhtf",
        fields: {
            question: "I am comfortable being who I really am at work",
            facet: "relatedness",
            subFacet: "inclusion"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recu0uxPkLPgQEKO8",
        fields: {
            question: "I respect the opinions and beliefs of the people I work with, even when I disagree with them",
            facet: "relatedness",
            subFacet: "respect"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recuihyNfP68Zyz7W",
        fields: {
            question: "I set myself high standards which I work hard to meet",
            facet: "mastery",
            subFacet: "progress"
        },
        createdTime: "2021-04-07T10:07:27.000Z"
    },
    {
        id: "recve0Y2KYXJwUVqc",
        fields: {
            question: "I have the freedom to  use my own judgement at work - I don't simply follow rules",
            facet: "freedom_responsibility",
            subFacet: "autonomy"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    },
    {
        id: "recvsUrW8wkmeniK5",
        fields: {
            question: "I actively define and develop my role and responsibilities",
            facet: "meaningfulness",
            subFacet: "progress"
        },
        createdTime: "2021-04-07T10:14:12.000Z"
    }
]

export default DBQUESTIONSRB