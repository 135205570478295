function add_nnv_reportStuff(report) {
	report = whereOnAxes(report);
	//report.archetype = whichArchetype(report.rankedGroupNames);
	report.archetype = whichArchetypeFromAxes(report);
	return report;
}

export { add_nnv_reportStuff };

function whereOnAxes(report) {
	report.yGroups = report.rankedGroupNames.filter(
		(group) => group === "tribe" || group === "world"
	);
	report.xGroups = report.rankedGroupNames.filter(
		(group) => group === "change" || group === "preservation"
	);
	return report;
}

function whichArchetypeFromAxes(report) {
	const x = report.xGroups[0],
		y = report.yGroups[0];

	// which is stronger on world-tribe axis?
	let world = y === "world" ? true : false;
	let tribe = y === "tribe" ? true : false;
	// which is stronger on preservation-change axis?
	let preservation = x === "preservation" ? true : false;
	let change = x === "change" ? true : false;

	// ... so which quadrant of the map are they in?
	if (world && preservation) {
		return "global_stewards";
	}
	if (world && change) {
		return "justice_warriors";
	}
	if (tribe && preservation) {
		return "preservers";
	}
	if (tribe && change) {
		return "innovation_leaders";
	}
	return "no_archetype";
}
