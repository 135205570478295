import React from 'react'

import { Footer, TopNav } from './nnv_components.js'



export const ReportGoalsPage = (props) => {

    const questions = props.state.nnv_freeTextQuestionsObject
    
    /* Free text answers */
    const adjective_1 = questions['adjective_1']
    ? questions['adjective_1'].a
    : null
    const adjective_2 = questions['adjective_2']
    ? questions['adjective_2'].a
    : null
    const adjective_3 = questions['adjective_3']
    ? questions['adjective_3'].a
    : null
    const number_one_goal = questions['number_one_goal']
    ? questions['number_one_goal'].a
    : null
    const biggest_challenge = questions['biggest_challenge']
    ? questions['biggest_challenge'].a
    : null
    const how_you_give_back = questions['how_you_give_back']
    ? questions['how_you_give_back'].a
    : null





/* 
    const adjective_2 = questions['adjective_2'].a
    const adjective_3 = questions['adjective_3'].a
    const number_one_goal = questions['number_one_goal'].a
    const biggest_challenge = questions['biggest_challenge'].a
    const how_you_give_back = questions['how_you_give_back'].a
 */


    return (
        <>


<TopNav handleBack = {()=>props.handleBack()} handlePrint = {()=>props.handlePrint()} 
startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="dark">
                <div>
                    <h1><span>Your results</span> Goals</h1>
                    <p><i>Our values reveal the underlying goals and drivers that motivate our actions. Based on your answers,
                        this report identifies your values archetype and details where you rank on each sub-value.
                    </i></p>

                </div>
            </section>
            <section className="dark tabs">
                <div>
                <div className="tab selected" onClick={()=>props.handlePage('ReportGoalsPage')}>Your Goals</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportArchetypesPage')}>Archetypes</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportInDetailPage')}>In detail</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportActionPage')}>Action</div>
                </div>
            </section>

            <section>
                <div>
                    <p><i>In your own words</i></p>
                    <h1>Your business</h1>
                </div>
            </section>

            <section>
                <div>
                    <h3>Your business in three adjectives</h3>
                    {(adjective_1 || adjective_2||adjective_3)
                    ?<><p className="small hint">You describe your business as...</p>
                    <div>
                        {adjective_1
                        ?<span className="tag h5">{adjective_1}</span>
                    : null}
                        {adjective_2
                        ?<span className="tag h5">{adjective_2}</span>
                    : null}
                        {adjective_3
                        ?<span className="tag h5">{adjective_3}</span>
                    : null}
                    </div></>
                    : <p className="small hint">You didn't say anything here</p>}
                </div>
            </section>

            <section>
                <div>
                    <h3>Your Number One business goal</h3>
                    {number_one_goal
                    ? <blockquote>{number_one_goal}</blockquote>
                    : <blockquote className="hint">You didn’t answer anything here</blockquote>
                }
                </div>
            </section>

            <section>
                <div>
                    <h3>The biggest challenge you face</h3>
                    {biggest_challenge
                    ? <blockquote>{biggest_challenge}</blockquote>
                    : <blockquote className="hint">You didn’t answer anything here</blockquote>
                }
                </div>
            </section>

            <section className="last">
                <div>
                    <h3>How you give back</h3>
                    {how_you_give_back
                    ? <blockquote>{how_you_give_back}</blockquote>
                    : <blockquote className="hint">You didn’t answer anything here</blockquote>
                }
                </div>
            </section>



            <section className="grey last">
                <div className='centred'>
                    <button className="big"  onClick={()=>props.handlePage('ReportArchetypesPage')}>Next: <span>The Archetypes</span></button>
                    <div className="small centred">Which archetype are you?</div>
                </div>
            </section>



            <Footer handlePage={(p)=>props.handlePage(p)}/>

        </>
    );
};

export default ReportGoalsPage