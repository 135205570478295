//================ FUNCTIONS ============================


export function mergeArrays(...arrays) {
  let mergedArray = [];

  arrays.forEach(array => {
    mergedArray = mergedArray.concat(array)
  });

  return mergedArray;
}

export function isEmail(input) {
  return input.indexOf(".") !== -1 &&
    input.indexOf(".") !== input.length - 1 &&
    input.indexOf("@") !== -1 &&
    input.indexOf("@") !== input.length - 1
    ? true
    : false;
}

export function phraseHowStrong(score) {
  let hiOrLo = convertPercentToHiOrLo(score);
  return hiOrLo === "lo" ? "Valued Less" : "Highly Valued";
}
export function convertPercentToHiOrLo(num) {
  if (num < 50) {
    return "lo";
  }
  if (num >= 50) {
    return "hi";
  }
}

export function progressPercent(questions) {
  let answered = questions.filter((q) => q.a).length;
  return Math.ceil((answered / questions.length) * 100);
}

export function howManyAnswered(questions) {
  let howManyAnswered = 0;
  let q;
  for (q in questions) {
    questions[q].a && howManyAnswered++;
  }
  return howManyAnswered;
}

export function formatToSentenceCaseFrom_snake_case(snake_case_input = 'snake_case_input') {
  let output = snake_case_input.split("_").join(" ");
////and use text-transform: capitalize;

  return output
}