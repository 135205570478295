import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
import { FacetsNav } from '../../components/FacetsNav.jsx';
import { Navtabs } from '../../components/Navtabs.jsx';
// import './css/rbe.css'

export const JournalTEMPPage = props => {
    if (!props.journalQuestions) { return 'loading journal' }

    /* const entrieslist = Object.keys(props.journalQuestions) */
    const listMyStrengths = [
        /* strengths */
        "what_aspects_of_your_work_receive_the_most_positive_feedback_from_colleagues",
        "which_traits_or_skills_do_you_think_help_you_get_positive_feedback_from_your_colleagues",
        "in_the_last_month_what_did_you_really_enjoy_doing_at_work",
        "do_you_find_it_very_easy_to_concentrate_when_you_are_doing_this_activity",
        "afterwards_are_you_invigorated_and_authentically_proud_strong_or_happy",
        "and_what_did_you_really_not_enjoy",
        "when_do_you_feel_most_focused_at_work_and_why",
        "what_really_demotivates_you_at_work_and_why",
        "think_about_a_strength_you_have_that_you_really_want_to_make_the_most_of_how_could_you_practice_that_strength_more_in_your_day_to_day_work",
        "what_could_you_change_about_your_routine_or_the_way_you_go_about_your_work_to_really_play_to_that_strength",

        /* growth */


        "what_do_you_most_enjoy_doing_and_why",


        "what_are_you_going_to_try_outside_your_usual_skillset",
        "what_are_you_going_to_change_about_the_way_you_do_your_work_to_really_play_to_your_strengths",
        "what_do_you_want_to_do_that_you_are_too_scared_to_do_or_you_just_think_you_cant_do",
        "what_skills_have_you_improved_over_time_and_how_have_managed_to_improve_them",
        "what_difficult_thing_are_you_going_to_learn",
        "when_have_you_shown_grit",
        "what_failures_have_you_really_learnt_something_from",
        "and_what_did_you_learn",



        "think_of_something_outside_your_usual_skillset",
    ]



    /*     const listGrowthMindset = [
            "what_do_you_want_to_do_that_you_are_too_scared_to_do_or_you_just_think_you_cant_do",
            "what_skills_have_you_improved_over_time_and_how_have_managed_to_improve_them",
            "what_difficult_thing_are_you_going_to_learn",
            "when_have_you_shown_grit",
            "what_failures_have_you_really_learnt_something_from",
            "and_what_did_you_learn"
        ] */


    const journalEntriesStrengths = listMyStrengths.map(i =>
        props.journalQuestions[i].a
            ? <div key={props.journalQuestions[i].qid} className='card'>
                <div className="journalQuestion strong">{props.journalQuestions[i].question}</div>
                <div className="journalAnswer">
                    <span className='bubble'>{props.journalQuestions[i].a}</span>
                </div>
            </div>
            : <div key={props.journalQuestions[i].qid} className='card'>
                <div className="">{props.journalQuestions[i].question}</div>
            </div>
    )

    /*     const journalEntriesGrowthMindset = listGrowthMindset.map(i =>
            props.journalQuestions[i].a
                ? <div className='card' key={props.journalQuestions[i].qid}>
                    <div className="journalQuestion strong">{props.journalQuestions[i].question}</div>
                    <div className="journalAnswer">
                        <span className='bubble'>{props.journalQuestions[i].a}</span>
                    </div>
                </div>
                : <div className='card' key={props.journalQuestions[i].qid}>
                    <div className="">{props.journalQuestions[i].question}</div>
                </div>
        ) */

    return (
        <>
            <NavLogoHeader
                handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
                handlePage={(p) => props.handlePage(p, 0)}
                handleBack={(e) => props.handleBack(e)}
                showJumpMenu={props.state.showJumpMenu} />
            <section className="FacetsNavHeader">
                <div>
                    <FacetsNav
                        handlePage={(p) => props.handlePage(p)}
                    />
                </div>
            </section>

            <section className="navheader">
                <div>

                    <Navtabs
                        t={1}
                        handlePage={
                            (p) => props.handlePage(p, 1) /* tabs keep scroll postion */
                        }
                    />
                </div>
            </section>
            {/* onChange={(e) => props.handlePage(e.target.value)} */}
            <section className="journalNav">
                <div>
                    <select id="title" name="title" >
                        <option value="XXX" defaultValue>Mastery</option>

                        <option value="XXX">Freedom</option>
                        <option value="XXX">Meaning</option>
                        <option value="XXX">Relatedness</option>
                    </select>
                    <select id="title" name="title" >
                        <option value="XXX" defaultValue>My Strengths</option>

                        <option value="XXX">My Growth Mindset</option>
                        <option value="XXX">My Learning</option>
                        <option value="XXX">My Progress</option>
                    </select>
                </div>
            </section>
            <section className="">
                <div>
                    <h4>My Strengths</h4>
                    {journalEntriesStrengths}
                </div>
            </section>
            {/* <section className="">
                <div>
                    <h4>My Growth Mindset</h4>
                    {journalEntriesGrowthMindset}
                </div>
            </section> */}

            <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />

        </>
    );
};

export default JournalTEMPPage

/////////////////

/* const FacetsNav = props => {
    return (<>
        <div className='FacetsNav'>
            <span className='FacetsNavLink this' onClick={() => props.handlePage('ReportFacetsPage')}>Mastery</span>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportFacetsPage')}>Freedom</span>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportFacetsPage')}>Meaning</span>
            <span className='FacetsNavLink' onClick={() => props.handlePage('ReportFacetsPage')}>Relatedness</span>


        </div>
    </>)
} */