import React from 'react'


export const ScaleDiagram = (props, client) => {

    let scoreAsPercent = (props.score-5) + "%";

    const css = `left:${scoreAsPercent}`;

    return (
      <>
        <div className="scaleDiagram">
          <div className="scaleBar"></div>
          <div className="scaleMarkerOuter" Style={css}>
            <div className="scaleMarker"></div>
          </div>
          <div className="scaleLegend">
            <div className="scaleLegendLow">Valued Less</div>
            <div className="scaleLegendHigh">Valued More</div>
          </div>
        </div>
      </>
    );
  };

export default ScaleDiagram