import React from 'react'

import {
    nnv_navbackICON,
    nnv_navprintICON,
    nnv_navshareICON,
    nnv_navbackICON_for_light_bg,
    nnv_navprintICON_for_light_bg,
    nnv_navshareICON_for_light_bg,
} from '../nnv_importImages.js'


export const TopNav = (props) => {
    
    const startNewStyle = props.hideBack === true
    ? 'startNew hide'
    : 'startNew'
/*     const backStyle = props.hideBack === true
    ? 'back hide'
    : 'back' */

    const sectionStyle = props.light_bg === true
    ? 'topnav topnav_light_bg'
    : 'topnav dark'

    let icons = {
        back: nnv_navbackICON,
        print: nnv_navprintICON,
        share: nnv_navshareICON
    }
if (props.light_bg) { 
    icons = {
        back: nnv_navbackICON_for_light_bg,
        print: nnv_navprintICON_for_light_bg,
        share: nnv_navshareICON_for_light_bg
    }
 }



    return (
        <section className={sectionStyle}>
                    <div>
                        <div className={startNewStyle /* backStyle */} onClick={()=>props.startNew()/* props.handleBack() */}><img src={icons.back} alt="Back" /></div>
                        <div className={startNewStyle /* backStyle */} onClick={()=>props.startNew()}><span>Start New</span></div>
                        <div className="share"><a className='noUnderline' href="https://twitter.com/intent/tweet?text=The%20Values%20Map&amp;url=https:/https://thevaluesmap.com/"  target="_blank" rel="noopener noreferrer"><img src={icons.share} alt="Share" /></a></div>
                        <div 
                        className="print"
                        onClick = {()=>props.handlePrint()}
                        
                        ><img src={icons.print} alt="Print" /></div>
                    </div>
                </section>

        
    );
};

export default TopNav