import React from 'react'
import { LikertDivsAnswersNotButtons } from './LikertDivsAnswersNotButtons.jsx';
//import { LikertDivsAnswers } from './LikertDivsAnswers.jsx';
import { LikertDivsReverseAnswers } from './LikertDivsReverseAnswers.jsx';
import { LikertSliderAnswers } from './LikertSliderAnswers.jsx';
import { LikertTags } from './LikertTags.jsx';
/* import { FreeTextAnswers } from './FreeTextAnswers.jsx'; */
import {howManyAnswered} from '../helpers.jsx'

export const QuestionUI = (props) => {
  let questions = props.state.questions;

  // if questions have been marked (in Airtable) as part of group, show that group only
if (props.questionGroup){ 
  questions = questions.filter( q => q.questionGroup === props.questionGroup)
 }

 //sort: put any questions that have been given an 'order' value in Airtable first
 questions = questions
        .map(q => q)
        .sort(function (a, b) {
            return a.order - b.order;
        }); // sorts numerically not alpha // (b-a) gives descending    

// find out which is current question
console.log ('howManyAnswered', howManyAnswered(questions)) 
const howManyAnsweredNow =   howManyAnswered(questions)     

  let listOfQuestions = questions.map((question) => (
    <div
      className={
        question.a
          ? "question answered"
          : questions.indexOf(question) === howManyAnsweredNow //// was: props.state.howManyAnswered
            ? "question toDo card"
            : "futureQuestion"
      }
    >
      <div className="questionNumber">
          { /* questions.length - questions.indexOf(question) */ questions.indexOf(
            question
          ) + 1 }
        </div>
      <div className="questionText">
        
        {question.q}
      </div>

      {question.ui === "likert" ? (
        <LikertDivsAnswersNotButtons
          name={question.question_id}
          /* name={props.state.questions.indexOf(question)} */
          handleChangeLikert={(e, name, value, thing) => props.handleChangeLikert(e, name, value, thing)}
          a={question.a}
          client={props.state.client}
        />

      ) : question.ui === "likert_reverse" ? (
        <LikertDivsReverseAnswers
          name={props.state.questions.indexOf(question)}
          handleChangeLikert={(e) => props.handleChangeLikert(e)}
          a={question.a}
          client={props.state.client}
        /* reverse={true} */
        />
      ) : question.ui === "slider" ? (
        <LikertSliderAnswers
          name={props.state.questions.indexOf(question)}
          handleChangeSlider={(e) => props.handleChangeSlider(e)}
          a={question.a}
          client={props.state.client}
        />
      ) : question.ui === "tags" ? (
        <LikertTags
          name={props.state.questions.indexOf(question)}
          handleChangeLikert={(e) => props.handleChangeLikert(e)}
          a={question.a}
          client={props.state.client}
        />
      ) : (
        <LikertTags
          name={props.state.questions.indexOf(question)}
          handleChangeLikert={(e) => props.handleChangeLikert(e)}
          a={question.a}
          client={props.state.client}
        />
      )
      }

    </div>
  ));



  return <div className="listOfQuestions">{listOfQuestions}</div>;
};

export default QuestionUI