import React from 'react'

import { Footer, TopNav } from './nnv_components.js'

import {formatToSentenceCaseFrom_snake_case} from '../../helpers.jsx'

import {
    compass_and_dividers_ILLUS,
    pin_global_stewards,
    pin_justice_warriors,
    pin_preservers,
    pin_innovation_leaders,
} from '../nnv_importImages.js'




import ContentJusticeWarriors from './ReportActionContentJusticeWarriors.jsx'
import ContentInnovationLeaders from './ReportActionContentInnovationLeaders.jsx'
import ContentGlobalStewards from './ReportActionContentGlobalStewards.jsx'
import ContentPreservers from './ReportActionContentPreservers.jsx'


export const ReportActionPage = (props) => {

    const archetype = props.state.report.archetype

    const pins = {
        global_stewards: pin_global_stewards,
        justice_warriors: pin_justice_warriors,
        preservers: pin_preservers,
        innovation_leaders: pin_innovation_leaders,
    }

    

    const content_for = {
        justice_warriors : <ContentJusticeWarriors />,
       innovation_leaders : <ContentInnovationLeaders />,
       global_stewards : <ContentGlobalStewards />,
       preservers : <ContentPreservers />
    }

    /*  */
    return (
        <>

            <span className={archetype}>

           <TopNav handleBack = {()=>props.handleBack()}
light_bg = {true}
        handlePrint = {()=>props.handlePrint()}
        startNew = {()=>props.handleStartNew_nnv()} 
tempTest = {()=>props.tempTest()}/>

            <section className="illusHeader ">
                    <div>
                        <div>
                        <h1><span>{formatToSentenceCaseFrom_snake_case(archetype)}</span>Values in action</h1>
                    </div>   
                    <div className='centred'>
                        <img src={pins[archetype]} alt="" />
                    </div>
                    <div className='extraIllus'>
                        <img src={compass_and_dividers_ILLUS} alt="Justice Warriors" />
                    </div>
                    <div>
                        <p><i>In this section we’ll reveal how your values can be applied to your mission, culture, brand and
                            communication, and give examples of ads and brands that share your archetype.</i></p>
                    </div>
                    
                    </div>
                </section>

                <section className="dark tabs">
                    <div>
                        <div className="tab" onClick={()=>props.handlePage('ReportGoalsPage')}>Your Goals</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportArchetypesPage')}>Archetypes</div>
                        <div className="tab" onClick={()=>props.handlePage('ReportInDetailPage')}>In detail</div>
                        <div className="tab selected" onClick={()=>props.handlePage('ReportActionPage')}>Action</div>
                    </div>
                </section>


            
                {content_for[archetype]}


                <section className="last centred">
                    <div>
                        <button className="big" onClick={()=>props.handlePage('Consultancy')}>Next: <span>Apply this to your business</span></button>

                    </div>
                </section>

       

                <Footer handlePage={(p)=>props.handlePage(p)}/>


            </span>



        </>
    );
};

export default ReportActionPage