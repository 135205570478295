import React from 'react'
import { Footer } from '../../components/Footer.jsx';
import { NavLogoHeader } from '../../components/NavLogoHeader.jsx';
// import './css/rbe.css'

export const IntroPage = props => {
  /*  */
  return (
    <>
      <NavLogoHeader
        handleMakeFakeLikertAnswers={() => props.handleMakeFakeLikertAnswers()}
        handlePage={(p) => props.handlePage(p, 0)}
        handleBack={(e) => props.handleBack(e)}
        showJumpMenu={props.state.showJumpMenu} />

      <section className="intro header bgImgSurfTube ">
        <div>
          <h1>My Experience</h1>
          <div>Start your journey towards more <br />meaningful and impactful work</div>


        </div>
      </section>
      <section className="intro header bgImgSurfTube last">
        <div>

          <div>  Find somewhere quiet where you won't be disturbed. <i className="fa fa fa-coffee"></i><br /><b>Start when you're ready</b></div>
          <p></p>
          <div></div>
          <div>
            <button
              className="big strong"
              onClick={() => props.handlePage('questions')}
            >
              Start
            </button>
            <div className="comment">This questionnaire has 40 questions and should take no more than 10 minutes to complete.</div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="chunk">
            <h4>What is the aim of this app?</h4>
            <p>'My Experience' is designed to give you the insight and tools to access your best at work.</p>
          </div>

          <div className="chunk">
            <h4>What about my privacy?</h4>
            <p>Unless you choose to share any content you create in the app, all content you create is entirely private and will not be seen by anyone at Red Bull.</p>
          </div>

          <div>
            <button
              className="big strong"
              onClick={() => props.handlePage('questions')}
            >
              Start
            </button>
            <div className="comment">This questionnaire has 40 questions and should take no more than 10 minutes to complete</div>
          </div>
        </div>
      </section>

      <Footer handleShowJumpMenu={() => props.handleShowJumpMenu()} />
    </>
  );
};

export default IntroPage