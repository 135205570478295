import React from 'react'


export const LikertSliderAnswers = (props) => {
  const ids = [
    "Never!",
    "Rarely",
    "Not Often",
    /* "Sort of", */
    "Typically",
    "Always!"
  ];

  return (
    <>
      <div className="answer">{props.a
        ? ids[props.a - 1]
        : <span className='prompt'>How true is this for you?</span>} </div>
      <div><div className="slider">
        <input
          name={props.name}
          type="range"
          min="1"
          max="5"

          onMouseDown={(e) => props.handleChangeSlider(e)}
          onTouchStart={(e) => props.handleChangeSlider(e)}
          onChange={(e) => props.handleChangeSlider(e)}
          onMouseUp={(e) => props.handleChangeSlider(e)}
          onTouchEnd={(e) => props.handleChangeSlider(e)}
        ></input>
      </div></div>
    </>
  )
}

export default LikertSliderAnswers